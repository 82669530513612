import { Component, OnDestroy } from '@angular/core';
import { AppService } from './app.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupService } from './core/services/popup.service';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public currentURL: any;
  public showLandingHeader: boolean = true;
  public title = 'angular6';

  public message: any;
  public subscription: Subscription;

  constructor(
    private session: PopupService,
    public router: Router,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService) {

    this.subscription = this.session.getMessage().subscribe(message => { this.message = message; });
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  public ngOnInit() {
    this.getCurrentLocation()
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          let url:any = event.urlAfterRedirects.split('?');
          this.currentURL = url[0].split('/');
          if (this.currentURL[1]) {
            this.showLandingHeader = false;
          } else {
            this.showLandingHeader = true;
          }
        }
      });
      
  }
  getCurrentLocation(){
    navigator.geolocation.getCurrentPosition(position => {   
      localStorage.setItem("lat", (position.coords.latitude).toString());
      localStorage.setItem("lng", (position.coords.longitude).toString());     
     
    }, error => {
      if (error && error.code) {
      }
    },
    {
			enableHighAccuracy: true
		});
  } 
}
