import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { Router } from '@angular/router';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  public newChildName: string = '';
  public loginRole: any;
  public canAddUserRoleType: string;

  public addUserTypeName: string;
  public addTypeUser: string;
  public isNewAccount = false;

  constructor(private popupService: PopupService,
    private router: Router, private childServiceService: ChildServiceService) { }

  ngOnInit() {
    this.loginRole = localStorage.getItem("loginRole");
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
    this.newChildName = sessionStorage.getItem('newChildName');
    this.isNewAccount = this.childServiceService.checkWhetherParentChildExists;
    this.addUserTypeName = localStorage.getItem("newChildName");
    this.addTypeUser = this.childServiceService.messageInfor;
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  goToAddChild(poupType): void {
    this.popupService.showPopup(poupType);
    this.router.navigate(["/parent/search-parent"]);
  }
  goToHome() {
    this.getPopup('');
    this.router.navigate(["/home/list"]);
  }
}
