import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { AgencyProgramsComponent } from './agency-programs.component';
import { TranslateModule } from '@ngx-translate/core';



const routes: Routes = [
  {
    path: '',
    component: AgencyProgramsComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule,TranslateModule
  ],
  exports: [RouterModule],
  declarations: [AgencyProgramsComponent],
  providers: []
})
export class AgencyProgramsModule { }
