import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupSettingsComponent } from '../popup-settings/component/popup-settings/popup-settings.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { ChildModule } from '../child/child.module';
import { ParentModule } from '../parent/parent.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UserModule,
    ChildModule,
    ParentModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [PopupSettingsComponent],
  exports : [
    PopupSettingsComponent
  ],
})
export class PopupSettingsModule { }
