import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/core/services/popup.service';
import { AppService } from 'src/app/shared/services/app.service';
import { urls, toastMessage} from '../../../../shared/services/constant';
import { AuthServiceService } from 'src/app/core/services/authService/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {
  public geolocationPosition: any;
  public childList: Array<any> = []
  public idArray: Array<any> = []
  public latitude: any
  public longitude: any
  public currentTimezone: any
  public programId: any
  public Id: any
  constructor(
    private popupService: PopupService,
    private appService: AppService,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.Id = this.router.url.split("/").pop();
    this.programId = this.Id.split("?").shift();
  }

  ngOnInit() {
    this.getChild()
    this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }

  getChild() {
    this.appService.get(urls.getChildList)
      .subscribe((res: any) => {
        this.childList = res.result.users
      })
  }

  check(e) {
    if (e.target.checked) {
      this.idArray.push(e.target.value)
    }
    else {
      let a = this.idArray.indexOf(e.target.value)
      if (a > -1) {
        this.idArray.splice(a, 1);
      }
    }
  }
  fetchLocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.geolocationPosition = position;
          localStorage.setItem("lat", (position.coords.latitude).toString());
          localStorage.setItem("lng", (position.coords.longitude).toString());
          this.checkin();
        },
        error => {
          switch (error.code) {
            case 1:
              this.appService.showError(toastMessage.permissionDenied);
              break;
            case 2:
              this.appService.showError(toastMessage.postionUnavailable);
              break;
            case 3:
              this.appService.showError(toastMessage.timeout);
              break;
          }
        },
        {
          enableHighAccuracy: true
        }
      );
    }
  }
  executeCheckIn() {
    if (this.authService.isLoggednIn()) {
      if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
        this.checkin();
      }
      else {
        this.fetchLocation();
      }


    } else {
      this.getPopup('');
      this.router.navigate(["/home/list"]);
    }
  }
  checkin() {
    this.latitude = localStorage.getItem("lat")
    this.longitude = localStorage.getItem("lng")
    if (this.latitude && this.longitude) {
      let params = {
        "programId": this.programId,
        "child_ids": this.idArray,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "date": new Date().valueOf(),
        "time_zone": (this.currentTimezone).toString()
      }
      this.appService.post(urls.programCheckIn, params)
        .subscribe(
          (response: any) => {
            if (response.sc == 1) {
              this.appService.showSuccess(response.result.message);
              this.getPopup('');
            } else {
              this.appService.showError(response.error.msg);
              this.getPopup('');
            }
          });
    } else {
      this.appService.showError(toastMessage.locationDenied);
    }
  }
}
