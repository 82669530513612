import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { Router } from '@angular/router';
import { urls } from '../../../../shared/services/constant';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  public badges = [];
  public currentBadge: any = {};
  public showDullNumber;
  public isParent = false;
  public roleName: any;
  public role: number = null;
  public parentInfo: boolean = true;
  public badgeInfo = false;
  public personalInfo: boolean = false;
  constructor( private appService: AppService,
    private router: Router,
    public translate: TranslateService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getProfile();
  }

  tabopen(type) {
    if (type === 2) {
      this.parentInfo = false;
      this.personalInfo = true;
      this.badgeInfo = false;

    } else if (type === 3) {
      this.parentInfo = false;
      this.personalInfo = false;
      this.badgeInfo = true;
    } else {
      this.parentInfo = true;
      this.personalInfo = false;
      this.badgeInfo = false;
    }
  }
  getProfile() {
    this.appService.get(urls.getProfile).subscribe((response: any) => {
      if (response && response.sc === 1) {
        localStorage.setItem('loginRole', response.result.user.role);
        //login role 1 = parent and 2 = child
        localStorage.setItem('linkedin', response.result.user.connect_by_linkedin);
        localStorage.setItem('instagram', response.result.user.connect_by_inst);
        localStorage.setItem('twitter', response.result.user.connect_by_twit);
        //set the role which logged in user can add
        localStorage.setItem('canAddUserRoleType', response.result.user.role === 1 ? 'Child' : 'Parent');

        // set badges and current badge
        this.isParent = response.result.user.role === 1;
        this.badges = response.result.badges;
        this.currentBadge = response.result.current_badge;   
        this.calculateDullStatus();   
        this.roleName = (response.result.user.role === 1) ? 'Child' : 'Parents';
        this.role = response.result.user.role;
      }
    }, (error) => {
      console.log(error);
    });
  }
  calculateDullStatus() {
    for (let i = 0; i < this.badges.length; i++) {
      if (this.badges[i]._id === this.currentBadge._id) {
        this.showDullNumber = i;
      }
    }
  }
}
