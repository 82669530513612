import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddParentComponent } from './component/add-parent/add-parent.component';
import { SearchParentComponent } from './component/search-parent/search-parent.component';
const routes: Routes = [{
  path: 'search-parent',
  component: SearchParentComponent
},
{
  path: 'add-parent',
  component: AddParentComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParentRoutingModule { }
