import { Component, OnInit } from '@angular/core';
import { urls } from '../../../../shared/services/constant';
import { AppService } from '../../../../shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { resourceLimits, threadId } from 'worker_threads';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  public skip: number = 0;
  public limit: number = 400;
  public typeValue: number = 2;
  public notificationRequestList: any = [];
  public notificationRequestEmptyCheckValue: boolean = false;
  constructor(private appService: AppService, public translate: TranslateService) {
    window.scroll(0, 0)
  }
  ngOnInit() {
    this.appService.getNotificationList(urls.AlertsNotificationAPI + `${this.typeValue}?skip=${this.skip}&limit=${this.limit}`).subscribe(
      (response: any) => {
        if (response.sc === 1) {
          if (response.result.data.length > 0) {
            this.notificationRequestEmptyCheckValue = false;
            response.result.data.forEach((res) => {
              let body = {
                message: res.message,
                time: res.created_at,
                isRead: res.isRead,
                id: res._id,
              }
              this.notificationRequestList.push(body);
            })
          }
          else {
            this.notificationRequestEmptyCheckValue = true;
            this.notificationRequestList = [];
          }
        }
      });
  }
}
