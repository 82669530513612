import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PitComponent } from './pit.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: PitComponent
  }
];
@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes),TranslateModule
  ],
  exports: [RouterModule],
  declarations: [PitComponent]
})
export class PitModule { }
