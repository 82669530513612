import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { ProductDetailsComponent } from './product-details.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: ProductDetailsComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule,TranslateModule
  ],
  exports: [RouterModule,],
  declarations: [ProductDetailsComponent],
  providers: []
})
export class ProductDetailsModule { }
