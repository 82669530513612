import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { Subscription } from 'rxjs';
import { PopupManagementComponent } from '../../../../shared/component/popup-management/popup-management.component';
import { OtpComponent } from '../../../user/component/otp/otp.component';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-popup-settings',
  templateUrl: './popup-settings.component.html',
  styleUrls: ['./popup-settings.component.scss']
})

export class PopupSettingsComponent implements OnDestroy {
  @ViewChild('signupModal', {static: false}) signupModal: PopupManagementComponent;
  @ViewChild('otpModal', {static: false}) otpModal: PopupManagementComponent;
  @ViewChild('otpdiv', {static: false}) otpdiv: OtpComponent;
  @ViewChild('interestModal', {static: false}) interestModal: PopupManagementComponent;
  @ViewChild('forgotModal', {static: false}) forgotModal: PopupManagementComponent;
  @ViewChild('ethnicityModal', {static: false}) ethnicityModal: PopupManagementComponent;
  @ViewChild('searchChildModal', {static: false}) searchChildModal: PopupManagementComponent;
  @ViewChild('addChildModal', {static: false}) addChildModal: PopupManagementComponent;
  @ViewChild('addSucessModal', {static: false}) addSucessModal: PopupManagementComponent;
  @ViewChild('addErrorModal', {static: false}) addErrorModal: PopupManagementComponent;
  @ViewChild('addProfileSucessModal', {static: false}) addProfileSucessModal: PopupManagementComponent;
  @ViewChild('addProfileErrorModal', {static: false}) addProfileErrorModal: PopupManagementComponent;
  @ViewChild('addCheckInModal', {static: false}) addCheckInModal: PopupManagementComponent;
  @ViewChild('insightModal', {static: false}) insightModal: PopupManagementComponent;
  @ViewChild('linkSocialAccountModal', {static: false}) linkSocialAccountModal: PopupManagementComponent;
  @ViewChild('addPlacesModal', {static: false}) addPlacesModal: PopupManagementComponent;
  @ViewChild('addLocationsModal', {static: false}) addLocationsModal: PopupManagementComponent;
  @ViewChild('onboardingModal', {static: false}) onboardingModal: PopupManagementComponent;
  @ViewChild('reedemOtpModal', {static: false}) reedemOtpModal: PopupManagementComponent;
  public message: any;
  public fbUserDetails: any;
  public childDetails: any;
  public subscription: Subscription;
  constructor(private popupService: PopupService) {
    this.subscription = this.popupService.getPopup().subscribe(popup => {
      this.signupModal.hide();
      this.otpModal.hide();
      this.interestModal.hide();
      this.forgotModal.hide();
      this.ethnicityModal.hide();
      this.searchChildModal.hide();
      this.addChildModal.hide();
      this.addSucessModal.hide();
      this.addErrorModal.hide();
      this.addProfileSucessModal.hide();
      this.addProfileErrorModal.hide();
      this.addCheckInModal.hide();
      this.insightModal.hide();
      this.linkSocialAccountModal.hide();
      this.addPlacesModal.hide();
      this.addLocationsModal.hide();
      this.onboardingModal.hide();
      this.reedemOtpModal.hide();
      if (popup == "signup") {
        this.signupModal.show();
      }
      if (popup == "otp") {
        this.otpModal.show();
        observableOf(null).pipe(delay(400)).subscribe(() => {
          window.scrollTo(0, 0);
          this.otpdiv.countdown()
        })
      }
      if (popup == "interest") {
        this.interestModal.show();
      }
      if (popup == "forgot") {
        this.forgotModal.show();
      }
      if (popup == "ethnicity") {
        this.ethnicityModal.show();
      }

      if (popup == "searchChild") {
        this.searchChildModal.show();
      }
      if (popup == "addChild") {
        this.addChildModal.show();
      }
      if (popup == "addSucess") {
        this.addSucessModal.show();
      }
      if (popup == "addError") {
        this.addErrorModal.show();
      }
      if (popup == "addProfileSucess") {
        this.addProfileSucessModal.show();
      }
      if (popup == "addProfileError") {
        this.addProfileErrorModal.show();
      }
      if (popup == "checkIn") {
        this.addCheckInModal.show();
      }
      if (popup == 'insight') {
        this.insightModal.show();
      }
      if (popup == 'linkSocialAccount') {
        this.linkSocialAccountModal.show();
      }
      if (popup == 'addPlaces') {
        this.addPlacesModal.show();
      }
      if (popup == 'addLocations') {
        this.addLocationsModal.show();
      }
      if (popup == 'onboarding') {
        this.onboardingModal.show();
      }
      if (popup == "reedemOtp") {
        this.reedemOtpModal.show();
        observableOf(null).pipe(delay(400)).subscribe(() => {
          window.scrollTo(0, 0);
          this.otpdiv.countdown()
        })
      }
    });

  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  getfacebookinfo(event) {
    this.fbUserDetails = event;

  }
  signupClose() {
    this.fbUserDetails = null;
  }

  getAlreadyAdd(event) {
    this.childDetails = event;
  }
}
