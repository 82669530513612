import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  public subject: string = '';
  public toEmail = "info@spotivity.com";

  constructor(private router: Router,public translate: TranslateService) { }

  ngOnInit() {}

  shareProgram() {   
    window.location.href = "mailto:" + this.toEmail + "?subject=" + this.subject + "&body=" + this.subject;
  }
  goToAboutUs(){
    this.router.navigate(["/about-us"]);
  }
  goToFaqs(){
    this.router.navigate(["/faqs"]);
  }
  goToPrivacy(){
    this.router.navigate(["/privacy-policy"]);
  }
  goToTerms(){
    this.router.navigate(["/terms-and-conditions"]);
  }
}
