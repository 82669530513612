import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from 'src/app/core/services/popup.service';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

@Component({
  selector: 'app-pit',
  templateUrl: './pit.component.html',
  styleUrls: ['./pit.component.scss']
})
export class PitComponent implements OnInit {
  public test: any = '';
  public EQResult: any;
  public PQResult: any;
  public modal: any;
  public btn: any;
  public viewLoading: boolean = false;
  public insightResult: Array<any> = [];
  public EQMaxScore: any;
  public PQMaxScore: any;
  public PQpercent: any;
  public pitTaken: any;
  public canTakePit: any;
  public canTakeEq: any;
  constructor(private router: Router, private appService: AppService, private popupService: PopupService, public translate: TranslateService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.getEQPQResult();
    this.getProfile();
    // Analytics.record({ name: 'Event Personal_Insight' });
  }

  goToInstructions(type) {
    if (type == 'pit-test') {
      if (this.canTakePit) {
        this.router.navigate(['/personal-insight-tool'])
      }
      else {
        alert("Test has been taken already.You can give this test again after 6 months from previous attempt.")
      }
    }
    if (type == 'eq-test') {
      if (this.canTakeEq) {
        this.router.navigate(['/test-instructions'], { queryParams: { 'test': type } })
      }
      else {
        alert("Test has been taken already.You can give this test again after 6 months from previous attempt.")
      }
    }
    if (type == 'pq-test') {
      this.router.navigate(['/test-instructions'], { queryParams: { 'test': type } })
    }
  }
  getProfile() {
    this.appService.get(urls.getProfile).subscribe((res: any) => {
      this.insightResult = res.result.insight_result
    })

  }
  openInsight() {
    this.getPopup('insight');
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

  getEQPQResult() {
    this.viewLoading = true;
    this.appService.get(urls.getResult).subscribe((res: any) => {
      if (res['result']) {
        this.viewLoading = false;
        this.pitTaken = res['result'].PITTaken;
        this.canTakePit = res['result'].canTakePIT;
        this.canTakeEq = res['result'].canTakeEQ
        if (res['result'].hasOwnProperty('EQResult')) {
          this.EQResult = res['result'].EQResult;
          this.EQMaxScore = res['result'].EQ_MAX_SCORE;
        }
        if (res['result'].hasOwnProperty('PQResult')) {
          this.PQResult = res['result'].PQResult;
          this.PQMaxScore = res['result'].PQ_MAX_SCORE;
          this.PQpercent = ((this.PQResult / this.PQMaxScore) * 100).toFixed(2);
        }
      }
    })
  }
  openModal(pop: any, type) {
    this.test = type
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
  }
  closemodal() {
    this.btn = document.getElementsByClassName("close")[0];
    this.modal.style.display = "none";
  }
}
