export class UserModel {   
    
    public email: string;
    public password: string;
    public profile_pic: string;
    public first_name: string;
    public last_name: string;
    public phone_no: string;
    public dob: string ='53454366';
    public gender: string = "1";
    public role: string = "2";
    public login_type: any = 1;
    public fbId: any = null;    
    public image: any;
    public referralCode: string = '';
    public haveReferralCode: boolean = false;

}

export class UserProfileModel {
    public email: string;
    public first_name: string;
    public last_name: string;
    public phone_no: string;
    public image: any;
    public emailError: boolean = false;
    public firstNameError: boolean = false;
    public lastNameError: boolean = false;
    public phoneNumberError: boolean = false;
}

export class ReserPasswordModel {
    token: string;
    password: string;
    confirmPassword: string;
    passwordError: boolean;
    confirmPasswordError: boolean;
}

export class ChangePasswordModel {
    oldPassword: string;
    nPassword: string;
    confirmPassword: string;
    oldPasswordError: boolean;
    newPasswordError: boolean;
    confirmPasswordError: boolean;
}
