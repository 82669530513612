import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsroomComponent } from './newsroom.component';
import { RouterModule, Routes } from '@angular/router';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
  };
const routes: Routes = [
  {
    path: '',
    component: NewsroomComponent
  }
];
@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes),SwiperModule,TranslateModule
  ],
  exports: [RouterModule],
  declarations: [NewsroomComponent],
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }]
})
export class NewsroomModule { }
