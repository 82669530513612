import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AppService } from './app.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { LandingModule } from './modules/landing/landing.module';
import { InfoModule } from './modules/info/info.module';
import { UserModule } from './modules/user/user.module';
import { ReactiveFormsModule } from "@angular/forms";
import { PopupSettingsModule } from './modules/popup-settings/popup-settings.module';
import { ProgramModule } from './modules/program/program.module';
import { SettingsModule } from './modules/settings/settings.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { SocialShareModule } from './modules/social-share/social-share.module';
import { ChildModule } from './modules/child/child.module';
import { WalletModule } from './modules/wallet/wallet.module';
import { ParentModule } from './modules/parent/parent.module';
import { HomeModule } from './modules/home/home.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { FaqsComponent } from './component/faqs/faqs.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from './shared/services/shared.service';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    FaqsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    CoreModule,
    LandingModule,
    InfoModule,
    UserModule,
    ReactiveFormsModule,
    PopupSettingsModule,
    ProgramModule,
    SettingsModule,
    SocialShareModule,
    ChildModule,
    WalletModule,
    ParentModule,
    HomeModule,
    NgbModule,
    NotificationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    NotificationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastNoAnimationModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
    GooglePlaceModule
  ],
  providers: [
    AppService,
    AuthGuardService,
    AngularFireAuth,
    ScreenTrackingService,
    UserTrackingService,
    SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
