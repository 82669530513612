import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchParentComponent } from '../parent/component/search-parent/search-parent.component';

const routes: Routes = [{
  path: 'search-parent',
  component: SearchParentComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChildRoutingModule { }
