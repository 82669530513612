import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProgramlistComponent } from './components/programlist/programlist.component';

const routes: Routes = [
{
  path: 'list',
  component: HomeComponent
},
{
  path: 'programlist',
  component: ProgramlistComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HomeRoutingModule { }