import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MapViewComponent } from './map-view.component';
import { SharedModule } from '../../../../shared/shared.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: MapViewComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule, 
    ReactiveFormsModule, SharedModule,NgxSliderModule,TranslateModule
  ],
  exports: [RouterModule,],
  declarations: [MapViewComponent],
  providers: []
})
export class MapViewModule { }
