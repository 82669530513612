import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls ,toastMessage} from '../../../../shared/services/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { PopupService } from '../../../../core/services/popup.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from './../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
    public programId: any;
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public banner_image: any;
    public video_url: any;
    public bucketArray: any = [];
    public season: any;
    public springSeason: string = '';
    public winterSeason: string = '';
    public fallSeason: string = '';
    public summerSeason: string = '';
    public like: boolean = false;
    public genderType: string = '';
    public subject: string = '';
    public type: any = '';
    public toEmail = "";
    public currentTimezone: any;
    public like_count: number = 0;
    public favourite: boolean = false;
    public latitude: string;
    public longitude: string;
    public programDetail: any = {};
    public showImage: boolean = true;
    public embededVideo: boolean = false;
    public embededCode: any = '';
    public role: number = null
    public lat: number = null;
    public long: number = null;
    public programType: any;
    public modal: any;
    public btn: any;
    public model: any = {};
    public navigationFrom: any;
    public status: boolean = false;
    public productArr: any = [];
    public config: SwiperConfigInterface;
    public activeIndex: number;
    public value: any = [];
    public selectedRating: any = [];
    public ratingInfo = [];
    public reports: any = [
        'Questions/Issues regarding published scheduling',
        'Questions/Issues regarding upcoming events',
        'Questions/Issues regarding the Team Store',
        'Questions/Issues regarding notifications',
        'Questions/Issues regarding spotivity points',
        'Questions/Issues regarding leadership',
        'Helpful insights',
        'Questions from a Parent/Guardian',
        'Program Suggestions',
        'Other'
    ];
    public reportDescription: string = '';
    public geolocationPosition: any;
    public show: boolean = false;
    public enrolldata: any;
    public showLoader: boolean;
    public class: boolean = false;
    public questionsList: any = [];
    public selected = 0;
    public showSubmit: boolean = false;
    public allow: boolean = false;
    public listingIssue: string = '';
    public dropdown: any;
    public selectedQuestion: any = 'Select Question';
    public isNull:any;
    public content:any;
    constructor(private appService: AppService,
        private router: Router,public translate: TranslateService,
        private authService: AuthServiceService,
        private popupService: PopupService,private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute) {
        this.showLoader = false;
        this.activatedRoute.queryParams.subscribe(params => {
            this.type = params['type'];
            this.navigationFrom = params.nav_from;
            if (this.type == 'refersh') {
                let clean_uri = location.protocol + "//" + location.host + location.pathname;
                window.history.replaceState({}, document.title, clean_uri);
            }
            this.config = {
                slidesPerView: 'auto',
                pagination: false,
                keyboard: false,
                navigation: true,
            };
        });
        this.programId = this.activatedRoute.snapshot.paramMap.get('token');
    }
    ngOnInit() {

        this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.isNull = localStorage.getItem('isNull')
        this.getProgramDetail()
        this.getProfile()
        window.scrollTo(0, 0);
    }

    checkClass() {
        this.class = !this.status;
    }
    goToVideos(){
         this.router.navigate(['/program/videos'],{queryParams:{ 'nav_from': this.navigationFrom, 'program_id': this.programId }})
    }
    getQuestionnaire() {
        this.appService.get(urls.getRatingQuestions).subscribe((data: any) => {
            if (data && data['result'] && data['result'].qustionnaireList.length > 0) {
                this.questionsList = data['result'].qustionnaireList
                this.questionsList.forEach((ele, ind) => {
                    this.value.push(ele.ratings)
                })

                this.selectedRating[this.selected] = this.value[this.selected]['4']
                this.ratingInfo[this.selected] = {
                    'questionId': this.questionsList[this.selected]._id,
                    'rating': 4
                }
            }
        })
    }
    change(index) {
        this.selected = index;
        if (!this.selectedRating[this.selected]) {
            this.selectedRating[this.selected] = this.value[this.selected]['4'];
            this.ratingInfo[this.selected] = {
                'questionId': this.questionsList[this.selected]._id,
                'rating':4
            }
        }
        if (this.selected == this.questionsList.length - 1) {
            this.showSubmit = true;
        }
        else {
            this.showSubmit = false;
        }
    }
    previous() {
        if (this.selected > 0) {
            this.selected = this.selected - 1;
        }
        if (this.showSubmit) {
            this.showSubmit = false;
        }
    }
    next() {
        if (this.selected < this.questionsList.length - 1) {
            this.selected = this.selected + 1;
            if (!this.selectedRating[this.selected]) {
                this.selectedRating[this.selected] = this.value[this.selected]['4'];
                this.ratingInfo[this.selected] = {
                    'questionId': this.questionsList[this.selected]._id,
                    'rating':4
                }
            }
        }
        if (this.selected == this.questionsList.length - 1) {
            this.showSubmit = true;
        }
    }
    select(value, i,rate) {
        this.selectedRating[i] = value;
        this.ratingInfo[i] = {
            'questionId': this.questionsList[i]._id,
            'rating':rate
        }
    }
    getProfile() {
        this.appService.get(urls.getProfile)
            .subscribe((res: any) => {
                this.role = res.result.user.role
            })
    }

    goToProductsList(id) {
        this.router.navigate(['/program/product-list'], { queryParams: { id: id } });
    }

    navigateToProductDetail(id) {
        this.router.navigate(['/program/product-details'], { queryParams: { id: id } });
    }

    getPrimaryProductImage(product) {

        let imgObj = product.images.filter(function (image) {
            return image.primary == true;
        });
        if (imgObj.length == 0) {
            this.show == true;
        }
        else {
            return imgObj[0].url;
        }

    }

    getProgramDetail() {
        this.showLoader = true;
        this.appService.get(urls.programDetails + this.programId)
            .subscribe(
                (response: any) => {
                    this.showLoader = false;
                    if (response.sc == 1) {
                        if (response.result.program.is_like) {
                            this.like = true;
                        }
                        if (response.result.program.gender == 1) {
                            this.genderType = 'Male';
                        }
                        if (response.result.program.gender == 2) {
                            this.genderType = 'Female';
                        }
                        if (response.result.program.gender == 3) {
                            this.genderType = 'Other';
                        }
                        if (response.result.program.gender == 4) {
                            this.genderType = 'Both';
                        }
                        this.bucketArray = response.result.program.buckets;
                        this.programType = response.result.program.program_type;
                        if (response.result.program.spring_season == "1") {
                            this.springSeason = "Spring, "
                        }
                        if (response.result.program.winter_season == "1") {
                            this.winterSeason = "Winter, "
                        }
                        if (response.result.program.fall_season == "1") {
                            this.fallSeason = "Fall, "
                        }
                        if (response.result.program.summer_season == "1") {
                            this.summerSeason = "Summer,"
                        }
                        this.season = this.springSeason + this.winterSeason + this.fallSeason + this.summerSeason
                        this.season = this.season.slice(0, -1);
                        this.like_count = response.result.program.like_count;
                        this.favourite = response.result.program.is_favourite;
                        this.enrolldata = response.result;
                        this.programDetail = response.result.program;
                        this.banner_image = (response.result.program.banner_image == '') ? './assets/img/default_image.png' : response.result.program.banner_image;
                        this.video_url = response.result.program.video_url
                        if(response.result.program.latlng){
                            this.lat = response.result.program.latlng[1]
                            this.long = response.result.program.latlng[0]
                        }
                        if (this.video_url) {
                            let youTube = this.video_url.includes(environment.youtubeLink);
                            if (youTube) {
                                this.embededVideo = true;
                                this.embededCode = environment.youtubeEmbedLink + this.video_url.slice(17);
                            } else {
                                youTube = this.video_url.includes(environment.youtubeWatchLink);
                                if (youTube) {
                                    this.embededVideo = true;
                                    let youtubeUrl = (this.video_url.slice(32).split('&'))
                                    this.embededCode = environment.youtubeEmbedLink + youtubeUrl[0];
                                }
                            }
                        }

                        if (response.result.program.agencyStore && response.result.program.agencyStore.length) {
                            this.productArr = response.result.program.agencyStore;
                        }
                        if (response.result.rateProgram == true) {
                            this.openModal('reviews')
                        }
                    }
                },
                (error) => {
                    this.showLoader = false;
                    console.log(error);
                }
            )
    }

    markLike() {
        if (this.authService.isLoggednIn()) {
            let params = {
                "programId": this.programId,
                "is_like": true
            }
            this.appService.post(urls.likeProgram, params)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.programDetail.like_count = this.programDetail.like_count + 1;
                            this.like = true;
                        }
                    });
        } else {
            this.getPopup('');
            this.router.navigate([""]);
        }

    }
    getSafeUrl(data) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(data);
      }
    markUnlike() {
        if (this.authService.isLoggednIn()) {
            let params = {
                "programId": this.programId,
                "is_like": false
            }
            this.appService.post(urls.likeProgram, params)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.programDetail.like_count = this.programDetail.like_count - 1;
                            this.like = false;
                        }
                    });
        } else {
            this.getPopup('');
            this.router.navigate([""]);
        }

    }

    shareProgram() {
        this.subject = '';
        window.location.href = "mailto:" + this.toEmail + "?subject" + this.subject + "&body=" + window.location.href;
    }

    checkIn() {
        if (this.role == 1) {
            this.getPopup('checkIn')
        }
        else {
            this.executeCheckIn();
        }

    }
    fetchLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.geolocationPosition = position;
                    localStorage.setItem("lat", (position.coords.latitude).toString());
                    localStorage.setItem("lng", (position.coords.longitude).toString());
                    this.checkin();
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.appService.showError(toastMessage.permissionDenied);
                            break;
                        case 2:
                            this.appService.showError(toastMessage.postionUnavailable);
                            break;
                        case 3:
                            this.appService.showError(toastMessage.timeout);
                            break;
                    }
                },
                {maximumAge:10000, timeout:5000, enableHighAccuracy:true}
            );
        }
    }
    executeCheckIn() {
        if (this.authService.isLoggednIn()) {

            if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
                this.checkin();
            }
            else {
                this.fetchLocation();
            }

        }
        else {
            this.getPopup('');
            this.router.navigate(["/home/list"]);
        }
    }
    checkin() {
        this.latitude = localStorage.getItem("lat")
        this.longitude = localStorage.getItem("lng")
        if (this.latitude && this.longitude) {
            let params = {
                "programId": this.programId,
                "child_ids": [],
                "latitude": this.latitude,
                "longitude": this.longitude,
                "date": new Date().valueOf(),
                "time_zone": (this.currentTimezone).toString()
            }
            this.appService.post(urls.programCheckIn, params)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.appService.showSuccess(response.result.message);
                        } else {
                            this.appService.showError(response.error.msg);
                        }
                    });
        } else {
            this.appService.showError(toastMessage.locationDenied);
        }
    }
    getPopup(popupType): void {
        this.popupService.showPopup(popupType);

    }

    markFavorite(id, favType) {
        if (this.authService.isLoggednIn()) {
            let params = {
                "programId": id,
                "isFav": (favType) ? false : true
            };
            this.appService.post(urls.programFavorite, params)
                .subscribe(
                    (response: any) => {

                        if (response.sc == 1) {
                            this.favourite = (favType) ? false : true
                        }
                    });
        } else {
            this.getPopup('');
            this.router.navigate([""]);
        }
    }

    showPremiumPorgram(showType) {
        if (showType == 'video') {
            this.showImage = false;
        } else {
            this.showImage = true;
        }
    }

    openModal(pop: any) {
        this.modal = document.getElementById(pop);
        this.modal.style.display = "block";
        if (pop == 'report') {
            this.reportDescription = '';
        }
        if(pop == 'reviews'){
            this.getQuestionnaire();
        }
    }

    closemodal() {
        this.activeIndex = null;
        this.btn = document.getElementsByClassName("close")[0];
        this.modal.style.display = "none";
    }

    clickEvent(index) {
        if (this.activeIndex != index + 1) {
            this.reportDescription = '';
        }
        this.activeIndex = index + 1;
    }

    reportProgram() {
        if (this.authService.isLoggednIn()) {
            if (this.reportDescription && this.reportDescription.trim().length == 0) {
                this.appService.showError(toastMessage.descLengthCheck);
            }
            if (this.reportDescription && this.reportDescription.length) {
                    this.subject = "I am curious"
                    this.toEmail = this.programDetail.contact_email_address
                    if(this.selectedQuestion !="Select Question"){
                        this.content = `${this.selectedQuestion}%0D%0A${this.reportDescription}`
                    }
                    else{
                        this.content = `${this.reportDescription}`
                    }
                    window.location.href = "mailto:" + this.toEmail + "?subject=" + this.subject + "&body=" + this.content;
                    this.closemodal();
                    this.openModal('curiousSuccess')
            }

        } else {
            this.getPopup('');
            this.router.navigate([""]);
        }
    }
    goToReviews() {
        this.router.navigate(['/program/reviews'], { queryParams: { 'nav_from': this.navigationFrom, 'program_id': this.programId } })
    }
    add() {
        let formData = {
            program_id: this.programId,
            ratingInfo: this.ratingInfo,
        }
        if (this.model.review) {
            formData['review'] = this.model.review
        }
        this.appService.post(urls.rateProgram, formData)
            .subscribe(
                (response: any) => {
                    if (response.sc == 1) {
                        this.appService.showSuccess(response.result.message);
                        this.modal.style.display = "none";
                        this.getProgramDetail()
                        this.getProfile()
                    }
                    if (response.sc == 0) {
                        this.appService.showError(response.error.msg)
                    }
                });
    }

    enroll() {
        this.showLoader = true;
        let body = {
            "programId": this.programId,
        }
        this.appService.post(urls.enrollnow, body).subscribe(
            (response: any) => {
                this.showLoader = false;
                if (response.sc == 1) {
                    this.appService.showSuccess("Enroll Request Submitted");
                    this.closemodal()
                    this.openModal('enrollmentSuccess')
                    
                }
                else if (response.sc == 0) {
                    this.appService.showError(response['error'].msg);
                }
        });
    }
    gotit(){
        this.closemodal(); 
        this.getProgramDetail();
    }
    listIssue(){
        this.showLoader = true;
        if(this.listingIssue && this.listingIssue.trim().length == 0 ){
            this.appService.showError('Please enter aplhanumeric characters only spaces are not allowed');
            this.listingIssue = "";
            this.showLoader = false;
        }
        if(this.listingIssue && this.listingIssue.length){
            let body = {
                "issue" : this.listingIssue,
                "programId" : this.programId
            }
            this.appService.post(urls.listingIss, body).subscribe(
                (response: any) => {
                    this.showLoader = false;
                    if (response.sc == 1) {
                        this.appService.showSuccess(response['result'].message);
                        this.closemodal();
                        this.openModal('listingSuccess') 
                    }
                    else if (response.sc == 0) {
                        this.appService.showError(response['error'].msg);
                    }
            });
        }

    }
    // goToChildProgress(){
    //     this.router.navigate(['/program/child-progress'], { queryParams: { 'nav_from': this.navigationFrom, 'program_id': this.programId } })
    // }
    opendropdown() {
        this.dropdown = document.getElementById("myDropdown").classList.toggle("display");
      }
    setReport(report){
         this.selectedQuestion = report;
         this.dropdown = document.getElementById("myDropdown").classList.remove("display");
      }
      closereport(){
          this.selectedQuestion = "Select Question";
          this.dropdown = document.getElementById("myDropdown").classList.remove("display");
          this.btn = document.getElementsByClassName("close")[0];
          this.modal.style.display = "none";
      }
}
