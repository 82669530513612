import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicalInstructionsComponent } from './physical-instructions.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: PhysicalInstructionsComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule
  ],
  declarations: [PhysicalInstructionsComponent]
})
export class PhysicalInstructionsModule { }
