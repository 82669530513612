import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { NotificationRequestComponent } from './component/notification-request/notification-request.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [NotificationListComponent, NotificationRequestComponent],
})
export class NotificationsModule { }
