import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls, facebookConfig, fileSize, toastMessage } from '../../../../shared/services/constant';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../../../environments/environment';
declare var FB: any;

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public socialShareForm: FormGroup;
  public submitted = false;
  public socialShareApiResponse: boolean = true;
  public imgUrl: any;
  public linkedinConnect: boolean = false;
  public isLinkedInPresent: boolean = false;
  public twitterConnect: boolean = false;
  public isTwitterPresent: boolean = false;
  public socialConnect: boolean = false;
  public code: any;
  public state: any;
  public fileName = '';
  public linkedinToken: any;
  public twitterToken: any;
  public secret: any;
  public uploadImg: boolean = true;

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    window.scroll(0, 0)
  }

  ngOnInit() {
    this.getProfile();
    this.socialShareForm = this.formBuilder.group({
      desc: ['', [Validators.maxLength(300)]],
      image: [],
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state'];
      if (this.code && this.state) {
        let clean_uri = location.protocol + '//' + location.host + location.pathname;
        this.connectLinkedin(this.code, true);
        window.history.replaceState({}, document.title, clean_uri);
      }
    });
    FB.init(facebookConfig)
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.socialShareForm.controls;
  }

  onSubmit(socialShare) {
    let data = Object.assign({}, socialShare);
    this.submitted = true;
    // stop here if form is invalid
    if (this.socialShareForm.invalid) {
      return;
    } else {
      if (!this.socialConnect) {
        this.appService.showError(toastMessage.enableSocialShare);
      }
      else {
        if (!data.image && !data.desc) {
          this.appService.showError(toastMessage.contentCheck);
        }
        else {
          this.socialShareApiResponse = false;
          data.url = urls.sharePost;
          data.share_on = [];
          data.title = data.desc + '#SpotivityApp';
          if (this.twitterConnect) {
            data.twit = {
              'access_token_key': this.twitterToken,
              'access_token_secret': this.secret
            };
            data.share_on.push(2);
          }
          if (this.linkedinConnect) {
            data.linkedin = { 'oauth2_access_token': this.linkedinToken };
            data.share_on.push(1);
          }
          data.platform = 1;

          this.appService.socialShare(data)
            .subscribe(
              (response: any) => {
                let result = JSON.parse(response);
                if (result && result.sc == 1) {
                  this.socialShareForm.reset();
                  this.imgUrl = null;
                  this.socialShareForm.patchValue({ image: null });
                  this.uploadImg = true;
                  this.appService.showSuccess(result.result.message);
                } else {
                  this.appService.showError(toastMessage.SomethingWentWrong);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      }

    }
  }

  getProfile() {
    this.appService.get(urls.getProfile)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            if (response.result.user.connect_by_linkedin) {
              this.linkedinConnect = response.result.user.connect_by_linkedin;
              this.isLinkedInPresent = response.result.user.connect_by_linkedin;
              this.linkedinToken = response.result.user.linkedin && response.result.user.linkedin.oauth2_access_token;
            }
            if (response.result.user.connect_by_twit) {
              this.twitterConnect = response.result.user.connect_by_twit;
              this.isTwitterPresent = response.result.user.connect_by_twit;
              this.twitterToken = response.result.user.twit.access_token_key;
              this.secret = response.result.user.twit.access_token_secret;
            }
            this.socialConnect = this.linkedinConnect || this.twitterConnect;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  connectLinkedin(code, isConnect) {
    let params = {
      'linkedin': JSON.stringify({
        'oauth2_access_token': code
      }),
      'connect_by_linkedin': isConnect
    };
    this.appService.post(urls.linkedinConnect, params)
      .subscribe(
        (response: any) => {
          if (response.sc && response.sc == 1) {
            if (isConnect) {
              this.linkedinToken = code;
              localStorage.setItem('linkedin', 'true');
              localStorage.setItem('linkedinTokenget', code);
              this.linkedinConnect = true;
            } else {
              this.linkedinToken = '';
              localStorage.removeItem('linkedin');
              this.linkedinConnect = false;
            }
          } else {
            this.appService.showError(toastMessage.SomethingWentWrong);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getProfileDp(type) {
    const linkedinUrl = './assets/img/linkdin-edit.png';
    const twitterUrl = './assets/img/twitter-edit.png';
    const facebookUrl = './assets/img/facebook-edit.png';
    const instagramUrl = './assets/img/insta-edit.png';
    let st: any;
    if (type === 'twitter') {
      st = { background: `url(${twitterUrl})` };
    }
    else if (type === 'linkedin') {
      st = { background: `url(${linkedinUrl})` };
    }
    else if (type === 'facebook') {
      st = { background: `url(${facebookUrl})` };
    }
    else {
      st = { background: `url(${instagramUrl})` };
    }
    return st;
  }
  onFileChanged(event) {
    let imageType = event.target.files[0].type.split('/');
    if (imageType[0] == 'image') {
      if (event.target.files[0].size < fileSize && imageType[0] == 'image') {
        if (event.target.files.length) {
          let files = event.target.files;
          this.imgUrl = event.target.value;
          var that = this;
          let reader = new FileReader();
          reader.onload = function (event: any) {
            that.imgUrl = event.target.result;
          };
          reader.readAsDataURL(files[0]);
          this.uploadImg = false;
        }
        const file = event.target.files[0];
        this.socialShareForm.patchValue({ image: file });
      } else {
        this.appService.showError(toastMessage.imageSize);
      }
    } else {
      this.appService.showError(toastMessage.imageCheck);
    }
  }
  getPostImage() {
    let dummyImg = './assets/img/upload-icon.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }

  removeImage() {
    this.imgUrl = null;
    this.socialShareForm.patchValue({ image: null });
    this.uploadImg = true;
  }

  checkSocialConnect() {
    if (!this.socialConnect) {
      this.appService.showError(toastMessage.enableSocialShare);
    }
  }

  toggleSocial(param) {
    if (param === 'twitter') {
      this.twitterConnect = !this.twitterConnect;
    }
    else if (param === 'linkedin') {
      this.linkedinConnect = !this.linkedinConnect;
    }
    else if (param === 'facebook') {
      this.facebookPost();
    }
    this.socialConnect = this.twitterConnect || this.linkedinConnect;
  }

  facebookPost() {
    FB.ui({
      method: 'share',
      href: environment.facebookSharePost,
      hashtag: '#Spotivity'
    }, function (response) {
      console.log(response);
    })
  }
}