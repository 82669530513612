import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-management',
  templateUrl: './popup-management.component.html',
  styleUrls: ['./popup-management.component.scss']
})
export class PopupManagementComponent implements OnInit {

  public show1: boolean = false;
  public menuActive: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public show() {
    this.show1 = true;
    this.doToggle();
   
  }
  public hide() {
    this.show1 = false;
    this.doClose();
  }
  public doToggle() {
    this.menuActive = true;
    const el = document.scrollingElement || document.documentElement ;
    el.classList.add("modal-open");
  }

  public doClose() {
    this.menuActive = false;
    const el = document.scrollingElement || document.documentElement ;
    el.classList.remove("modal-open");
  }
}
