import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { PopupService } from '../../../../core/services/popup.service';
@Component({
  selector: 'app-profile-error-popup',
  templateUrl: './profile-error-popup.component.html',
  styleUrls: ['./profile-error-popup.component.scss']
})
export class ProfileErrorPopupComponent implements OnInit {
  public msgs: any;
  public canAddUserRoleType: string;
  
  constructor(private childServiceService: ChildServiceService,public translate: TranslateService,
    private popupService: PopupService) { }

  ngOnInit() {
    this.msgs = this.childServiceService.errorMessInfo;
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
}
