import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from '../../../../shared/services/app.service';
import { urls, facebookConfig, linkedUrl, twitUrl, instUrl } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import * as alertify from 'alertifyjs';
import { environment } from 'src/environments/environment';
declare var FB: any;
@Component({
    selector: 'app-social-info',
    templateUrl: './social-info.component.html',
    styleUrls: ['./social-info.component.scss']
})

export class SocialInfoComponent implements OnInit {

    public currentURL = '';
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public description: any;
    public title: string;
    public code: any;
    public state: any;
    public linkdinUrl: any;
    public linkedin: boolean = false;
    public inst: boolean = false;
    public twitter: boolean = false;
    public twitterURL: string;
    public oauthCallbackConfirmed: boolean;
    public oauthToken: string;
    public facebook: boolean = false;
    public instagram: boolean = false;
    constructor(private appService: AppService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
        this.currentURL = this.router.url.split('/').pop();
    }

    ngOnInit() {
        window.scroll(0, 0)
        if (localStorage.getItem('linkedin') && localStorage.getItem('linkedin') != 'false') {
            this.linkedin = true;
        }
        if (localStorage.getItem('twitter') && localStorage.getItem('twitter') != 'false') {
            this.twitter = true;
        }
        if (localStorage.getItem('facebook') && localStorage.getItem('facebook') != 'false') {
            this.facebook = true;
        }
        if (localStorage.getItem('instagram') && localStorage.getItem('instagram') != 'false') {
            this.instagram = true;
        }
        this.activatedRoute.queryParams.subscribe(params => {
            this.code = params['code'];
            this.state = params['state'];
            if (this.code && this.state) {
                let clean_uri = location.protocol + '//' + location.host + location.pathname;
                this.getLinkedinToken(this.code, true);
                window.history.replaceState({}, document.title, clean_uri);
            }
            if (params['oauth_token'] && params['oauth_verifier']) {
                this.twitterContent(true, params['oauth_token'], params['oauth_verifier']);
            }
        });
        //For Facebook
        FB.init(facebookConfig);
        //For Instagram
        this.activatedRoute.queryParams.subscribe(params => {
            let token;
            let varrr = (window.location.href.split('='));
            if (varrr.length == 2) {
                token = (window.location.href.split('='))["1"];
            }
            if (token) {
                this.getInstagramLogin(token);
            }
        });
    }

    getQueryStringParameters = url => {
        if (url) {
            if (url.split('?').length > 0) {
                var query = url.split('?')[1];
            }
        } else {
            url = window.location.href;
            query = window.location.search.substring(1);
        }
        return (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {});
    };

    getLinkedinToken(code, isConnect) {
        let params = {
            'linkedin': JSON.stringify({
                'oauth2_access_token': code,
                redirect_url: localStorage.getItem('linkedin_redirect_url')
            }),
            'connect_by_linkedin': isConnect,
            'isWeb': true
        };
        this.appService.post(urls.socialConnect, params)
            .subscribe(
                (response: any) => {
                    if (response.sc && response.sc == 1) {
                        if (isConnect) {
                            localStorage.setItem('linkedin', 'true');
                            this.linkedin = true;
                        }
                        else {
                            localStorage.removeItem('linkedin');
                            this.linkedin = false;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    connectLinkedin(type) {
        console.log(window.location.origin)
        if (type == 'disconnect') {
            alertify.confirm('Are you sure?',
                'Do you really want to disconnect your LinkedIn account? By clicking on "OK" you will no longer get reward points by social posts.',
                () => {
                    this.getLinkedinToken('', false);
                }
                , function () {
                });
        } else {
            localStorage.setItem('linkedin_redirect_url', window.location.origin + '/user/editProfile/social-info');
            console.log(window.location.origin)
            window.location.href = linkedUrl + '&redirect_uri=' + window.location.origin + '/user/editProfile/social-info';
        }
    }


    twitterLogin(type) {
        if (type == 'disconnect') {
            alertify.confirm('Are you sure?',
                'Do you really want to disconnect your Twitter account? By clicking on "OK" you will no longer get reward points by social posts.',
                () => {
                    this.twitterContent(false, '', '');
                }
                , function () {
                });
        }
        else {
            let body = {
                'type': 1
            }
            this.appService.post(urls.twitterConnect, body)
                .subscribe(
                    (rsp: any) => {
                        console.log(rsp)
                        if (rsp.sc === 1) {
                            this.oauthCallbackConfirmed = true;
                            var qsParams = this.getQueryStringParameters(rsp.result['url']);
                            localStorage.setItem('twitterSecretKey', qsParams.oauth_secret);
                            this.twitterURL = rsp.result['url'];
                            window.location.href = this.twitterURL;
                        } else {
                            this.oauthCallbackConfirmed = false;
                            console.log('error: ' + rsp);
                        }
                    },
                    error => {
                        this.oauthCallbackConfirmed = false;
                        console.log(error);
                    }
                );
            if (this.oauthCallbackConfirmed === true) {
                window.location.href = twitUrl + environment.twitKey;
                window.location.href = twitUrl + this.oauthToken;
            }
        }
    }

    twitterContent(isConnect, token, verifier) {
        var arr = {
            'oauth_token': token,
            'oauth_verifier': verifier,
            'oauth_secret': localStorage.getItem('twitterSecretKey')
        };
        let params = {
            'twit': JSON.stringify(arr),
            'connect_by_twit': isConnect,
            'isWeb': true
        };
        this.appService.post(urls.socialConnect, params)
            .subscribe(
                (response: any) => {
                    console.log(response)
                    if (response.sc && response.sc == 1) {
                        if (isConnect) {
                            this.twitter = true;
                            localStorage.setItem('twitter', 'true');
                        } else {
                            this.twitter = false;
                            localStorage.removeItem('twitter');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    facebookLogin(type) {
        if (type == 'disconnect') {
            alertify.confirm('Are you sure?',
                'Do you really want to disconnect your Facebook account? By clicking on "OK" you will no longer get reward points by social posts.',
                () => {
                    this.facebookContent('', false);
                }
                , function () {
                });
        }
        else {
            FB.login(response => {
                this.statusChangeCallback(response);
            }, { scope: 'public_profile,email' });
        }
    }

    statusChangeCallback(response) {
        if (response.status === 'connected') {
            let accessToken = response.authResponse.accessToken;
            this.facebookContent(accessToken, true);
        }
    }

    facebookContent(token, isConnect) {
        let params = {
            'fb': JSON.stringify({
                'oauth2_access_token': token,
            }),
            'connect_by_fb': isConnect,
            'isWeb': true
        };
        this.appService.post(urls.socialConnect, params)
            .subscribe(
                (response: any) => {
                    if (response.sc && response.sc == 1) {
                        if (isConnect) {
                            this.facebook = true;
                            localStorage.setItem('facebook', 'true');
                        } else {
                            this.facebook = false;
                            localStorage.removeItem('facebook');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    instagramLogin(type) {
        if (type == 'disconnect') {
            alertify.confirm('Are you sure?',
                'Do you really want to disconnect your Instagram account? By clicking on "OK" you will no longer get reward points by social posts.',
                () => {
                    this.instagramContent('', false);
                }
                , function () {
                });
        }
        else {
            window.location.href = instUrl + '&redirect_uri=' + window.location.origin +'/user/editProfile/social-info&response_type=token';
        }
    }
    getInstagramLogin(token) {
        let Token = token;

        this.instagramContent(Token, true);
    }

    instagramContent(token, isConnect) {
        let params = {
            'inst': JSON.stringify({
                'oauth2_access_token': token,
            }),
            'connect_by_inst': isConnect,
            'isWeb': true
        };
        this.appService.post(urls.socialConnect, params)
            .subscribe(
                (response: any) => {
                    if (response.sc && response.sc == 1) {
                        if (isConnect) {
                            this.instagram = true;
                            localStorage.setItem('instagram', 'true');
                        } else {
                            this.instagram = false;
                            localStorage.removeItem('instagram');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
}
