import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from "../../../../shared/services/app.service";
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { urls ,toastMessage} from '../../../../shared/services/constant';
import { PopupService } from 'src/app/core/services/popup.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedCardsComponent implements OnInit {
  public viewLoading: boolean = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public page_no: number = 1;
  public pageSize: number = 10;
  public cardDetails: any = {};
  public name: any;
  public ccNum: any;
  public ccRefNum: any;
  public cardList: any = [];
  public modal: any;
  public activeIndex: number;
  public newCardForm: FormGroup;
  public newCard: FormGroup;
  public model: any = {};
  public ccExp: any;
  public refNum: any;
  public Cardcvv: any;
  public submitnewCard: boolean = false;
  public show: boolean = false;
  public years: any = [];
  public selectedMonth: any;
  public selectedYear: any;
  public showMonthError: any;
  public showYearError: any;
  public expiryMonth: any = 'MM';
  public expiryYear: any = 'YYYY';
  public monthArr: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  constructor(private appService: AppService, private popupService: PopupService,private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,public translate: TranslateService) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formInitialization();
    this.getCardList();
    let i: number = 0;
    while (i < 40) {
      this.years.push({
        'year': new Date().getFullYear() + i
      })
      i++;
    }
  }
  formInitialization() {
    this.newCardForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      cardNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(19), Validators.pattern('[0-9]+')]],
      cvv: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(4)]],
    });
  }
  get f() {
    return this.newCardForm.controls;
  }
  getCardList() {
    this.cardList = [];
    this.submitnewCard = false;
    this.modal = document.getElementById("addCard").classList.remove("show");
    this.viewLoading = true;
    this.apiParam.appJson = true;
    this.appService.get(urls.cardList)
      .subscribe(
        (response: any) => {
          if (response['result'] && response['result'].cards && response['result'].cards.data && response['result'].cards.data.length) {
            let data: any = response['result'].cards.data;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.cardList.push(ele);
              })
            }
          }
          else {
            this.show = true;
          }
          this.viewLoading = false;
          this.cd.detectChanges();
        },
        (error) => {
          console.log(error);
        }
      );
  }
  opendropdown() {
    this.modal = document.getElementById("myDropdown").classList.toggle("display");
  }
  showdropdown() {
    this.modal = document.getElementById("myDropdown1").classList.toggle("dish");
  }
  showadd() {
    this.show = false;
    this.submitnewCard = false;
    this.model.Name = '';
    this.model.cardNumber = '';
    this.expiryMonth = 'MM';
    this.expiryYear = 'YYYY';
    this.selectedYear = '';
    this.selectedMonth = '';
    this.model.cvv = '';
    this.newCardForm.reset();
    this.modal = document.getElementById("addCard").classList.toggle("show");
    this.showMonthError = '',
    this.showYearError = ''
    this.cd.detectChanges();
  }
  delete(id) {
    console.log(id)
    let params = {
      "card_id": id
    }
    this.appService.post(urls.deleteCard, params)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.appService.showSuccess(response.result.message.message);
            this.getCardList();
          }
          if (response.sc == 0) {
            this.appService.showError(response.error.msg)
          }
        });
  }
  setMonth(month) {

    this.expiryMonth = month;
    this.selectedMonth = month;
    this.showMonthError = '';
    this.modal = document.getElementById("myDropdown").classList.remove("display");

  }
  setYear(year) {
    this.expiryYear = year;
    this.selectedYear = year;
    this.showYearError = '';
    this.modal = document.getElementById("myDropdown1").classList.remove("dish");
  }
  getToken() {
    this.submitnewCard = true;
    if (this.expiryMonth == 'MM') {
      this.showMonthError = "Month is required"
    }
    if (this.expiryYear == 'YYYY') {
      this.showYearError = "Year is required"
    }
    if (this.expiryYear == ((new Date()).getFullYear()) && this.expiryMonth < ((new Date()).getMonth())) {
      this.appService.showError(toastMessage.currentMonthCheck)
    }
    else if ((this.newCardForm.valid && this.expiryMonth != 'MM' && this.expiryYear != 'YYYY')) {
      (<any>window).Stripe.card.createToken({
        name: this.model.Name,
        number: this.model.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.model.cvv
      }, (status: number, response: any) => {
        if (status === 200) {
          let that = this;
          let newCardData = {
            token: response.id
          }
          this.appService.post(urls.addCard, newCardData)
            .subscribe(
              (response: any) => {
                if (response.sc == 1) {
                  this.appService.showSuccess(toastMessage.cardAdd);
                  that.getCardList();
                }
                if (response.sc == 0) {
                  this.appService.showError(response['error'].msg);
                }
              },
              (error) => {
                console.log(error);
              }
            )
        } else {
          if (response.error.message)
            this.appService.showError(response.error.message)
          else
            this.appService.showError('Something went wrong!!!!')
        }
      });
    }
  }
  keypress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
        return event;
    }
    else {
        return false;
    }
}
}
