import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public token: any;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public changePasswordApiResponse: boolean = true;
  public hoverMeImg: number = null;
  public hoverMeImg1: number = null;
  public msgDisplay: string = "Password must contain 8 characters, at least 1 number, and a special character(!@#$%^&*)";

  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private popupService: PopupService,
    public translate: TranslateService) {
    this.token = this.router.url.split("/").pop()
  }

  ngOnInit() {
    window.scroll(0,0);
    this.resetPasswordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8),Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#&])[A-Za-z\d@$!%*^#&]{8,}$/)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
    this.appService.minHeightGlobally();
    // Analytics.record({ name: 'Change Password' });
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  onSubmit(params) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.changePasswordApiResponse = false;
      let obj = {
        "oldPassword": params.oldpassword,
        "nPassword": params.password
      };

      this.appService.post(urls.passwordChanged, obj)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.appService.showSuccess(response.result.message);
              this.getPopup('');
              this.router.navigate(["/settings/user-settings"]);
            } else {
              this.appService.showError(response.error.err);
              this.changePasswordApiResponse = true;
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }

  hoverMe(val?) {
    if (val) {
      this.hoverMeImg = val
    }
    else {
      this.hoverMeImg = null
    }
  }

  hoverMe1(val?) {
    if (val) {
      this.hoverMeImg1 = val
    }
    else {
      this.hoverMeImg1 = null
    }
  }
  getPopup(popupType): void {
    this.popupService.showPopup(popupType);

  }
}
