import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { BasicInfoComponent } from './component/basic-info/basic-info.component';
import { MoreInfoComponent } from './component/more-info/more-info.component';
import { SocialInfoComponent } from './component/social-info/social-info.component';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { InsightToolComponent } from './component/insight-tool/insight-tool.component';
import { ParentsComponent } from './component/parents/parents.component';

const routes: Routes = [
    {
        path: 'reset-password',
        loadChildren: './component/reset-password/reset-password.module#ResetPasswordModule'
    },
    {
        path: 'reset-password/:token',
        loadChildren: './component/reset-password/reset-password.module#ResetPasswordModule'
    },
    {
        path: 'set-password/:token',
        loadChildren: './component/set-password/set-password.module#SetPasswordModule'
    },
    {
        path: 'profile',
        loadChildren: './component/profile/profile.module#ProfileModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'saved-locations',
        loadChildren: "./component/saved-locations/saved-locations.module#SavedLocationsModule",
        canActivate: [AuthGuardService],
    },
    {
        path: 'parents',
        component: ParentsComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'badges',
        loadChildren: './component/badges/badges.module#BadgesModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'mycheck-in',
        loadChildren: './component/mycheck-in/mycheck-in.module#MycheckInModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'personal-insight-tool',
        component: InsightToolComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'personal-insight',
        loadChildren : './component/pit/pit.module#PitModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'test-instructions',
        loadChildren : './component/eq-pq-instructions/eq-pq-instructions.module#EqPqInstructionsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'tests',
        loadChildren : './component/eq-pq-test/eq-pq-test.module#EqPqTestModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'academic-instructions',
        loadChildren : './component/academic-instructions/academic-instructions.module#AcademicInstructionsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'academic-records',
        loadChildren : './component/academic-record/academic-record.module#AcademicRecordModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'physical-instructions',
        loadChildren : './component/physical-instructions/physical-instructions.module#PhysicalInstructionsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'physical-characteristics',
        loadChildren : './component/physical-record/physical-record.module#PhysicalRecordModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'change-password',
        loadChildren: './component/change-password/change-password.module#ChangePasswordModule'
    },
    {
        path: 'linked-agencies', 
        loadChildren: './component/linked-agencies/linked-agencies.module#LinkedAgenciesModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'agency-programs',
        loadChildren: './component/agency-programs/agency-programs.module#AgencyProgramsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'create-transcript',
        loadChildren: './component/create-transcript/create-transcript.module#CreateTranscriptModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'reward-center',
        loadChildren: './component/rewards-center/rewards-center.module#RewardsCenterModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'child-programs',
        loadChildren: './component/child-programs/child-programs.module#ChildProgramsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'linked-programs',
        loadChildren: './component/linked-programs/linked-programs.module#LinkedProgramsModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'newsroom',
        loadChildren: './component/newsroom/newsroom.module#NewsroomModule',
        canActivate: [AuthGuardService],
    },
    {
        path: 'editProfile',
        component: EditProfileComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: 'basic-info', component: BasicInfoComponent },
            { path: 'more-info', component: MoreInfoComponent },
            { path: 'social-info', component: SocialInfoComponent },
            { path: '**', redirectTo: 'basic-info', pathMatch: 'full' }
        ]    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule {
}
