import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqPqTestComponent } from './eq-pq-test.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: EqPqTestComponent
  }
];
@NgModule({
  imports: [
    CommonModule,RouterModule.forChild(routes),TranslateModule
  ],
  exports: [RouterModule],
  declarations: [EqPqTestComponent]
})
export class EqPqTestModule { }
