import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { CountryISO } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-search-child',
  templateUrl: './search-child.component.html',
  styleUrls: ['./search-child.component.scss']
})
export class SearchChildComponent implements OnInit {
  @Output() isAlreadyAdd: EventEmitter<any> = new EventEmitter<any>();
  public searchChildForm: FormGroup;
  public submitted = false;
  public loginRole: any;
  public canAddUserRoleType: string;
  public country_code:any = '1';
  CountryISO = CountryISO;
  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private popupService: PopupService,

    private childServiceService: ChildServiceService) { }

  ngOnInit() {
    this.loginRole = localStorage.getItem("loginRole");
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
    this.searchChildForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(10), Validators.maxLength(15)]]
    });
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  get f() { return this.searchChildForm.controls; }

  onSubmit(user) {
    this.submitted = true;
    if (this.searchChildForm.invalid) {
      return;
    } else {
      let params = {
        "phone_no": user.phone_no
      };
      this.appService.post(urls.searchUser, params)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {

              localStorage.setItem("member_status", response.result.member_status)
              if (response.result.member_status == 1 || response.result.member_status == 4) {
                localStorage.setItem("child_no", user.phone_no);
                localStorage.setItem("childCountryCode",this.country_code)
                this.childServiceService.childInfo = response.result.user
                if (response.result.member_status == 1) {
                  this.childServiceService.errorMessInfo = "Hi " + localStorage.getItem("fName") + ", " + "\"" + user.phone_no + "\"" + " is not registered into our system. Please create an account for your child with this phone number.";
                  this.childServiceService.checkWhetherParentChildExists = true;
                } else {
                  localStorage.setItem("user_reg_id", response.result.user._id);
                  sessionStorage.setItem("newChildName", response.result.user.first_name);

                  this.childServiceService.errorMessInfo = "Hi " + localStorage.getItem("fName") + ", " + "\"" + user.phone_no + "\"" + " is already registered into our system with a name" + "\"" + response.result.user.first_name + "\"" + ".";
                }
                this.isAlreadyAdd.emit(response.result.user);
                observableOf(null).pipe(delay(400)).subscribe(() => {
                  this.getPopup('addChild');
                })
              } else if (response.result.member_status == 2) {
                sessionStorage.setItem("newChildName", response.result.user.first_name);
                this.childServiceService.errorMessInfo = "Entered phone number " + "\"" + user.phone_no + "\"" + " is already your child with a name " + "\"" + response.result.user.first_name + "\"" + ".";
                this.getPopup('addProfileError');
              } else {
                sessionStorage.setItem("newChildName", response.result.user.first_name);
                this.childServiceService.errorMessInfo = "Entered phone number " + "\"" + user.phone_no + "\"" + " is already your parent with a name " + "\"" + response.result.user.first_name + "\"" + ".";
                this.getPopup('addProfileError');
              }
            } else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }
}
