import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { urls } from '../../../../shared/services/constant';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss']
})
export class ParentsComponent implements OnInit {
  public isParent = false;
  public roleName: any;
  public childArray: any = [];
  public childPic: any;
  public role: number = null;
  public viewLoading: boolean = false;
  public skip: number = 0;
  public limit: number = 10;
  public notificationRequestList: any = [];
  public notificationRequestCount: number = 0;
  constructor(private appService: AppService,
    private router: Router,public translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getProfile();
    this.getNotifications();
  }
  getNotifications(){
    this.appService.get(urls.getPendingRequestsList + "/" + this.skip + "/" + this.limit)
      .subscribe(
        (response: any) => {
          if (response.result != null && response.result.requests.length > 0) {
            this.notificationRequestList = response.result.requests;
            this.notificationRequestCount = response.result.requests.length;
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  acceptRejectRequest(reponseType: string, requestData: any) {
    let params = {
      "request_id": requestData._id,
      "senderId": requestData.senderId,
      "request_status": reponseType
    };
    this.appService.post(urls.acceptRejectRequest, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.appService.showSuccess(response.result.message);
          }
          else {
            this.appService.showError(response.error.msg);
          }
          this.notificationRequestList = this.notificationRequestList.filter(item => item._id !== requestData._id);
          this.notificationRequestCount = this.notificationRequestList.length;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  goToParent() {
    this.router.navigate(['/parent/search-parent']);
  }
  removeEntity(data: any) {
    let params = { 'id': data.id };
    this.appService.post(urls.removeChildParent, params).subscribe((response: any) => {
      if (response && response.sc === 1) {
        this.appService.showSuccess(response.result.message);
        this.childArray = this.childArray.filter(item => item.id !== data.id);
      } else {
        this.appService.showError(response.error.msg);
      }
    }, (error) => {
      console.log(error);
    });
  }
  getProfile() {
    this.viewLoading = true;
    this.appService.get(urls.getProfile).subscribe((response: any) => {
      if (response && response.sc === 1) {
        this.viewLoading = false;
        localStorage.setItem('loginRole', response.result.user.role);
        //login role 1 = parent and 2 = child
        localStorage.setItem('linkedin', response.result.user.connect_by_linkedin);
        localStorage.setItem('instagram', response.result.user.connect_by_inst);
        localStorage.setItem('twitter', response.result.user.connect_by_twit);
        //set the role which logged in user can add
        localStorage.setItem('canAddUserRoleType', response.result.user.role === 1 ? 'Child' : 'Parent');

        // set badges and current badge
        this.isParent = response.result.user.role === 1;
        this.roleName = (response.result.user.role === 1) ? 'Child' : 'Parent/Guardian';
        this.childArray = [];
        if (response.result && response.result.details) {
          let AccData = (response.result.details.users) ? response.result.details.users : [];
          if (AccData && AccData.length > 0) {
            AccData.forEach((ele, ind) => {
              var currentYear = new Date().getFullYear();
              var dYear = new Date(ele.dob).getFullYear();
              var age = currentYear - dYear;
              this.childPic = (ele.profile_pic) ? ele.profile_pic : './assets/img/blank-image.png';
              let obj = { 'first_name': ele.first_name, 'last_name': ele.last_name, 'profile_pic': this.childPic, 'id': ele._id, 'age': age };
              this.childArray.push(obj);
            });
          }
        }

        this.role = response.result.user.role;
      }
    }, (error) => {
      this.viewLoading = false;
      console.log(error);
    });
  }
  openLeaderBoard(id) {
    this.router.navigate(['/wallet/leader-board'], { queryParams: { id: id } });
  }
  programList(id){
     this.router.navigate(['/user/child-programs'],{queryParams: { id: id }});
  }
}
