import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from "../../../../shared/services/app.service";
import { AuthServiceService } from "../../../../core/services/authService/auth-service.service";
import { ApiParams, ApiResponse } from "../../../../shared/model/apiResponse";
import { urls, toastMessage } from '../../../../shared/services/constant';
import { map, filter, debounceTime, tap, switchAll, throttleTime, timestamp, delay } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { fromEvent } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    public programOwner: string = '';
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public page_no: number = 1;
    public pageSize: number = 10;
    public showNextData = true;
    public cartList: any = [];
    public resultsScrollObservable: any;
    public scrollSubscription: any;
    public cartDetails: any = {};
    public address: string = '';
    public total_amount: number = 0;
    public quantityArr: any = [];
    public cart: boolean = true;
    public viewLoading: boolean = false;
    constructor(private appService: AppService,
        private router: Router,public translate: TranslateService,
        private toastr: ToastrService) {
    }

    ngOnInit() {
        observableOf(null).pipe(delay(400)).subscribe(() => {
            window.scrollTo(0, 0);

        });

        this.resultsScrollObservable = fromEvent(window, 'scroll');
        /**
         * Captures scrolling behavior within teh application, so we can implement infinite
         *  scroll or hiding elements once a certain amount of scrolling is done.
         */
        this.scrollSubscription = this.resultsScrollObservable
            .pipe(
                throttleTime(250),
                map((event: any) => {
                    if (event) {
                        const elem = event.srcElement ? event.srcElement : event.target;
                        const srcElement = elem.body;
                        const clientHeight = srcElement ? srcElement.clientHeight : 0;
                        const el = document.scrollingElement || document.documentElement;
                        const scrollTop = el.scrollTop;
                        const position = scrollTop + clientHeight;
                        const height = srcElement ? srcElement.scrollHeight : 0;
                        const positionPercent = +((position / height).toFixed(2));
                        return positionPercent > .85;
                    }
                }),
                filter((doSearch: boolean) => doSearch)
            )
            .subscribe((doSearch: boolean) => {
                if (this.showNextData) {
                    this.page_no = this.page_no + 1;
                    this.getCartList();
                }

            });
        this.getCartList();

    }

    getCartList() {
        this.cart = true;
        this.viewLoading = true;
        this.apiParam.appJson = true;
        this.appService.get(urls.cartList + `page=${this.page_no}&page_size=${this.pageSize}`)
            .subscribe(
                (response: any) => {
                    this.viewLoading = false;
                    this.showNextData = false;
                    if (response['result'] && response['result'].cart_products && response['result'].cart_products.length) {
                        let data: any = response['result'].cart_products;
                        this.cartDetails = response['result'];
                        this.address = this.cartDetails.shipping_address;
                        this.total_amount = this.cartDetails.item_total;
                        if (data && data.length > 0) {
                            data.forEach((ele, ind) => {
                                this.cartList.push(ele);
                                this.quantityArr.push(ele.quantity);
                            });
                            this.showNextData = true;
                        }

                        localStorage.setItem('ownername', this.cartList[0].owner_name)
                    }
                    else if (response['result'] && !response['result'].hasOwnProperty('cart_products')) {
                        this.cartList = [];
                        this.cart = false;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    navigateToProductDetail(id) {
        this.router.navigate(['/program/product-details'], { queryParams: { id: id } });
    }

    getPrimaryProductImage(product) {
        let imgObj = product.images.filter(function (image) {
            return image.primary == true;
        });
        return imgObj[0].url;
    }

    removeItem(id) {
        this.appService.delete(urls.removeFromCart + `?_id=${id}`, {})
            .subscribe(
                (response: any) => {
                    if (response.sc == 1) {
                        this.appService.showSuccess(response.result.message);
                        let index = this.cartList.findIndex(ele => ele._id == id);
                        this.cartList.splice(index, 1);
                        this.quantityArr.splice(index, 1);
                        this.calculateTotalAmount();
                    }
                    if (response.sc == 0) {
                        this.appService.showError(response.error.msg);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    decreaseQuantity(index) {
        if (this.quantityArr[index] > 1)
            this.quantityArr[index] = this.quantityArr[index] - 1;
        this.calculateTotalAmount();
    }

    increaseQuantity(index) {
        this.quantityArr[index] = this.quantityArr[index] + 1;
        this.calculateTotalAmount();
    }

    calculateTotalAmount() {
        let amt = 0;
        for (let i = 0; i < this.cartList.length; i++) {
            amt = amt + this.quantityArr[i] * this.cartList[i].price;
        }
        this.total_amount = amt;
    }

    saveAddress() {
        if (!this.address || !this.address.length) {
            this.appService.showError(toastMessage.addShippingAddress)
        }
        else {
            let addressData = { shipping_address: this.address };
            this.appService.post(urls.shippingAddress, addressData)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.appService.showSuccess(response.result.message);
                        }
                        if (response.sc == 0) {
                            this.appService.showError(response.error.msg);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        }
    }

    editCart() {
        if (!this.address || !this.address.length) {
            this.appService.showError(toastMessage.addShippingAddress)
        }
        else {
            let variants = [];
            for (let i = 0; i < this.cartList.length; i++) {
                let obj = { _id: this.cartList[i]._id, quantity: this.quantityArr[i] };
                variants.push(obj);
            }
            let formData = { products: variants };
            this.appService.post(urls.editCart, formData)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.router.navigate(['/program/card-list']);
                        }
                        if (response.sc == 0) {
                            this.appService.showError(response.error.msg);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        }
    }
}
