import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { Router } from '@angular/router';
import { ChildServiceService } from 'src/app/core/services/child/child-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-success-popup',
  templateUrl: './profile-success-popup.component.html',
  styleUrls: ['./profile-success-popup.component.scss']
})
export class ProfileSuccessPopupComponent implements OnInit {

  public newChildName = '';
  public loginRole: any;
  public canAddUserRoleType: string;
  public isNewAccount = false;
  public addUserTypeName: string;
  public addTypeUser: string;
  constructor(private popupService: PopupService,public translate: TranslateService,
    private router: Router,private childServiceService: ChildServiceService) { }

  ngOnInit() {
    this.loginRole = localStorage.getItem("loginRole");
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
    this.newChildName = sessionStorage.getItem('newChildName');
    this.isNewAccount = this.childServiceService.checkWhetherParentChildExists;
    this.addUserTypeName = localStorage.getItem("newChildName");
    this.addTypeUser = this.childServiceService.messageInfor;
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  goToAddChild() {
    this.getPopup('searchChild');
  }
  goToHome() {
    this.getPopup('');
    this.router.navigate(["/home/list"]);
  }
}
