import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls,fileSize, toastMessage } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { CountryISO } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AdChildComponent implements OnInit {
  @Input() userDetails: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  public addChildForm: FormGroup;
  public submitted = false;
  public childPhoneNo: any = '';
  public parentName = '';
  public imgUrl: any;
  public childApiResponse = true;
  public disableSinceDay: number = new Date().getDate() + 1;
  public disableSinceModelDay: number = new Date().getDate();
  public disableSinceMonth: number = new Date().getMonth() + 1;
  public disableSinceYear: number = new Date().getFullYear() - 13;
  public memberStatus: any;
  public message: any;
  public userRegId: any;
  public canAddUserRoleType: string;
  public buttonText: string;
  public genderDisplay: any;
  public imageURL: any = '';
  public getDOB: any;
  public openGetDOB: any;
  CountryISO = CountryISO;
  public months: any = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  public myOptions: INgxMyDpOptions = {
    dateFormat: 'mmm dd, yyyy',
    minYear: 1900,
    maxYear: this.disableSinceYear,
    disableSince: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceDay }
  };
  // Initialized to specific date (09.10.2018)
  public model: any = { date: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceModelDay } };
  inputObj: any;
  public country_code:any = '1';
  // @Input('ng2TelInputOptions') ng2TelInputOptions: any;
  // @Output('ng2TelOutput') ng2TelOutput: EventEmitter<any> = new EventEmitter();
  // @Output('intlTelInputObject') intlTelInputObject: EventEmitter<any> = new EventEmitter(); 
  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private popupService: PopupService,
    private childServiceService: ChildServiceService) { }

  ngOnInit() {
    this.genderDisplay = 0;
    this.canAddUserRoleType = localStorage.getItem('canAddUserRoleType');
    if(this.canAddUserRoleType == 'Parent'){
      this.buttonText = 'Yes, Add as ' + this.canAddUserRoleType +'/Guardian';
    }
    else{
      this.buttonText = 'Yes, Add as ' + this.canAddUserRoleType;
    }
    if (localStorage.getItem('child_no')) {
      this.childPhoneNo = localStorage.getItem('child_no');
      if(localStorage.getItem("childCountryCode") && localStorage.getItem("child_no")){
        let number = '+' + localStorage.getItem("childCountryCode").concat(localStorage.getItem("child_no"));
        if(this.inputObj){
            this.inputObj.setNumber(number);
         }
      }
      this.memberStatus = localStorage.getItem('member_status');
      this.parentName = localStorage.getItem('first_name');
      this.userRegId = localStorage.getItem('user_reg_id');
    }
    this.message = this.childServiceService.errorMessInfo;
    this.formInitialization();
  }
  telInputObject(obj) {
    this.inputObj = obj;
    if(localStorage.getItem("childCountryCode") && localStorage.getItem("child_no")){
      let number = '+' + localStorage.getItem("childCountryCode").concat(localStorage.getItem("child_no"));
      if(this.inputObj){
          this.inputObj.setNumber(number);
       }
  }
    }
  ngOnChanges() {
    this.formInitialization();
  }

  formInitialization() {
    if (this.userDetails) {
      this.genderDisplay = [(this.userDetails) ? this.userDetails.gender : 0];
      this.imageURL = [(this.userDetails) ? this.userDetails.profile_pic : null];
      this.openGetDOB = [(this.userDetails) ? this.userDetails.dob : null];
    }
    if (this.openGetDOB) {
      const date = new Date(this.userDetails.dob);
      const month = date.getMonth();
      this.addChildForm = this.formBuilder.group({
        first_name: [(this.userDetails) ? this.userDetails.first_name : '', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        last_name: [(this.userDetails) ? this.userDetails.last_name : '', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        email: [(this.userDetails) ? this.userDetails.email : '', [Validators.required, Validators.email]],
        phone_no: [(this.userDetails) ? this.userDetails.phone_no : this.childPhoneNo, [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(10), Validators.maxLength(15)]],
        gender: [(this.userDetails) ? this.userDetails.gender : this.genderDisplay, Validators.required],
        image: [''],
        getDOB: this.months[month] + ' ' + date.getDate() + ', ' + date.getFullYear(),
      });
    } else {
      this.addChildForm = this.formBuilder.group({
        first_name: [(this.userDetails) ? this.userDetails.first_name : '', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        last_name: [(this.userDetails) ? this.userDetails.last_name : '', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        email: [(this.userDetails) ? this.userDetails.email : '', [Validators.required, Validators.email]],
        phone_no: [(this.userDetails) ? this.userDetails.phone_no : this.childPhoneNo, [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(10), Validators.maxLength(15)]],
        gender: [(this.userDetails) ? this.userDetails.gender : this.genderDisplay, Validators.required],
        image: [''],
        dob: [{ date: { year: 2005, month: 11, day: new Date().getDate() } }, Validators.required],
      });
    }
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  get f() { return this.addChildForm.controls; }

  onDateChanged(event: IMyDateModel): void {
    this.addChildForm.patchValue({ dob: event.epoc * 1000 });
  }
  onSubmit(user) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addChildForm.invalid) {
      return;
    } else {
      const data = Object.assign({}, user);
      if (this.userDetails) {
        data.dob = this.userDetails.dob;
      } else {
        const myDate = user.dob.date.day + '-' + user.dob.date.month + '-' + user.dob.date.year;
        const splitData = myDate.split('-');
        const newDate = splitData[1] + '/' + splitData[0] + '/' + splitData[2];
        data.dob = new Date(newDate).getTime();
        data.dob = (data.dob).toString();
      }
      this.submitted = true;
      data.country_code =  localStorage.getItem("childCountryCode")
      data.url = (this.memberStatus == 4) ? urls.makeChildOrParent : urls.addChildOrParent;
      if (this.memberStatus == 4) {
        const params = {
          'user_id': this.userRegId
        };
        this.appService.post(data.url, params)
          .subscribe(
            (response: any) => {
              if (response && response.sc == 1) {
                localStorage.setItem('newChildName', data.first_name);
                this.getPopup('addSucess');

              } else {
                this.appService.showError(response.error.msg);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.appService.makeFileRequest(data)
          .subscribe(
            (response: any) => {
              const result = JSON.parse(response);
              if (result && result.sc == 1) {
                localStorage.setItem('newChildName', result.result.first_name);
                this.getPopup('addSucess');
              } else {
                this.appService.showError(result.error.msg);
                console.log('error', result);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  }

  getProfilePic() {
    const dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else if (this.userDetails) {
      st = { background: `url(${this.userDetails.profile_pic})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }

  genderType(value) {
    this.addChildForm.patchValue({ gender: value });
  }
  onFileChanged(event) {
    const imageType = event.target.files[0].type.split('/');
    if (imageType[0] == 'image') {
      if (event.target.files[0].size < fileSize && imageType[0] == 'image') {
        if (event.target.files.length) {
          const files = event.target.files;
          this.imgUrl = event.target.value;
          const that = this;
          const reader = new FileReader();
          reader.onload = function (event: any) {
            that.imgUrl = event.target.result;
          };
          reader.readAsDataURL(files[0]);
        }
        const file = event.target.files[0];
        this.addChildForm.patchValue({ image: file });
      } else {
        this.appService.showError(toastMessage.imageSize);
      }
    } else {
      this.appService.showError(toastMessage.imageCheck);
    }
  }
}
