import { Component, OnInit } from '@angular/core';
import {PopupService} from "../../../../core/services/popup.service";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-popup',
  templateUrl: './onboarding-popup.component.html',
  styleUrls: ['./onboarding-popup.component.scss']
})
export class OnboardingPopupComponent implements OnInit {

  constructor(private popupService: PopupService, private router: Router,public translate: TranslateService) { }

  ngOnInit() {
  }

  login() {
    this.getPopup('');
    this.router.navigate([""]);
  }

  signUp() {
    this.getPopup('signup');
  }

  closeAccountPopup() {
    this.getPopup('');
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

}
