import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})

export class SetPasswordComponent implements OnInit {
  public setPasswordForm: FormGroup;
  public submitted = false;
  public token: any;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();

  constructor(private formBuilder: FormBuilder,
    private appService: AppService,public translate: TranslateService,
    private toastr: ToastrService,
    private router: Router) {
    this.token = this.router.url.split("/").pop()
  }

  ngOnInit() {
    localStorage.clear();
    window.scroll(0, 0)
    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#&])[A-Za-z\d@$!%*^#&]{8,}$/)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
    this.appService.minHeightGlobally();
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.setPasswordForm.controls; }

  onSubmit(obj: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.setPasswordForm.invalid) {
      return;
    } else {
      let params = {
        "id": this.token,
        "password": obj.password,
      };
      this.appService.post(urls.setPassword, params).subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.appService.showSuccess("password set successfully.");
            this.router.navigate(["/"]);
          } else {
            this.appService.showError(response.error.msg);
          }
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }
}
