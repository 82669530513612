import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  facebookInfo:any;
  constructor() { }
  public subject : Observable<any>;

 
    sendMessage(message: any) {
      var newObservable = new Observable(observer => {
        observer.next(message);
        observer.complete();
      });
      this.subject=newObservable;    }
      
    getMessage(): Observable<any> {
      return this.subject;
    }
}
