import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ReviewsComponent } from './reviews.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component:ReviewsComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, 
    NgbModule,TranslateModule
  ],
  exports: [RouterModule,],
  declarations: [ReviewsComponent],
  providers: []
})
export class ReviewsModule { }
