import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { urls, dropUrl } from 'src/app/shared/services/constant';
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSaverService } from 'ngx-filesaver';
// import { Http, ResponseContentType } from '@angular/http';
import { environment } from './../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
declare let gapi: any;
@Component({
  selector: 'app-agency-programs',
  templateUrl: './agency-programs.component.html',
  styleUrls: ['./agency-programs.component.scss']
})
export class AgencyProgramsComponent implements OnInit {
   public id: any;
   public viewLoading: boolean = false;
   public apiParam: ApiParams = new ApiParams();
   public apiResponse: ApiResponse = new ApiResponse();
   public enrolledPrograms: any = [];
   public videos: any =[];
   public reports: any =[];
   public images: any =[];
   public improvments :any;
   public notes:any;
   public strengths:any;
   public transcripts: any =[];
   public skip = 1;
   public limit = 10;
   public hide: boolean = false;
  constructor(private activatedRoute: ActivatedRoute,private appService: AppService,private sanitizer: DomSanitizer,private router: Router,
    private _http: HttpClient,private _FileSaverService: FileSaverService,public translate: TranslateService) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id
    })
    gapi.load('auth2', function () {
      gapi.auth2.init()
    });
    window.scroll(0, 0)
  }

  ngOnInit() {
    this.getAssociatedNotes();
    this.getTranscriptList(this.skip,this.limit);
  }
  getAssociatedNotes() {
    this.viewLoading = true;
    this.apiParam.appJson = true;
    this.appService.get(urls.notes + `?owner_id=${this.id}`)
      .subscribe(
        (response: any) => {
          this.viewLoading = false;
          if(response.sc == 1){
            this.hide = false
            if (response['result'] && response['result'].agencyEnrolledPrograms && response['result'].agencyEnrolledPrograms.length) {
              let data: any = response['result'].agencyEnrolledPrograms;
              if (data && data.length > 0) {
                data.forEach((ele, ind) => {
                  this.enrolledPrograms.push(ele);
                });
              }
            }
            if (response['result'] && response['result'].sharedNotes) {
              this.improvments = response['result'].sharedNotes.improvments;
              this.notes = response['result'].sharedNotes.notes;
              this.strengths = response['result'].sharedNotes.strengths;
              if(response['result'].sharedNotes.videos !=null){
                this.videos = response['result'].sharedNotes.videos;
              }
              if(response['result'].sharedNotes.reports !=null){
                this.reports = response['result'].sharedNotes.reports;
              }
              if(response['result'].sharedNotes.images !=null){
                this.images = response['result'].sharedNotes.images;
              }
              
            }
          }
          else if(response.sc ==0){
            this.hide= true;
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }
  getTranscriptList(pageNo,pageSize){
    this.viewLoading = true;
    this.apiParam.appJson = true;
    pageNo = pageNo ? ((pageNo - 1) * 10) : pageNo;
    this.appService.get(urls.transcript + `?skip=${pageNo}&limit=${pageSize}`)
      .subscribe(
        (response: any) => {
           if (response['result'] && response['result'].list) {
            let data: any = response['result'].list;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.transcripts.push(ele);
              });
              this.viewLoading = false;
          }   
        }    
        } ) 
  }
  scroll(event){
    const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
          console.log('scroll')
          this.skip = this.skip + 1
          this.getTranscriptList(this.skip,this.limit)
        }
  }
  getSafeUrl(data) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
  saveToDrive(key) {

    let googleAuth = gapi.auth2.getAuthInstance();
    googleAuth.then(() => {
      googleAuth.signIn({scope:'profile email https://www.googleapis.com/auth/drive'}).then(googleUser => {
        let profile = googleUser.getBasicProfile();
        let myObj: any = googleUser.getAuthResponse();
        let formData = {
          token: myObj.access_token,
          share_on: 1,
          key: key
        };
        this.appService.post(urls.file_share, formData).subscribe((response: any) => {
          if (response['sc'] == 1) {
            this.appService.showSuccess('Note Saved on Google drive  Successfully');
          }
          if (response['sc'] == 0 && response['error'].msg) {
            this.appService.showError(response['error'].msg);
          }
        }
        )
      })
    })
  }
  shareOnDropbox(key){
    localStorage.setItem('key',key)
    let redirect_uri = 'https://web.spotivity.com/user/linked-agencies';        //for staging
    let client_id = "j33vklfl9tssnja";
    window.open(`https://www.dropbox.com/oauth2/authorize?client_id=${client_id}&response_type=token&redirect_uri=${redirect_uri}`, 'new', 'height=800,width=800');
  }
onDown(type: string,url:string) {
  const fileName = `save.${type}`;
  this._http.get(url, {
    responseType: 'blob'
  }).subscribe(res => {
    console.log(res)
    this._FileSaverService.save((<any>res)._body, fileName);
  });
}

downloadAgencieFile(fileId) { 
    this.viewLoading = true; 
    this._http.get(urls.downloadAgenciesFile + fileId, {
      responseType: 'text'
    }).subscribe(res => {  
      console.log(res)
      this.viewLoading = false;      
      // if (res.status != 200) {
      // this.appService.showError("Something went wrong to download the file.");
      // }      
      // window.open(res.url, "_blank");  
   });   
  }
  downTrans(transFile){
    window.open(transFile, "_blank");  
  }
  goTofiles(){
    this.router.navigate(['/user/linked-agencies'],{queryParams:{'navFor':'files'}})
  }
  goToChildProgress(id){
    this.router.navigate(['/program/child-progress'], { queryParams: {'program_id': id ,'owner_id':this.id} })
}
}
