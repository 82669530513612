import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { PopupService } from 'src/app/core/services/popup.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-insight-test',
  templateUrl: './insight-test.component.html',
  styleUrls: ['./insight-test.component.scss']
})
export class InsightTestComponent implements OnInit {
  public viewLoading : boolean = false;
  public questionArray: Array<any> = []
  public questionIndex: number = 0
  public hoverMeImg: number = null;
  public modal: any;
  public btn: any;
  public disableButton:boolean = false;
  public msgArray: Array<any> = [
    'Disagree strongly',
    'Disagree moderately',
    'Disagree',
    'Neither Agree or Disagree',
    'Agree',
    'Agree moderately',
    'Agree strongly'
  ]
  constructor(
    private appService: AppService,
    private popupService: PopupService,
    public router:Router,
    public translate: TranslateService
  ) { window.scroll(0,0) }

  ngOnInit() {
    this.getQuestions();
  }

  getQuestions() {
    this.viewLoading = true;
    this.questionArray = []
    this.appService.get(urls.getQuestions)
      .subscribe((res: any) => {
        if (res.result.list) {
          res.result.list.forEach(element => {
            let obj = {
              category: element.category,
              question: element.question,
              id: element._id,
              answer: null
            }
            this.questionArray.push(obj);
            this.viewLoading = false;
          });
        }
      })
  }

  hoverMe(val?) {
    if (val) {
      this.hoverMeImg = val
    }
    else {
      this.hoverMeImg = null
    }
  }

  assignAns(val) {
    this.questionArray[this.questionIndex].answer = val
  }

  get imgArray() {
    let a: Array<any> = [1, 2, 3, 4, 5, 6, 7]
    return a;
  }

  getImg(i) {
    if (this.questionArray[this.questionIndex].answer) {
      if (this.questionArray[this.questionIndex].answer == i) {
        return ('./assets/insight/' + (i) + 'copy.png')
      }
      else {
        return ('./assets/insight/' + (i) + '.png')
      }
    }
    else {
      return ('./assets/insight/' + (i) + '.png')
    }
  }

  doSubmit() {
    this.viewLoading = true;
    this.disableButton = true;
    let payload: Array<any> = []
    this.questionArray.forEach((element) => {
      let obj = {
        "_id": element.id,
        "category": element.category,
        "score": element.answer
      }
      payload.push(obj);
    })
    let body = {
      answers: payload
    }
    this.appService.post(urls.userInsightResult, body)
      .subscribe((res: any) => {
        if (res.sc == 1) {
          this.openModal('success')
        }
        else{
          this.appService.showError(res['error'].msg)
        }
        this.disableButton = false;
        this.viewLoading = false;
      })

  }
  openModal(pop: any) {
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
}
closemodal() {
  this.btn = document.getElementsByClassName("close")[0];
  this.modal.style.display = "none";
}
gotIt(){
  this.closemodal();
  this.getPopup('insight')
}
getPopup(poupType): void {
  this.popupService.showPopup(poupType);
}
}
