import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopupService } from 'src/app/core/services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AuthServiceService } from 'src/app/core/services/authService/auth-service.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit{
  public unverified_coin:number=null;
  public totalCoin: number = null;
  public rankContact: number = null;
  public rankSchool: number = null;
  public rankSpotivity: number = null;
  public role: number = null;
  public showLoader: boolean;

  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public timer: boolean = false;
  public otpSend: boolean = true;
  public counter: string;
  public seconds = 10;
  //  public userPhone = localStorage.getItem("userPhone");
  public otpForm: FormGroup;
  public submitted = false;
  public otpApiResponse: boolean = true;
  public timerGoingOn: boolean = false;
  public disableResend: boolean = false;
  public modal: any;
  closeResult: string;
  public btn: any;
  public redeemedPoint:any;
  @ViewChild('otpFirst', { static: false }) otpFirst: ElementRef;
  @ViewChild('otpSecond', { static: false }) otpSecond: ElementRef;
  @ViewChild('otpThird', { static: false }) otpThird: ElementRef;
  constructor(private appService: AppService,
    public modalService:NgbModal,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private popupService: PopupService,
    private toastr : ToastrService,
    private authService: AuthServiceService) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpFirst: ['', Validators.required],
      otpSecond: ['', Validators.required],
      otpThird: ['', [Validators.required]],
      otpFour: ['', [Validators.required]]
    });
    this.getProfile();
    this.getTotal();
    window.scroll(0,0);
  }
  open(content:any) {
    this.onkeypressChangeFocusOfOTPinput();
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
   }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 

  getTotal() {
    this.showLoader = true;
    this.appService.post(urls.getTotalPoint)
      .subscribe((res: any) => {
        this.showLoader = false;
        this.totalCoin = res.result.Total_Coin;
        this.rankContact = res.result.rank_by_contact;
        this.rankSchool = res.result.rank_by_school;
        this.rankSpotivity = res.result.rank_by_spotivity;
        this.unverified_coin=res.result.unverified_coin
      })
  }

  getProfile() {
    this.appService.get(urls.getProfile)
      .subscribe((res: any) => {
        this.role = res.result.user.role
      })
  }
  public onkeypressChangeFocusOfOTPinput() {
    setTimeout(() => {
      let elem: any = document.getElementsByClassName("otp-input");
      for (let i = 0; i < elem.length; i++) {
        elem[i].addEventListener('keyup', (function (e) {
          if(e.keyCode != 8){
            console.log(this.value)
            if (this.value.length == this.maxLength && elem[i + 1]) {
              elem[i + 1].focus();
            }
          }
          else{
            if (this.value.length == 0 && elem[i - 1]) {
              elem[i - 1].focus();
            }
          }
        }));
      }
    }, 500)
  }
  onSubmit() {
    console.log("fdmmd",this.otpForm.value.otpFirst,this.otpForm.value.otpThird)
    let body = {
      "forStore":true,
      "otp": this.otpForm.value.otpFirst + this.otpForm.value.otpSecond + this.otpForm.value.otpThird + this.otpForm.value.otpFour
    };
    
    this.appService.post(urls.reedemVerficationCode, body)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.otpForm.reset();
            this.submitted = false;
            this.modalService.dismissAll();
            this.redeemedPoint = response.result.point;
            this.openModal('congratulations')
            // this.appService.showSuccess('Points Redeemed Successfully');
            // this.getTotal();
          } else {
            // this.appService.showError(response.error.msg);
            this.otpApiResponse = true;
            this.otpForm.reset();
            this.modalService.dismissAll();
            if(response.error.msg == 'Insufficient points'){
              this.openModal('cannotProcess')
            }
            else if(response.error.msg == 'Invalid store verification code'){
              this.openModal('invalid')
            }
            this.submitted = false;
          }
         
        },
        (error) => {
          console.log(error,"fdfdfdfdf");
         // this.appService.showError(error.msg);
          this.modalService.dismissAll();
         
        }
      )
  }
  openModal(pop: any) {
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
}
closemodal() {
  this.btn = document.getElementsByClassName("close")[0];
  this.modal.style.display = "none";
  this.getTotal();
}
}
