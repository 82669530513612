import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqPqInstructionsComponent } from './eq-pq-instructions.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: EqPqInstructionsComponent
  }
];
@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes),TranslateModule
  ],
  exports: [RouterModule],
  declarations: [EqPqInstructionsComponent]
})
export class EqPqInstructionsModule { }
