import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
@Directive({
  selector: '[rcgTouchScroll]'
})
export class TouchScrollDirective {
  @Input() private swipeElement:ElementRef;
  @Input() public xDiffOnMove:any=0;
  @Input() public isMouseDown:boolean=false;
  @Input() public frameWidth=0;

  @Output()
  public onUpdate = new EventEmitter();
  @Output()
  public onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onPrevious: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }
  @HostListener('mouseup', ['$event'])
  onMouseUp(event: any) {
    this.isMouseDown=false;
    this.xDiffOnMove=0;
    this.doUpdate();
  }

  ngAfterViewInit(){
    this.mySwipe();
  }

  public mySwipe(){
    let that=this;
    this.swipeElement.nativeElement.addEventListener("mousedown", handleTouchStart, false);
    this.swipeElement.nativeElement.addEventListener("mousemove", handleTouchMove, false);
    this.swipeElement.nativeElement.addEventListener('mouseup', handleTouchEnd, false);
    this.swipeElement.nativeElement.addEventListener("touchstart", handleTouchStart, false);
    this.swipeElement.nativeElement.addEventListener("touchmove", handleTouchMove, false);
    this.swipeElement.nativeElement.addEventListener('touchend', handleTouchEnd, false);

    let xDown = null;
    let yDown = null;

    function handleTouchStart(evt) {
      xDown = (evt.touches)?evt.touches[0].clientX:evt.clientX;
      yDown = (evt.touches)?evt.touches[0].clientY:evt.clientY;
      that.isMouseDown=true;
      that.doUpdate();
    }

    function handleTouchMove(evt){
      let xUp =(evt.changedTouches)?evt.changedTouches[0].clientX:evt.clientX;
      that.xDiffOnMove=(that.isMouseDown)?(xDown - xUp):0;
      that.doUpdate();
    }

    function handleTouchEnd(evt) {
      that.isMouseDown=false;
      that.doUpdate();
      if ( ! xDown || ! yDown ) {
        return;
      }
      let xUp =(evt.changedTouches)?evt.changedTouches[0].clientX:evt.clientX;
      let yUp =(evt.changedTouches)?evt.changedTouches[0].clientY:evt.clientY;
      let xDiff = xDown - xUp;
      let yDiff = yDown - yUp;


      if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if(Math.abs(xDiff)>(that.frameWidth/8)){
          if (xDiff>0){
            /* left swipe */
            that.onNext.emit();

          }
          else{
            /* right swipe */
            that.onPrevious.emit();
          }
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
      that.xDiffOnMove=0;
      that.doUpdate();
    }
  }

  public doUpdate(){
    this.onUpdate.emit({isMouseDown:this.isMouseDown,xDiffOnMove:this.xDiffOnMove});
  }
}
