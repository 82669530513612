import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
providedIn: 'root'
})
export class SharedService {
schoolData :any;
private _data = new Subject<any>();
getData$ = this._data.asObservable();
sendData: any;

constructor(private http: HttpClient) {

}
setData(data: any) {
this._data.next(data);
this.sendData = data;
}

getData() {
return this.sendData;
}
}
