import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { AppService } from '../shared/services/app.service';
import { PopupService } from './services/popup.service';
import { DataService } from './services/data/data.service';
@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule
  ],
  declarations: [],
  exports: [ ],
  providers:[AppService,PopupService,DataService]
})
export class CoreModule { }
