import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { urls } from 'src/app/shared/services/constant';
import { AppService } from 'src/app/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-eq-pq-test',
  templateUrl: './eq-pq-test.component.html',
  styleUrls: ['./eq-pq-test.component.scss']
})
export class EqPqTestComponent implements OnInit {
  public type:any='';
  public questionArray: Array<any> = [];
  public pqQuestionArray: Array<any> = [];
  public questionIndex: number = 0;
  public pqQuestionIndex: number = 0
  public msgArray: Array<any> = [];
  public pqMsgArray: Array<any> = [];
  public hoverMeImg: number = null;
  public hoverImg: number = null;
  public eqScore = [];
  public pqScore = [];
  public score = [];
  public scoreEq = [];
  public modal: any;
  public btn: any;
  public viewLoading : boolean = false;
  public EQMaxScore: any;
  public PQMaxScore: any;
  public PQpercent: any;
  public pitTaken:any;
  public EQResult:any;
  public PQResult:any;
  public eqQuestionArray: Array<any> = [];
  public pqAnswerArray: Array<any> = [];
  constructor(private activatedRoute: ActivatedRoute,private router:Router,
    private appService: AppService,public translate: TranslateService) { 
    this.activatedRoute.queryParams.subscribe(params =>{
    this.type = params['test']
})}

  ngOnInit() {
    window.scroll(0,0);
    if(this.type == 'eq-test'){
      this.eqQuestionList();
    }
    else{
      this.pqQuestionList();
    }
  }
  eqQuestionList(){
    this.questionArray = [];
    this.viewLoading = true;
    this.appService.get(urls.getEqQuestions).subscribe((res:any)=>{
      if (res.result.list) {
        res.result.list.forEach(element => {
          let obj = {
            options: element.options,
            question: element.question,
            id: element._id,
          }
          this.questionArray.push(obj);
        });
        this.msgArray = Object.keys(this.questionArray[0].options);
        this.viewLoading = false;
      }
    })
  }
  get imgArray() {
    let a: Array<any> = Object.values(this.questionArray[this.questionIndex].options)
    return a;
  }
  get imageArray(){
    let a: Array<any> = Object.values(this.pqQuestionArray[this.questionIndex].options)
    return a;
  }
  assignAns(val,j,index) {
    this.eqScore[this.questionIndex] = index;
    this.scoreEq[this.questionIndex] = this.questionArray[j].options[val];
    this.eqQuestionArray.push({
      question : this.questionArray[j].question,
      answer : val
    })
  }
  assign(val,j,index){
    this.pqScore[this.pqQuestionIndex] = index;
    this.score[this.pqQuestionIndex] = this.pqQuestionArray[j].options[val];
    this.pqAnswerArray.push({
      question : this.pqQuestionArray[j].question,
      answer : val
    })
  }
  getImg(i) {
    if (this.eqScore[this.questionIndex]) {
      if (this.eqScore[this.questionIndex] == i) {
        return ('./assets/eq-pq/' + (i) + 'copy.png')
      }
      else {
        return ('./assets/eq-pq/' + (i) + '.png')
      }
    }
    else {
      return ('./assets/eq-pq/' + (i) + '.png')
    }
  }
  getImage(i) {
    if (this.pqScore[this.pqQuestionIndex]) {
      if (this.pqScore[this.pqQuestionIndex] == i) {
        return ('./assets/eq-pq/' + (i) + 'copy.png')
      }
      else {
        return ('./assets/eq-pq/' + (i) + '.png')
      }
    }
    else {
      return ('./assets/eq-pq/' + (i) + '.png')
    }
  }
  hover(val?) {
    if (val) {
      this.hoverImg = val
    }
    else {
      this.hoverImg = null
    }
  }
  hoverMe(val?) {
    if (val) {
      this.hoverMeImg = val
    }
    else {
      this.hoverMeImg = null
    }
  }
  doSubmit(type){
    if(type == 'eq-test'){
      var sum = this.scoreEq.reduce(function(a, b){
        return a + b;
    }, 0);
    let data ={
      EQScore : sum,
      result : this.eqQuestionArray
    }
    this.saveScore(data)
    }
    else{
      var sum = this.score.reduce(function(a, b){
        return a + b;
    }, 0);
    let data ={
      PQScore : sum,
      result : this.pqAnswerArray
    }
    this.saveScore(data)
    }
  }
  saveScore(formData){
    this.appService.post(urls.saveScore, formData)
    .subscribe((res: any) => {
      if (res.sc == 1) {
        this.openModal('success');
        this.getEQPQResult();
      }
    })
  }
  openModal(pop: any) {
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
}
closemodal() {
  this.btn = document.getElementsByClassName("close")[0];
  this.modal.style.display = "none";
  this.router.navigate(['../personal-insight'])
}
pqQuestionList(){
  this.pqQuestionArray = [];
  this.viewLoading = true;
  this.appService.get(urls.getPqQuestions).subscribe((res:any)=>{
    if (res.result.list) {
      res.result.list.forEach(element => {
        let obj = {
          options: element.options,
          question: element.question,
          id: element._id,
        }
        this.pqQuestionArray.push(obj);
      });
      this.pqMsgArray = Object.keys(this.pqQuestionArray[0].options);
      this.viewLoading = false;
    }
  })
}
getEQPQResult(){
  // this.viewLoading = true;
  this.appService.get(urls.getResult).subscribe((res:any)=>{
    if(res['result']){
      // this.viewLoading = false;
        this.pitTaken = res['result'].PITTaken
       if(res['result'].hasOwnProperty('EQResult')){
           this.EQResult = res['result'].EQResult;
           this.EQMaxScore = res['result'].EQ_MAX_SCORE
       }
       if(res['result'].hasOwnProperty('PQResult')){
        this.PQResult = res['result'].PQResult;
        this.PQMaxScore = res['result'].PQ_MAX_SCORE;
        this.PQpercent = ((this.PQResult/this.PQMaxScore)*100).toFixed(2);
       }
    }
  })
}
}
