import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls, fileSize ,toastMessage} from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-parent',
  templateUrl: './add-parent.component.html',
  styleUrls: ['./add-parent.component.scss']
})
export class AddParentComponent implements OnInit {
  @Input() userDetails: any;
  @Output() isAlreadyAdd: EventEmitter<any> = new EventEmitter<any>();
  public addParentForm: FormGroup;
  public submitted = false;
  public childPhoneNo: any = '';
  public imgUrl: any;
  public parentName: string = '';
  public disableSinceDay: number = new Date().getDate() + 1;
  public disableSinceModelDay: number = new Date().getDate();
  public disableSinceMonth: number = new Date().getMonth() + 1;
  public disableSinceYear: number = new Date().getFullYear() - 13;
  public message: any;
  public buttonText: string;
  public memberStatus: number;
  public canAddUserRoleType: string;
  public genderDisplay: any = 0;
  public getgenderDisplay: any;
  public messageBreak: any;
  public imageURL: any = '';
  public getDOB: any;
  public openGetDOB: any;
  public months: any = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  public checkLoginRole: string;

  public myOptions: INgxMyDpOptions = {
    dateFormat: 'mmm dd, yyyy',
    minYear: 1900,
    maxYear: this.disableSinceYear,
    disableSince: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceDay }
  };
  // Initialized to specific date (09.10.2018)
  public model: any = { date: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceModelDay } };
  CountryISO = CountryISO;
  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,public translate: TranslateService,
    private popupService: PopupService,
    private childServiceService: ChildServiceService) {window.scroll(0,0) }

  ngOnInit() {
    if (this.childServiceService.childInfo != undefined) {
      this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
      this.userDetails = this.childServiceService.childInfo.user;
      //Member status 4 means record found in database but is not linked with any other account as a child or parent.
      this.memberStatus = this.childServiceService.childInfo.member_status;
      if(this.canAddUserRoleType == 'Parent'){
        this.buttonText = (this.memberStatus == 4) ? "Yes, Add as " + this.canAddUserRoleType + '/Guardian ' : "Create An Account";
      }
      else{
        this.buttonText = (this.memberStatus == 4) ? "Yes, Add as " + this.canAddUserRoleType : "Create An Account";
      }
      this.message = this.childServiceService.errorMessInfo;
      this.messageBreak = this.message.split('.');
      if (localStorage.getItem("child_no")) {
        this.childPhoneNo = localStorage.getItem("child_no");
        this.parentName = localStorage.getItem("first_name")
      }
      this.checkLoginRole = localStorage.getItem("loginRole");
      this.formInitialization();
      this.setRoleValidators();
    } else {
      this.router.navigate(["/parent/search-parent"]);
    }
  }
  formInitialization() {
    if (this.userDetails) {
      this.genderDisplay = [(this.userDetails) ? this.userDetails.gender : 0];
      this.imgUrl = [(this.userDetails) ? this.userDetails.profile_pic : null];
      this.openGetDOB = [(this.userDetails) ? this.userDetails.dob : null];

    }
    if (this.openGetDOB) {
      let date = new Date(this.userDetails.dob);
      let month = date.getMonth();
      this.addParentForm = this.formBuilder.group({
        first_name: [(this.userDetails) ? this.userDetails.first_name : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
        last_name: [(this.userDetails) ? this.userDetails.last_name : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
        email: [(this.userDetails) ? this.userDetails.email : '', [Validators.required, Validators.email]],
        phone_no: [(this.userDetails) ? this.userDetails.phone_no : this.childPhoneNo, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
        gender: [(this.userDetails) ? this.userDetails.gender : this.genderDisplay, Validators.required],
        image: [''],
        getDOB: this.months[month] + ' ' + date.getDate() + ', ' + date.getFullYear(),
      })
    }
    else {
      this.addParentForm = this.formBuilder.group({
        first_name: [(this.userDetails) ? this.userDetails.first_name : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
        last_name: [(this.userDetails) ? this.userDetails.last_name : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
        email: [(this.userDetails) ? this.userDetails.email : '', [Validators.required, Validators.email]],
        phone_no: [(this.userDetails) ? this.userDetails.phone_no : this.childPhoneNo, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
        gender: [(this.userDetails) ? this.userDetails.gender : this.genderDisplay, Validators.required],
        image: [''],
        dob: ['', [Validators.required]],
      });
    }
  }
  setRoleValidators(){
    const dobControl= this.addParentForm.get('dob');
    if(dobControl != null){
      if (this.checkLoginRole === '2') {
        dobControl.setValidators(null);
      }
      if (this.checkLoginRole === '1') {
        dobControl.setValidators([Validators.required]);
      }
      dobControl.updateValueAndValidity();
    }
  }
  get f() { return this.addParentForm.controls; }

  onSubmit(user) {
    this.submitted = true;
    if (this.addParentForm.invalid) {
      return;
    } else {
      if (this.memberStatus == 1) {
        let data = Object.assign({}, user);
        if(this.checkLoginRole === '2'){
          data.dob = "";
        }
        else{
          let myDate = user.dob.date.day + '-' + user.dob.date.month + '-' + user.dob.date.year
          let splitData = myDate.split("-");
          let newDate = splitData[1] + "/" + splitData[0] + "/" + splitData[2];
          data.dob = new Date(newDate).getTime();
          data.dob = (data.dob).toString()
        }
        let number = user.phone_no.number.replace(user.phone_no.dialCode,'')
        data.phone_no = number
        data.country_code =  user.phone_no.dialCode
        data.url = urls.addChildOrParent
        this.appService.makeFileRequest(data).subscribe((response: any) => {
          let result = JSON.parse(response);
          if (result && result.sc == 1) {
            localStorage.setItem("newChildName", result.result.first_name);
            this.getPopup('addSucess');
          } else {
            this.appService.showError(result.error.msg);
          }
        }, (error) => { console.log(error); })

      } else if (this.memberStatus == 4) {
        let params = {
          "user_id": this.userDetails._id
        };
        this.appService.post(urls.makeChildOrParent, params).subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              localStorage.setItem("newChildName", response.result.first_name);
              this.getPopup('addSucess');
            } else {
              this.appService.showError(response.error.msg);
            }
          }, (error) => { console.log(error); })
      }
    }
  }

  // optional date changed callback
  onDateChanged(event: IMyDateModel): void {
    this.addParentForm.patchValue({ dob: event.epoc * 1000 });
  }
  setDate(){
    if(this.addParentForm.value['dob'] == ''){
        this.addParentForm.controls['dob'].patchValue({ date: { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() } })
    }
    else{
        this.addParentForm.controls['dob'].patchValue(this.addParentForm.value['dob'])
    }
    
}
  getProfilePic() {
    let dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      if (this.imgUrl.length) {
        st = { background: `url(${(this.imgUrl[0].length > 0 ? this.imgUrl : dummyImg)})` };
      } else {
        st = { background: `url(${dummyImg})` };
      }
    }
    else if ((this.userDetails)) {
      if (this.userDetails.profile_pic.length) {
        st = { background: `url(${this.userDetails.profile_pic})` };
      }
      else {
        st = { background: `url(${dummyImg})` };
      }
    }
    else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }

  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  genderType(value) {
    this.addParentForm.patchValue({ gender: value });
  }

  onFileChanged(event) {
    let imageType = event.target.files[0].type.split("/")
    if (imageType[0] == "image") {
      if (event.target.files[0].size < fileSize && imageType[0] == "image") {
        if (event.target.files.length) {
          let files = event.target.files;
          this.imgUrl = event.target.value;
          var that = this;
          let reader = new FileReader();
          reader.onload = function (event: any) {
            that.imgUrl = event.target.result;
          };
          reader.readAsDataURL(files[0]);
        }
        const file = event.target.files[0]
        this.addParentForm.patchValue({ image: file });
      } else {
        this.appService.showError(toastMessage.imageSize);
      }
    } else {
      this.appService.showError(toastMessage.imageCheck);
    }
  }

  redirect() {
    this.router.navigate(["/user/profile"]);
  }
}
