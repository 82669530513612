import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { urls } from 'src/app/shared/services/constant';
import { AppService } from 'src/app/shared/services/app.service';
@Component({
  selector: 'app-eq-pq-instructions',
  templateUrl: './eq-pq-instructions.component.html',
  styleUrls: ['./eq-pq-instructions.component.scss']
})
export class EqPqInstructionsComponent implements OnInit {
  public type:any='';
  public testType:any='';
  public viewLoading : boolean = false;
  public eqLength:any;
  public pqLength:any;
  constructor(private activatedRoute: ActivatedRoute,private router:Router,private appService: AppService,public translate: TranslateService) {
    this.activatedRoute.queryParams.subscribe(params =>{
         this.type = params['test']
    })
   }

  ngOnInit() {
    window.scroll(0,0);
    if(this.type == 'eq-test'){
      this.eqQuestionList();
    }
    else{
      this.pqQuestionList();
    }
  }
  startTest(type){
    this.testType = type;
    this.router.navigate(['/tests'],{queryParams:{'test':this.testType}})
  }
  eqQuestionList(){
    this.viewLoading = true;
    this.appService.get(urls.getEqQuestions).subscribe((res:any)=>{
      if (res.result.list) {
        this.eqLength = res.result.list.length
        this.viewLoading = false;
      }
    })
  }
  pqQuestionList(){

    this.viewLoading = true;
    this.appService.get(urls.getPqQuestions).subscribe((res:any)=>{
      if (res.result.list) {
        this.pqLength = res.result.list.length
        this.viewLoading = false;
      }
    })
  }
}
