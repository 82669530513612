import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  public currentURL = "";
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public description: any;
  public title: string;
  constructor(private appService: AppService, private titles: Title,
     private meta: Meta,public translate:TranslateService,
    private router: Router) {
      window.scroll(0,0)
    this.currentURL = this.router.url.split("/").pop()
  }

  ngOnInit() {
    this.getPageInfo();
    this.appService.minHeightGlobally();
  }

  getPageInfo() {
    this.apiParam.url = urls.getStaticPage + this.currentURL;
    if (this.currentURL == 'privacy-policy') {
      this.titles.setTitle('Privacy Policy | Spotivity');
      this.meta.updateTag({ name: 'description', content: 'Spotivity, an affiliate of Graphium Inc., is committed to protecting your privacy. Please read the privacy policy to understand our practices regarding your personal data.' });
    }
    else if (this.currentURL == 'terms-and-conditions') {
      this.titles.setTitle('Terms and Conditions | Spotivity');
      this.meta.updateTag({ name: 'description', content: 'Please read our terms & conditions which govern your access to, and use of, the Spotivity website and the mobile application operated as an affiliate of Graphium, Inc.' });
    }
    else if (this.currentURL == 'about-us') {
      this.titles.setTitle('Teen Adventure Summer Camps & Programs Chicago | About Us | Spotivity');
      this.meta.updateTag({ name: 'description', content: 'Spotivity is a dual education & network platform that helps both teens & their parents to find summer programs while also supporting after-school program providers.' });
      this.meta.updateTag({ name: 'keywords', content: 'Summer Camps for Teens, Summer Programs for Teens, Summer Sports Camps and Programs For Teens, Teen Adventure Summer Camps, Teens Summer Camp Chicago, Teen Sports Camps Chicago' });
    }
    this.appService.get(this.apiParam.url)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.description = response.result.message.staticPage.page_content;
            this.title = (response.result.message.staticPage.title) ? response.result.message.staticPage.title : "Terms of Use";
          }
        },
        (error) => {
          console.log(error);
        }

      )
  }

  ngOnDestroy() {
    this.titles.setTitle('After School Programs for Teens Chicago � Art, Education, Jobs, Sports');
    this.meta.updateTag({ name: 'description', content: 'Spotivity empowers Chicago teens & parents with meaningful after school activities like sports, mentoring, tutoring, jobs, internships, volunteer & summer camps.' });
    this.meta.updateTag({ name: 'keywords', content: 'After School Activities for Teenagers, After School Activities for Teens, After School Programs Chicago, After School Sports Program, Education Programs for Teens Chicago' });
  }

}