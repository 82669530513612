import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { ChildRoutingModule } from './child-routing.module';
import { AdChildComponent } from './component/add-child/add-child.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SearchChildComponent } from './component/search-child/search-child.component';
import { SuccessPopupComponent } from './component/success-popup/success-popup.component';
import { ErrorPopupComponent } from './component/error-popup/error-popup.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment.prod';
@NgModule({
  imports: [
    CommonModule,
    ChildRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMyDatePickerModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule,
    NgxIntlTelInputModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [AdChildComponent, SearchChildComponent, SuccessPopupComponent, ErrorPopupComponent],
  exports: [AdChildComponent, SearchChildComponent, SuccessPopupComponent,ErrorPopupComponent ]
})
export class ChildModule { }
