import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { NotificationRequestComponent } from './component/notification-request/notification-request.component';
const routes: Routes = [{
  path: '',
  component: NotificationListComponent
},
{
  path: 'notification-request',
  component: NotificationRequestComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
