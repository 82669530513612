import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from "../../../../core/services/popup.service";
import * as alertify from 'alertifyjs';
import { of as observableOf } from 'rxjs';
import { map, filter, throttleTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})

export class FavoriteComponent implements OnInit {
  public programTime: any = 0;
  public limit: any
  public programArray: any = [];
  public searchArray: any = [];
  public season: any;
  public springSeason: string = '';
  public winterSeason: string = '';
  public fallSeason: string = '';
  public summerSeason: string = '';
  public lastElementData: any
  public resultsScrollObservable: any
  public scrollSubscription: any
  public showNoResultFound: boolean = false;
  public isEditButton: boolean = true;
  public showDeletePopup: boolean = false;
  public bookmarkId: string = '';
  public isUserLoggedIn: boolean = true;
  public viewLoading: boolean = false;
  public type: any = '';
  public navigationFrom: any;
  public showNextData = true;
  public skip = 1;
  public videolimit = 10;
  public videosList: any = [];
  public newslimit = 10;
  public newsList: any = [];
  public genieList: any = [];
  public activityList: any = [];
  public schoolList: any = [];
  public careerList: any = [];
  public keywordList: any = [];
  constructor(private appService: AppService, public translate: TranslateService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private popupService: PopupService) {
    window.scroll(0, 0)
    this.activatedRoute.queryParams.subscribe(params => {
      this.type = params.type
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (this.type == 'programs') {
      this.getFavoritePrograms()
    }
    observableOf(null).subscribe(() => {
      if (this.type == 'videos') {
        this.getBookmarkedVideoList(this.skip, this.videolimit);
      }
      if (this.type == 'news') {
        this.getBookmarkedNews(this.skip, this.newslimit);
      }
      if (this.type == 'genie') {
        this.getBookmarkedGenie(this.skip, this.newslimit);
      }
      if (this.type == 'keywords') {
        this.getBookmarkedkeywords(this.skip, this.newslimit);
      }
      if (this.type == 'activity') {
        this.getBookmarkedActivity();
      }
      if (this.type == 'school') {
        this.getBookmarkedSchool();
      }
      if (this.type == 'career') {
        this.getBookmarkedCareer();
      }
    });

    this.resultsScrollObservable = fromEvent(window, 'scroll');
    this.scrollSubscription = this.resultsScrollObservable
      .pipe(
        throttleTime(250),
        map((event: any) => {
          if (event) {
            const elem = event.srcElement ? event.srcElement : event.target;
            const srcElement = elem.body;
            const clientHeight = srcElement ? srcElement.clientHeight : 0;
            const el = document.scrollingElement || document.documentElement;
            const scrollTop = el.scrollTop;
            const position = scrollTop + clientHeight;
            const height = srcElement ? srcElement.scrollHeight : 0;
            const positionPercent = +((position / height).toFixed(2));
            return positionPercent > .85;
          }
        }),
        filter((doSearch: boolean) => doSearch)
      )
      .subscribe((doSearch: boolean) => {
        if (this.showNextData) {
          if (this.type == 'videos') {
            this.skip = this.skip + 1;
            this.getBookmarkedVideoList(this.skip, this.videolimit);
          }
          if (this.type == 'news') {
            this.skip = this.skip + 1;
            this.getBookmarkedNews(this.skip, this.newslimit);
          }
          if (this.type == 'genie') {
            this.skip = this.skip + 1;
            this.getBookmarkedGenie(this.skip, this.newslimit);
          }
          if (this.type == 'keywords') {
            this.skip = this.skip + 1;
            this.getBookmarkedkeywords(this.skip, this.newslimit);
          }
          if (this.type == 'activity') {
            this.getBookmarkedActivity();
          }
          if (this.type == 'school') {
            this.getBookmarkedSchool();
          }
          if (this.type == 'career') {
            this.getBookmarkedCareer();
          }
        }
      });
    this.appService.minHeightGlobally();
    this.isLoggedIn();
  }
  getBookmarkedVideoList(skip, limit) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.bookmarkVideoList + `?skip=${skip}&limit=${limit}`).subscribe((response) => {
      this.viewLoading = false;
      this.showNextData = false;
      if (response['result'] && response['result'].list && response['result'].list.length) {
        let data: any = response['result'].list;
        if (data && data.length > 0) {
          data.forEach((ele, ind) => {
            this.videosList.push(ele);
          });
        }
        this.showNextData = true
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }
  getBookmarkedGenie(skip, limit) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.genieBook + `?skip=${skip}&limit=${limit}`).subscribe((response) => {
      this.showNextData = false;
      if (response['result'] && response['result'].data && response['result'].data.length) {
        let data: any = response['result'].data;
        if (data && data.length > 0) {
          data.forEach((ele, ind) => {
            this.genieList.push(ele);
          });
        }
        this.viewLoading = false;
        this.showNextData = true;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }
  getBookmarkedActivity() {
    this.viewLoading = true;
    this.appService.getActivityBookmarksList(urls.GetAllActivityBookmarks).subscribe((response) => {
      console.log(response);
      if (response['result'].length > 0) {
        this.activityList = response['result'];
      }
      (error) => {
        console.log(error);
      }
      this.viewLoading = false;
      this.showNextData = true;
    });
  }
  removeActivityBookmark(name) {
    this.viewLoading = true;
    let body =
    {
      activityName: name
    }
    this.appService.activityRemoveBookmarks(urls.activityRemoveBookmark, body).subscribe((res: any) => {
      if (res.sc == 1) {
        this.appService.showSuccess(res['result'].message);
        this.activityList = [];
        this.getBookmarkedActivity();
      }
      else {
        this.appService.showError(res['error'].err.msg)
      }
      this.viewLoading = false;
    })
  }
  getBookmarkedSchool() {
    this.viewLoading = true;
    this.appService.getSchoolBookmarksList(urls.GetAllSchoolBookmarks + `?skip=0&limit=400`).subscribe((response) => {
      console.log(response);
      if (response['result'].data.length > 0) {
        this.schoolList = response['result'].data;
      }
      (error) => {
        console.log(error);
      }
      this.viewLoading = false;
      this.showNextData = true;
    });
  }
  removeSchoolBookmark(id) {
    this.viewLoading = true;
    let Institute_id = id.toString();
    this.appService.InstituteRemoveBookmarks(urls.RemoveInstitutionBookmarks + Institute_id, '').subscribe((res: any) => {
      if (res.sc == 1) {
        this.appService.showSuccess(res['result'].message);
        this.schoolList = [];
        this.getBookmarkedSchool();
      }
      else {
        this.appService.showError(res['error'].err.msg)
      }
      this.viewLoading = false;
    })
  }
  getBookmarkedCareer() {
    this.viewLoading = true;
    this.appService.getCareerBookmarksList(urls.GetAllCareerBookmarks).subscribe((response) => {
      console.log(response);
      if (response['result'].length > 0) {
        this.careerList = response['result'];
      }
      (error) => {
        console.log(error);
      }
      this.viewLoading = false;
      this.showNextData = true;
    });
  }
  removeCareerBookmark(id) {
    this.viewLoading = true;
    let body =
    {
      careerId: id
    }
    this.appService.careerRemoveBookmarks(urls.careerRemoveBookmark, body).subscribe((res: any) => {
      if (res.sc == 1) {
        this.appService.showSuccess(res['result'].message);
        this.careerList = [];
        this.getBookmarkedCareer();
      }
      else {
        this.appService.showError(res['error'].err.msg)
      }
      this.viewLoading = false;
    })
  }
  getBookmarkedkeywords(skip, limit) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.getBuckBook + `?skip=${skip}&limit=${limit}`).subscribe((response) => {
      this.showNextData = false;
      if (response['result'] && response['result'].category && response['result'].category.length) {
        let data: any = response['result'].category;
        if (data && data.length > 0) {
          data.forEach((ele, ind) => {
            this.keywordList.push(ele);
          });
        }
        this.showNextData = true;
      }
      this.viewLoading = false;
    },
      (error) => {
        console.log(error);
      }
    );
  }
  removebookmark(id) {
    this.viewLoading = true;
    this.appService.delete(urls.addBook + id, '').subscribe((res: any) => {
      if (res.sc == 1) {
        this.appService.showSuccess(res['result'].message);
        this.genieList = [];
        this.getBookmarkedGenie(1, 10)
      }
      else {
        this.appService.showError(res['error'].err.msg)
      }
      this.viewLoading = false;
    })
  }
  removekeybookmark(id) {
    this.viewLoading = true;
    this.appService.delete(urls.addBuckBook + id, '').subscribe((res: any) => {
      if (res.sc == 1) {
        this.appService.showSuccess(res['result'].message);
        this.keywordList = [];
        this.getBookmarkedkeywords(1, 10)
      }
      else {
        this.appService.showError(res['error'].err.msg)
      }
      this.viewLoading = false;
    })
  }
  getBookmarkedNews(skip, limit) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.getBookmarkedNewsList + `?skip=${skip}&limit=${limit}`).subscribe(
      (response: any) => {
        this.viewLoading = false;
        this.showNextData = false;
        if (response['result'] && response['result'].list && response['result'].list.length) {
          let data: any = response['result'].list;
          if (data && data.length > 0) {
            data.forEach((ele, ind) => {
              this.newsList.push(ele);
            });
          }
          this.showNextData = true
        }
      },
      (error) => {
        console.log(error);
      }
    );

  }
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

  getFavoritePrograms() {
    this.limit = 30;
    this.viewLoading = true;
    this.appService.get(urls.favoriteProgram + this.programTime + '/' + this.limit)
      .subscribe(
        (response: any) => {
          if (response.sc == 1 && (response.result.program.length) > 0) {
            let ProgramData = response.result.program;
            ProgramData.forEach((ele, ind) => {
              if (ele.spring_season == 1) {
                this.springSeason = "Spring, "
              }
              if (ele.winter_season == 1) {
                this.winterSeason = "Winter, "
              }
              if (ele.fall_season == 1) {
                this.fallSeason = "Fall, "
              }
              if (ele.summer_season == 1) {
                this.summerSeason = "Summer,"
              }
              this.season = this.springSeason + this.winterSeason + this.fallSeason + this.summerSeason
              this.season = this.season.slice(0, -1);
              let obj = {
                "name": ele.program_name,
                "image": ele.image__url,
                "address": ele.address,
                "season": this.season,
                "agency": ele.agency_name,
                "id": ele._id,
                'program_type': ele.program_type,
                'free': ele.free,
                'buckets': ele.bucketDetails
              };
              this.programArray.push(obj);
              this.lastElementData = ProgramData[ProgramData.length - 1]
              this.programTime = this.lastElementData.created_at
            });
          } else {
            this.showNoResultFound = true;
          }
          this.viewLoading = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  programDetail(id) {
    this.navigationFrom = "home"
    this.router.navigate(['program/program-detail/' + id], { queryParams: { nav_from: this.navigationFrom } });
  }
  goToVideo(id, videoId) {
    this.router.navigate(['program/videos'], { queryParams: { nav_from: 'home', program_id: id, 'videoId': videoId } })
  }
  viewDeletePopup(id) {
    this.bookmarkId = id;
    alertify.confirm('Delete Bookmark', 'Are you sure you want to delete this bookmark?', function () { }
      , function () { });
    alertify.confirm('Delete Bookmark', 'Are you sure you want to delete this bookmark?', () => {
      this.markUnfavourite();
      alertify.success('Deleted');
    }, function () { alertify.error('Cancel') });
  }

  hideDeletePopup() {
    this.bookmarkId = '';
  }

  markUnfavourite() {
    let params = {
      "programId": this.bookmarkId,
      "isFav": false
    };
    this.appService.post(urls.programFavorite, params)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.hideDeletePopup();
            this.programTime = 0;
            this.programArray = [];
            this.getFavoritePrograms()
          }
        });
  }

  editBookmarks() {
    this.isEditButton = false;
  }

  saveBookmarks() {
    this.isEditButton = true;
  }

  goToSearchMap() {
    this.router.navigate(["/program/map-list"]);
  }


  isLoggedIn() {
    let user = localStorage.getItem('LoggedIn');
    if (user && user.length) {
      this.isUserLoggedIn = true
    }
    else {
      this.isUserLoggedIn = false
    }
  }

  showBuckets(ele) {
    let buckets = '';
    for (let i = 0; i < ele.buckets.length; i++) {
      buckets = buckets + ele.buckets[i].name + ', ';
    }
    if (buckets && buckets.length)
      buckets = buckets.slice(0, -2);
    return buckets;
  }
  removeVideobookmark(id) {
    this.viewLoading = true
    let body = {
      _id: id,
      isBookmarked: false
    }
    this.appService.post(urls.bookmarkVideo, body)
      .subscribe((response: any = {}) => {
        if (response.sc == 1) {
          this.videosList = [];
          this.appService.showSuccess(response['result'].message);
          this.getBookmarkedVideoList(1, this.videolimit);
        }
        else if (response.sc == 0) {
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
      })
  }
  removeNewsBookmark(id) {
    this.viewLoading = true
    let body = {
      _id: id,
      isBookmarked: false
    }
    this.appService.post(urls.bookmarkNews, body)
      .subscribe((response: any = {}) => {
        if (response.sc == 1) {
          this.appService.showSuccess(response['result'].message);
          this.newsList = [];
          this.skip = 1;
          this.getBookmarkedNews(this.skip, this.newslimit);
        }
        else if (response.sc == 0) {
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
      })
  }
}
