import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/core/services/popup.service';
import { urls } from '../../../../shared/services/constant';
import { AppService } from 'src/app/shared/services/app.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-insight-result',
  templateUrl: './insight-result.component.html',
  styleUrls: ['./insight-result.component.scss']
})
export class InsightResultComponent implements OnInit {

  public insightResult: Array<any> = []
  public show:any;
  constructor(
    private popupService: PopupService,
    private appService: AppService,
    private router: Router,
    public translate: TranslateService) { }

  ngOnInit() {
    this.getProfile()
  }

  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }

  getProfile() {
    this.appService.get(urls.getProfile).subscribe((res: any) => {
      this.insightResult = res.result.insight_result
      if(this.insightResult.length !=0){
        this.show = true;
      }
      else{
        this.show = false;
      }
    })

  }
  goToProfile() {
    this.getPopup('');
    this.router.navigate(['../personal-insight'])
  }

}
