import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';
@Component({
  selector: 'app-physical-record',
  templateUrl: './physical-record.component.html',
  styleUrls: ['./physical-record.component.scss']
})
export class PhysicalRecordComponent implements OnInit {
  public activityPreference: any;
  public height: any;
  public weight: any;
  public wingspan: any;
  public shoeSize: any;
  public handSpan: any;
  public hipHeight: any;
  public eyesight: any;
  public yardDash: any;
  public verticalJump: any;
  public mileRun: any;
  public fatherHeight:any;
  public motherHeight:any;
  public viewLoading: boolean = false;
  constructor(private appService: AppService,private router: Router,public translate: TranslateService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.getPhysical()
  }
  selectactivity(type) {
    if(this.activityPreference != type){
      this.activityPreference = type
    }
    else{
      this.activityPreference = "";
    }
  }
  keypress(event) {
    if ((event.charCode >= 46 && event.charCode <= 58) || event.charCode == 39) {
      return event;
    }
    else {
      return false;
    }

}
  getPhysical() {
    this.viewLoading = true;
    return this.appService.get(urls.getPhysicalCharac).subscribe((response: any) => {
      if (response['result']) {
        if (response['result'].height !="" || response['result'].height !=null || response['result'].height == 0) {
          this.height = response['result'].height;
        }
        else{
          this.height = "";
        }
        if (response['result'].weight !="" || response['result'].weight !=null || response['result'].weight == 0) {
          this.weight = response['result'].weight;
        }
        else{
          this.weight = "";
        }
        if (response['result'].wingspan !="" || response['result'].wingspan !=null || response['result'].wingspan ==0) {
          this.wingspan = response['result'].wingspan;
        }
        else{
          this.wingspan = "";
        }
        if (response['result'].shoeSize !="" || response['result'].shoeSize !=null || response['result'].shoeSize ==0) {
          this.shoeSize = response['result'].shoeSize;
        }
        else{
          this.shoeSize = "";
        }
        if (response['result'].handSpan !="" || response['result'].handSpan !=null || response['result'].handSpan ==0) {
          this.handSpan = response['result'].handSpan;
        }
        else{
          this.handSpan = "";
        }
        if (response['result'].hipHeight !="" || response['result'].hipHeight !=null || response['result'].hipHeight ==0) {
          this.hipHeight = response['result'].hipHeight;
        }
        else{
          this.hipHeight = "";
        }
        if (response['result'].eyesight !="" || response['result'].eyesight !=null || response['result'].eyesight ==0) {
          this.eyesight = response['result'].eyesight;
        }
        else{
          this.eyesight = "";
        }
        if (response['result'].yardDash !="" || response['result'].yardDash !=null || response['result'].yardDash ==0) {
          this.yardDash = response['result'].yardDash;
        }
        else{
          this.yardDash = "";
        }
        if (response['result'].verticalJump !="" || response['result'].verticalJump !=null || response['result'].verticalJump ==0) {
          this.verticalJump = response['result'].verticalJump;
        }
        else{
          this.verticalJump = "";
        }
        if (response['result'].mileRun !="" || response['result'].mileRun !=null || response['result'].mileRun ==0) {
          this.mileRun = response['result'].mileRun;
        }
        else{
          this.mileRun = "";
        }
        if (response['result'].activityPreference !="" || response['result'].activityPreference !=null || response['result'].activityPreference ==0) {
          this.activityPreference = response['result'].activityPreference;
        }
        else{
          this.activityPreference = "";
        }
        if (response['result'].FathersHeight !="" && response['result'].FathersHeight !=null) {
          this.fatherHeight = response['result'].FathersHeight;
        }
        else{
          this.fatherHeight = "";
        }
        if (response['result'].MothersHeight !="" && response['result'].MothersHeight !=null) {
          this.motherHeight = response['result'].MothersHeight;
        }
        else{
          this.motherHeight = "";
        }
      }
      this.viewLoading = false
    })
  }
  addPhysical() {
    this.viewLoading = true;
    let formData = {};
    if (this.height) {
      formData['height'] = this.height;
    }
    else{
      formData['height'] = "";
    }
    if (this.weight) {
      formData['weight'] = parseFloat(this.weight);
    }
    else{
      formData['weight'] = "";
    }
    if (this.wingspan) {
      formData['wingspan'] = this.wingspan;
    }
    else{
      formData['wingspan'] = "";
    }
    if (this.shoeSize) {
      formData['shoeSize'] = parseFloat(this.shoeSize);
    }
    else{
      formData['shoeSize'] = "";
    }
    if (this.handSpan) {
      formData['handSpan'] = parseFloat(this.handSpan);
    }
    else{
      formData['handSpan'] = "";
    }
    if (this.hipHeight) {
      formData['hipHeight'] = this.hipHeight;
    }
    else{
      formData['hipHeight'] = "";
    }
    if (this.eyesight) {
      formData['eyesight'] = this.eyesight;
    }
    else{
      formData['eyesight'] = "";
    }
    if (this.yardDash) {
      formData['yardDash'] = parseFloat(this.yardDash);
    }
    else{
      formData['yardDash'] = "";
    }
    if (this.verticalJump) {
      formData['verticalJump'] = parseFloat(this.verticalJump);
    }
    else{
      formData['verticalJump'] = "";
    }
    if (this.mileRun) {
      formData['mileRun'] = this.mileRun;
    }
    else{
      formData['mileRun'] = "";
    }
    if (this.activityPreference) {
      formData['activityPreference'] = this.activityPreference;
    }
    else{
      formData['activityPreference'] = "";
    }
    if (this.fatherHeight) {
      formData['FathersHeight'] = this.fatherHeight;
    }
    else{
      formData['FathersHeight'] = "";
    }
    if (this.motherHeight) {
      formData['MothersHeight'] = this.motherHeight;
    }
    else{
      formData['MothersHeight'] = "";
    }
    this.appService.post(urls.updatePhysical, formData).subscribe((response: any) => {
      if (response.sc == 1) {
        if (response['result']) {
          this.appService.showSuccess(response['result'].message);
          this.router.navigate(["/personal-insight"])
        }
      }
      else {
        this.appService.showError(response['error'].msg)
      }
      this.viewLoading = false;
    })
  }
}
