import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls, facebookConfig, pwaFbUrl } from '../../../../shared/services/constant';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: FormGroup;
  public submitted = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();

  constructor(private popupService: PopupService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }
  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  onSubmit(params) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    } else {
      this.appService.post(urls.forgotPassword, params)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.appService.showSuccess(response.result.user.message);
              this.getPopup('');
            } else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  } 
  gotoLogin(){
     this.getPopup('');
  }
}
