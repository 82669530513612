import {environment} from './../../../environments/environment';
declare let process: any;

export let baseUrl = environment;
export const error: any = '';
export let pwaFbUrl = 'https://www.facebook.com/dialog/oauth?client_id=' + environment.fbId + '&scope=public_profile,email&redirect_uri=' + baseUrl.siteUrl;
export let instUrl = 'https://api.instagram.com/oauth/authorize/?client_id=' + environment.instaId ;
export let dropUrl = 'https://www.dropbox.com/oauth2/authorize?client_id=' + environment.dropId + '&response_type=token';
export let linkedUrl = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=' + environment.linkId + '&state=987654321&scope=r_emailaddress,r_liteprofile,w_member_social';
export let twitUrl = 'https://api.twitter.com/oauth/authorize?oauth_token=';

export function errorMsg() {
  localStorage.clear();
}

export const facebookConfig = {
  appId: environment.fbId,
  cookie: true,
  xfbml: true,
  version: 'v5.0'
};
export const googleConfig = {
  client_id: environment.googleClientId,
  scope: 'email',
  immediate: false,
  cookie_policy: 'single_host_origin',
  prompt: 'select_account consent',
}

export const defaultLocation = {
  latitude: 41.948783,
  longitude: -87.6518842
};

export let fileSize = '5242880';


export const toastMessage = {
  basicInfoUpdate: "Basic Profile info has been updated succesfully",
  cardAdd: "Card added successfully",
  bucketUpdate: "Buckets updated successfully",
  payment: "Payment successfully",
  otpSent: "OTP has been sent succesfully",
  imageSize: "You can not upload image more than 5 MB",
  imageCheck: "You can only upload image",
  emailNotFound: "Email address not found",
  locationDenied: "You can't check in the program beacuse you have not allowed your locations.",
  descEmpty: "Please provide desciption of your issue",
  descLengthCheck: "Description should be in alphanumeric characters only",
  interestEmpty: "Interest can't be empty.",
  dobCheck: "DOB can not be null.",
  currentMonthCheck: "Please enter month greater than current month",
  addCvv: "Please enter card cvv",
  addShippingAddress: "Please add shipping address!",
  permissionDenied: "Permission Denied",
  postionUnavailable: "Position Unavailable",
  timeout: "Timeout",
  productSize: "Please choose a size!",
  productColor: "Please choose a color!",
  enableSocialShare: "Please enable Social Sharing.",
  contentCheck: "Please Upload the image or enter some text",
  SomethingWentWrong: "Something went wrong please try again..",
  invalidAddress: "Invalid Address"
};

export const urls: any = {
  userLeaderBoard: baseUrl.url + '/user/leader_board',
  userRegister: baseUrl.url + '/user/register',
  userLogin: baseUrl.url + '/user/login',
  getStaticPage: baseUrl.url + '/user/static/pages/',
  getFaq: baseUrl.url + '/admin/faq_list/0/25',
  faqSearch: baseUrl.url + '/admin/faq_search/',
  passwordReset: baseUrl.url + '/user/password/reset',
  resendOtp: baseUrl.url + '/user/resendotp',
  otpConfirmation: baseUrl.url + '/user/otpconfirmation',
  reedemVerficationCode: baseUrl.url+'/user/distributePoint',
  facebookLogin: baseUrl.url + '/user/login/fb',
  googleLogin: baseUrl.url + '/user/login/gplus',
  forgotPassword: baseUrl.url + '/user/password/forgot',
  listBucket: baseUrl.url + '/user/list_bucket',
  addBucket: baseUrl.url + '/user/select_bucket',
  addLocation: baseUrl.url + '/user/add_location',
  listSchool: baseUrl.url + '/user/school_list',
  listEthnicity: baseUrl.url + '/user/ethnicity_list',
  ethnicityType: baseUrl.url + '/user/ethnicity_type_list',
  stateList: baseUrl.url + '/user/country_state_city',
  completeProfile: baseUrl.url + '/user/complete_profile',
  updateNotification: baseUrl.url + '/user/settings/change',
  appInvite: baseUrl.url + '/user/app_invite',
  programByLat: baseUrl.url + '/program/list_by_latlng',
  programDetails: baseUrl.url + '/program/details/',
  programList: baseUrl.url + '/program/list',
  programHomeList: baseUrl.url + '/program/home',
  programFavorite: baseUrl.url + '/program/fav',
  programSearch: baseUrl.url + '/program/search_keyword',
  programCheckIn: baseUrl.url + '/program/checkIn',
  getProfile: baseUrl.url + '/user/get_profile',
  editProfile: baseUrl.url + '/user/edit_profile',
  editLocation :  baseUrl.url + '/user/edit_location',
  deleteLocation :  baseUrl.url + '/user/delete_location',
  linkedinConnect: baseUrl.url + '/social/connect_disconnect',
  instagramlogin: baseUrl.url + '/user/login/instagram',
  twitterConnect: baseUrl.url + '/social/generate_twitter_token',
  favoriteProgram: baseUrl.url + '/program/fav-list/',
  listBylatlng: baseUrl.url + '/program/list_by_latlng',
  socialConnect: baseUrl.url + '/social/connect_disconnect',
  likeProgram: baseUrl.url + '/program/like',
  sharePost: baseUrl.url + '/social/share',
  passwordChanged: baseUrl.url + '/user/password/change',
  searchUser: baseUrl.url + '/user/search_user',
  addChildOrParent: baseUrl.url + '/user/addChildOrParent',
  getPendingRequestsList: baseUrl.url + '/user/getPendingRequestsList',
  acceptRejectRequest: baseUrl.url + '/user/accept_reject_request',
  makeChildOrParent: baseUrl.url + '/user/makeChildOrParent',
  removeChildParent: baseUrl.url + '/user/remove_child_parent',
  setPassword: baseUrl.url + '/user/setPassword',
  getTotalPoint: baseUrl.url + '/user/getTotalPoint',
  getChildList: baseUrl.url + '/user/my_parent_child_list',
  distributePoint: baseUrl.url + '/user/distributePoint',
  walletHistory: baseUrl.url + '/user/walletHistory?',
  getQuestions: baseUrl.url + '/user/getQuestions',
  userInsightResult: baseUrl.url + '/user/insight_result',
  checkEmail: baseUrl.url + '/user/checkEmailOrPhone',
  productList: baseUrl.url + '/agency_store/get_products',
  productDetails: baseUrl.url + '/agency_store/product_details/',
  addToCart: baseUrl.url + '/agency_store/add_to_cart',
  cartList: baseUrl.url + '/agency_store/get_cart?',
  removeFromCart: baseUrl.url + '/agency_store/delete_cart_product',
  shippingAddress: baseUrl.url + '/agency_store/add_shipping_address',
  programReport: baseUrl.url + '/user/reportFeedback',
  cardList: baseUrl.url + '/payment/getCards',
  cartHistory: baseUrl.url + '/agency_store/getOrderHistory?',
  deleteCard: baseUrl.url + '/payment/deleteCard',
  addCard: baseUrl.url + '/payment/addCard',
  payment: baseUrl.url + '/payment/makePayment',
  editCart: baseUrl.url + '/agency_store/edit_cart',
  productListByBucket: baseUrl.url + '/agency_store/get_products_by_bucket',
  ratingList: baseUrl.url +'/program/listRatings',
  editRating: baseUrl.url +'/program/editProgramRating',
  rateProgram: baseUrl.url +'/program/rateProgram',
  linkedAgencies: baseUrl.url +'/user/getMyLinkdedAgencies',
  notes : baseUrl.url + '/user/getAssosiatedAgencyNotes',
  file_share :  baseUrl.url + '/user/file_share',
  headingList: baseUrl.url + '/forum/listHeadings',
  topicList: baseUrl.url + '/forum/listTopics',
  questionList: baseUrl.url + '/forum/listQuestions',
  bookmarkQuestion: baseUrl.url + '/forum',
  myBookmark: baseUrl.url + '/forum/myBookmarks',
  answerList: baseUrl.url + '/forum/listAnswers',
  addQuestion: baseUrl.url + '/forum',
  deleteAnswer : baseUrl.url + '/forum/answer/',
  myAnswer: baseUrl.url + '/forum/myAnswers',
  myTopic: baseUrl.url + '/forum/mytopic',
  myQuestions: baseUrl.url + '/forum/my-question',
  addAnswer: baseUrl.url + '/forum',
  enrollnow: baseUrl.url + '/user/sendEnrolmentRequest',
  getRatingQuestions:baseUrl.url + '/program/listRatingQustionnaire',
  selectedBucket: baseUrl.url + '/user/check_bucket_selected',
  downloadAgenciesFile: baseUrl.url + '/user/file/',
  getNotificationCount : baseUrl.url + '/user/getPendingRequestsCount',
  transcript : baseUrl.url + '/payment/getTranscriptList',
  transcriptPayment : baseUrl.url + '/payment/makePaymentForTranscript',
  childProgress : baseUrl.url + '/program/progressReport/',
  childPrograms : baseUrl.url + '/user/getChildEnrolledPrograms/',
  checkForTransPay : baseUrl.url + '/payment/checkTranscriptPayment',
  getEqQuestions : baseUrl.url + '/user/getEQQuestionnaireList',
  saveScore: baseUrl.url + '/user/saveEQPQResult',
  getResult : baseUrl.url + '/user/getEQPQResult',
  getPqQuestions : baseUrl.url + '/user/getPQQuestionnaireList',
  getNewsList : baseUrl.url + '/newsroom/list',
  bookmarkNews : baseUrl.url + '/newsroom/bookmarkArticle',
  videoList : baseUrl.url + '/newsroom/videoList/',
  videoDetail : baseUrl.url + '/newsroom/videoDetail/',
  bookmarkVideo : baseUrl.url + '/newsroom/bookmarkVideo',
  likeVideo : baseUrl.url + '/newsroom/likeVideo',
  unlikeVideo : baseUrl.url + '/newsroom/dislikeVideo',
  bookmarks : baseUrl.url + '/newsroom/myBookmarks',
  bookmarkVideoList : baseUrl.url + '/newsroom/myBookmarkedVideos',
  getBookmarkedNewsList : baseUrl.url + '/newsroom/myBookmarkedArticle',
  updateRecord : baseUrl.url + '/newsroom/updateRecord',
  getGrade : baseUrl.url + '/newsroom/getRecord',
  reportVideo : baseUrl.url + '/newsroom/reportVideo',
  getPhysicalCharac:baseUrl.url + '/newsroom/getPhysicalCharacteristics',
  updatePhysical: baseUrl.url + '/newsroom/addPhysicalCharacteristics',
  subjectList: baseUrl.url + '/newsroom/getSubjects',
  schoolState : baseUrl.url + '/newsroom/getSchoolStates',
  stateCities : baseUrl.url + '/newsroom/getSchoolCities',
  addNewSchool : baseUrl.url + '/newsroom/addNewSchool',
  firstTime : baseUrl.url + '/user/first-time',
  listingIss : baseUrl.url + '/issue/',
  curious : baseUrl.url + '/user/curious',
  linkedPrograms: baseUrl.url + '/user/link-program',
  listKeyword: baseUrl.url + '/user/keywords',
  getProgressData:baseUrl.url + '/user/progress-bar',
  checkgrades:baseUrl.url + '/user/academic-grades',
  isGraduated:baseUrl.url + '/user/is-graduated',
  checkGraduated:baseUrl.url + '/user/check-is-graduated',
  getPolls:baseUrl.url + '/user/polls',
  getCategory:baseUrl.url + '/user/polls-category',
  getSubs:baseUrl.url + '/user/polls/sub-category/',
  addBook:baseUrl.url + '/user/bookmark/sub-category/',
  genieBook:baseUrl.url + '/user/bookmark/sub-category',
  nullCheck:baseUrl.url + '/user/check/null',
  getKey:baseUrl.url + '/user/keywords/null',
  addBuckBook:baseUrl.url + '/user/bookmark/keywords/',
  getBuckBook:baseUrl.url + '/user/bookmark/keywords',
  membershipList:baseUrl.url + '/user/membership',
  emailVerify:baseUrl.url + '/user/verifyEmail',
  sendAgain:baseUrl.url + '/user/resend-email-verify',
  pollsCategory:baseUrl.url+'/user/polls-category',
  careerTradeFilter:baseUrl.url+'/career/careerTradeFilter',
  careerAddBookmark:baseUrl.url+'/career/careerbookmark',
  careerRemoveBookmark:baseUrl.url+'/career/removebookmark',
  careerGradeFilter:baseUrl.url+'/career/careerGradeFilter',
  GetInstitution:baseUrl.url+'/university/getInstitution',
  GetInstitutionDetails:baseUrl.url+'/university/getInstitutionDetails',
  AddInstitutionBookmarks:baseUrl.url+'/university/bookmark/institution/',
  RemoveInstitutionBookmarks:baseUrl.url+'/university/bookmark/institution/',
  activityCategory:baseUrl.url+'/activity/findUserActivities',
  activityAddBookmark:baseUrl.url+'/activity/addbookmark',
  activityRemoveBookmark:baseUrl.url+'/activity/removebookmark',
  activityCategoryFilter:baseUrl.url+'/activity/activityFilter',
  GetAllActivityBookmarks:baseUrl.url+'/activity/mybookmark',
  GetAllSchoolBookmarks:baseUrl.url+'/university/bookmark/institution',
  GetAllCareerBookmarks:baseUrl.url+'/career/mybookmark',
  AlertsNotificationAPI:baseUrl.url+'/user/alerts-notification/',
  AlertsNotificationCountAPI:baseUrl.url+'/user/alerts-notification-count',
};

