import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  public modal: any;
  public btn: any;
  public activeIndex: number;
  public navigationFrom: any;
  public program_id: any;
  public viewLoading: boolean = false;
  public page_no: number = 1;
  public pageSize: number = 10;
  public ratingList: any = [];
  public show: boolean = false;
  public myRating: any;
  public picture: any;
  public imgUrl: any;
  public rating: any;
  public model: any = {};
  public programDetail: any = {};
  public questionsList: any = [];
  public selected = 0;
  public showSubmit: boolean = false;
  public value: any = [];
  public selectedRating: any = [];
  public ratingInfo = [];
  constructor(private activatedRoute: ActivatedRoute, private router: Router,public translate: TranslateService,
     private appService: AppService, private toastr: ToastrService,) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.activatedRoute.queryParams.subscribe(params => {
      this.navigationFrom = params.nav_from;
      this.program_id = params.program_id
    });
    this.getRatingList();
    this.getQuestionnaire();
    this.getProgramDetail();
  }

  openModal(pop: any) {
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
    this.selectedRating[this.selected] = this.value[this.selected]['4'];
    this.ratingInfo[this.selected] = {
      'questionId': this.questionsList[this.selected]._id,
      'rating': 4
  }
  }

  closemodal() {
    this.btn = document.getElementsByClassName("close")[0];
    this.modal.style.display = "none";
    this.selectedRating = [];
    this.ratingInfo = [];
    this.selected = 0;
    this.showSubmit = false;
  }
  goToDetails() {
    this.router.navigate(['program/program-detail/' + this.program_id], { queryParams: { nav_from: this.navigationFrom } });
  }
  getQuestionnaire() {
    this.appService.get(urls.getRatingQuestions).subscribe((data: any) => {
      if (data && data['result'] && data['result'].qustionnaireList.length > 0) {
        this.questionsList = data['result'].qustionnaireList
        this.questionsList.forEach((ele, ind) => {
          this.value.push(ele.ratings)
        })
      }
    })
  }
  change(index) {
    this.selected = index;
    if (!this.selectedRating[this.selected]) {
      this.selectedRating[this.selected] = this.value[this.selected]['4'];
      this.ratingInfo[this.selected] = {
        'questionId': this.questionsList[this.selected]._id,
        'rating': 4
    }
    }
    if (this.selected == this.questionsList.length - 1) {
      this.showSubmit = true;
    }
    else {
      this.showSubmit = false;
    }
  }
  previous() {
    if (this.selected > 0) {
      this.selected = this.selected - 1;
    }
    if (this.showSubmit) {
      this.showSubmit = false;
    }
  }
  next() {
    if (this.selected < this.questionsList.length - 1) {
      this.selected = this.selected + 1;
      if (!this.selectedRating[this.selected]) {
        this.selectedRating[this.selected] = this.value[this.selected]['4'];
        this.ratingInfo[this.selected] = {
          'questionId': this.questionsList[this.selected]._id,
          'rating': 4
      }
      }
    }
    if (this.selected == this.questionsList.length - 1) {
      this.showSubmit = true;
    }
  }
  select(value, i,rate) {
    this.selectedRating[i] = value;
    this.ratingInfo[i] = {
      'questionId': this.questionsList[i]._id,
      'rating':rate
  }
  }
  getProgramDetail() {
    this.appService.get(urls.programDetails + this.program_id)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.programDetail = response.result.program;
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  getRatingList() {
    this.viewLoading = true;
    this.appService.get(urls.ratingList + `?program_id=${this.program_id}&page_no=${this.page_no}&page_size=${this.pageSize}`)
      .subscribe(
        (data: any) => {

          if (!data['result'].hasOwnProperty('myRating')) {
            this.viewLoading = false;
            this.show = true;
          }
          else {
            this.viewLoading = false;
            this.show = false;
            this.myRating = data['result'].myRating
            this.picture = this.myRating.userDetails.profile_pic
            this.rating = this.myRating.rating
            this.model = {
              rating: this.myRating.rating,
              review: this.myRating.review
            }
          }
          if (data['result'] && data['result']) {
            this.ratingList = data['result'].ratingList
            this.viewLoading = false;
          }

        })
  }
  getProfilePic() {
    let dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else if (this.picture) {
      st = { background: `url(${this.picture})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }
  getPic(rating) {
    let dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else if (rating.userDetails.profile_pic) {
      st = { background: `url(${rating.userDetails.profile_pic})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }


  edit() {
    let formData = {
      rating_id: this.myRating._id,
      ratingInfo: this.ratingInfo
    }
    if (this.model.review) {
      formData['review'] = this.model.review
    }
    this.appService.post(urls.editRating, formData)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.appService.showSuccess(response.result.message);
            this.closemodal();
            this.getRatingList();
          }
          if (response.sc == 0) {
            this.appService.showError(response.error.msg)
          }
        });
  }
}
