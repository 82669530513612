import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-landing-header",
  templateUrl: "./landing-header.component.html",
  styleUrls: ["./landing-header.component.scss"]
})
export class LandingHeaderComponent implements OnInit {

  public isLogin: boolean = false;
  public showMenu: boolean = false;
  public name: string = "";
  public profilePic: string = "./assets/img/blank-image.png";
  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }


  clearMessage(): void { }

  public get isLoggedIn() {
    this.profilePic = localStorage.getItem("profilePic")
      ? localStorage.getItem("profilePic")
      : "./assets/img/blank-image.png";
    this.name = localStorage.getItem("fName");
    return !!localStorage.getItem("LoggedIn");
  }
  getToHome() {
    if(this.isLoggedIn){
      this.router.navigate(['/home/list']);
    }
    else{
      this.router.navigate(["/"]);
    }
  }

}
