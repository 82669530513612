import { Component, OnInit, EventEmitter, Output, NgZone } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
import { AuthServiceService } from 'src/app/core/services/authService/auth-service.service';
import { AppService } from 'src/app/shared/services/app.service';
import { urls, facebookConfig, googleConfig, instUrl, toastMessage, defaultLocation } from '../../../../shared/services/constant';
import { ApiParams } from '../../../../shared/model/apiResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var FB: any;
declare var gapi: any;

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
// 	Auth: {
// 		identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
// 		region: 'us-east-1'
// 	}
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
// 	AWSPinpoint: {
// 		appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
// 		region: 'us-east-1',
// 		mandatorySignIn: false,
// 	}
// }

// Analytics.configure(analyticsConfig)

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
	@Output() isSocialLogin: EventEmitter<any> = new EventEmitter<any>();
	public apiParam: ApiParams = new ApiParams();
	public hideMsg: boolean = false;
	public showBannerBox: boolean = false;
	public id: any;
	private isUserLoggedIn = true;
	public linkedin: boolean = false;
	public code: any;
	public state: any;
	public linkdinUrl: any;
	public inst: boolean = false;
	public twitter: boolean = false;
	public twitterURL: string;
	public oauthCallbackConfirmed: boolean;
	public oauthToken: string;
	public newURL: any;
	public denied: any;
	public signupApiResponse: boolean = true;
	public loginForm: FormGroup;
	public submitted = false;
	public loginApiResponse: boolean = true;
	public oneTime = 1;
	public verifyToken: any;
	public modal: any;
	public btn: any;
	constructor(public zone: NgZone, private formBuilder: FormBuilder, private router: Router, public translate: TranslateService,
		private activatedRoute: ActivatedRoute, private appService: AppService, private authService: AuthServiceService,
		private popupService: PopupService) {
		window.scroll(0, 0)
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {
			if (Object.keys(params).length != 0) {

				this.verifyToken = params['verifyToken'];
				this.verifyEmail()
			}
			else {
				this.isLoggedIn();
			}
		});
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]]
		});
		FB.init(facebookConfig);
		setTimeout(() => {
			this.hideMsg = true;
		}, 3000)
		observableOf(null).pipe(delay(400)).subscribe(() => {
			this.showBannerBox = false;
		})
		this.activatedRoute.queryParams.subscribe(params => {
			this.id = params['type'];
			if (this.id == 'refersh') {
				let clean_uri = location.protocol + "//" + location.host + location.pathname;
				window.history.replaceState({}, document.title, clean_uri);
			}
		});

		//For Instagram
		this.activatedRoute.queryParams.subscribe(params => {
			let token;
			let varrr = (window.location.href.split('='));
			if (varrr.length == 2) {
				token = (window.location.href.split('='))["1"];
			}
			let pathname = localStorage.getItem('path');
			if (token && pathname == 'connect') {
				this.getPopup('linkSocialAccount');
				localStorage.removeItem('path');
			}
			if (token && pathname == 'login') {
				this.getInstagramLogin(token);
				localStorage.removeItem('path');
			}
		});

		//For Twitter and LinkedIn
		this.activatedRoute.queryParams.subscribe(params => {
			this.code = params['code'];
			this.state = params['state'];
			this.newURL = params['error'];
			this.denied = params['denied'];
			if (this.newURL) {
				localStorage.setItem('linkedin', 'false');
				this.getPopup('linkSocialAccount');
			}
			if (this.denied) {
				localStorage.setItem('twitter', 'false');
				this.getPopup('linkSocialAccount');
			}
			if (this.code && this.state || params['oauth_token'] && params['oauth_verifier']) {
				this.getPopup('linkSocialAccount');
			}
		});

		// Analytics.record({ name: 'Website Visited' });
	}

	isLoggedIn() {
		const user = localStorage.getItem('LoggedIn');
		if (user && user.length) {
			this.isUserLoggedIn = true;
			this.getCurrentLocation();
		} else {
			this.isUserLoggedIn = false;
		}
	}

	getCurrentLocation() {
		if (window.navigator && window.navigator.geolocation) {
			window.navigator.geolocation.getCurrentPosition(position => {
				//   Analytics.record({ name: 'Location Access' });
				localStorage.setItem("lat", (position.coords.latitude).toString());
				localStorage.setItem("lng", (position.coords.longitude).toString());
				this.router.navigate(['/home/list'])
			}, error => {
				if (error && error.code) {
					console.log(error)
					localStorage.setItem("lat", (defaultLocation.latitude).toString());
					localStorage.setItem("lng", (defaultLocation.longitude).toString());
					this.router.navigate(['/home/list'])
				}
			},
				{
					enableHighAccuracy: true
				});
		}
	}

	getInstagramLogin(token) {
		let data = {
			'accessToken': token
		}
		this.appService.post(urls.instagramlogin, data)
			.subscribe(
				(response: any) => {
					if (response && response.sc == 1) {
						// Analytics.record({ name: 'Instagram Login Success' });
						if (response.result.user.status == 0) {
							localStorage.setItem('userPhone', response.result.user.phone_no);
							localStorage.setItem('countryCode', response.result.user.country_code)
							this.resendOtp(response.result.user.phone_no, response.result.user.country_code);
							this.getPopup('otp');
						} else if (response.result.user.status == 3) {
							this.popupService.userDetails = response.result.user;
							this.getPopup('signup');
							if (!response.result.user.hasOwnProperty('email')) {
								this.appService.showError(toastMessage.emailNotFound);
							}
						} else {
							localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "");
							this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name);
							this.getCurrentLocation();
							this.zone.run(() => this.router.navigate(["home/list"])).then();
							if (response.result.user.noti_setting) {
								localStorage.setItem("notiSetting", response.result.user.noti_setting)
							}
						}
					} else {
						console.log("error");
					}
				},
				(error) => {
					//   Analytics.record({ name: 'Instagram Login Failure' });
					console.log(error);
				}
			)
	}

	onSubmit(params) {
		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		} else {
			this.loginApiResponse = false;
			this.apiParam.params = Object.assign({}, params);
			this.appService.post(urls.userLogin, this.apiParam.params)
				.subscribe(
					(response: any) => {
						if (response && response.sc == 1) {
							//   Analytics.record({ name: 'Email Login Success' });
							localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "");
							localStorage.setItem("userRole", response.result.user.role);
							this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name)
							this.popupService.togglePopupVisibility(true);
							if (response.result.user.status == 0) {
								localStorage.setItem('userPhone', response.result.user.phone_no)
								localStorage.setItem('countryCode', response.result.user.country_code)
								this.resendOtp(response.result.user.phone_no, response.result.user.country_code);
								this.getPopup('otp');
							} else {
								this.getCurrentLocation();
								localStorage.setItem("notiSetting", response.result.user.noti_setting)

							}
							this.loginForm.reset(this.loginForm.value);

						} else {
							this.appService.showError(response.error.msg);
							this.loginApiResponse = true;
						}
					},
					(error) => {
						// Analytics.record({ name: 'Email Login Failure' });
						console.log(error);
					}
				)
		}
	}

	resendOtp(phone, code) {
		this.apiParam.url = urls.resendOtp;
		let params = {
			"phone_no": phone,
			"country_code": code
		};
		this.apiParam.appJson = true;
		this.appService.post(urls.resendOtp, params)
			.subscribe(
				(response: any) => {
					if (response && response.sc == 1) {

					}
				}
			)
	}

	getPopup(test): void {
		this.popupService.showPopup(test);
		if (test == 'signup') {
			// Analytics.record({ name: 'Sign Up' });
		}
	}

	get f() {
		return this.loginForm.controls;
	}

	facebook() {
		this.submitted = false;
		FB.login(response => {
			this.statusChangeCallback(response);
		}, {
			scope: 'public_profile,email'
		});
	}

	statusChangeCallback(response) {
		if (response.status === 'connected') {
			let accessToken = response.authResponse.accessToken;
			let params = {
				"accessToken": accessToken
			};
			this.apiParam.appJson = true;
			this.appService.post(urls.facebookLogin, params)
				.subscribe(
					(response: any) => {
						if (response && response.sc == 1) {
							//   Analytics.record({ name: 'FB Login Success' });
							if (response.result.user.status == 3) {
								this.popupService.FacebookDetails = response.result.user;
								this.getPopup('signup');
								if (!response.result.user.hasOwnProperty('email')) {
									this.appService.showError(toastMessage.emailNotFound);
								}
							} else if (response.result.user.status == 0) {
								localStorage.setItem('userPhone', response.result.user.phone_no);
								localStorage.setItem('countryCode', response.result.user.country_code)
								this.resendOtp(response.result.user.phone_no, response.result.user.country_code);
								this.getPopup('otp');
							} else {
								localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "")
								this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name);
								this.getCurrentLocation();
								// this.zone.run(() => this.router.navigate(["home/list"])).then();
							}
						} else {
							this.appService.showError(response.error.msg);
						}
					},
					(error) => {
						// Analytics.record({ name: 'FB Login Failure' });
						console.log(error);
					}
				)
		} else if (response.status === 'not_authorized') {
			console.log('not_authorized Please log into this app.');
		} else {
			console.log('Please log into Facebook.');
		}
	}

	gplus() {
		this.submitted = false;
		const gplus_signinCallback = (authResult) => {
			if (authResult['status']['signed_in']) {
				if (authResult['status']['method'] == 'PROMPT') {
					let params = {
						"accessToken": authResult.access_token
					};
					this.apiParam.appJson = true;
					this.appService.post(urls.googleLogin, params)
						.subscribe(
							(response: any) => {
								this.zone.run(() => {
									if (response && response.sc == 1) {
										// Analytics.record({ name: 'Gmail Login Success' });
										document.cookie.split(";").forEach(function (c) {
											document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
										});
										localStorage.clear();
										sessionStorage.clear();
										if (response.result.user.status == 3) {

											this.popupService.userDetails = response.result.user;
											this.getPopup('signup');
											if (!response.result.user.hasOwnProperty('email')) {
												this.appService.showError(toastMessage.emailNotFound);
											}
										} else if (response.result.user.status == 0) {
											localStorage.setItem('userPhone', response.result.user.phone_no)
											localStorage.setItem('countryCode', response.result.user.country_code)
											this.resendOtp(response.result.user.phone_no, response.result.user.country_code);
											this.getPopup('otp');
										} else {
											localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "")
											this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name)
											this.getCurrentLocation();
										}
									} else {
										this.appService.showError(response.error.msg);
									}
								})
							},
							(error) => {
								// Analytics.record({ name: 'Gmail Login Failure' });
								console.log(error);
							}
						)
				}
			}
		};
		gapi.auth.authorize(googleConfig, gplus_signinCallback);
	}

	instagram() {
		let path = 'login';
		localStorage.setItem('path', path);
		window.location.href = instUrl + '&redirect_uri=' + window.location.origin + '&response_type=token';
	}
	openModal(pop: any) {
		this.modal = document.getElementById(pop);
		this.modal.style.display = "block";
	}

	closemodal() {
		this.btn = document.getElementsByClassName("close")[0];
		this.modal.style.display = "none";
	}
	verifyEmail() {
		let data = {
			token: this.verifyToken
		}
		return this.appService.post(urls.emailVerify, data).subscribe((res: any) => {
			if (res.sc == 1) {
				this.openModal('successEmail')
			}
			else {
				this.appService.showError(res.error.msg)
			}
		})
	}
}