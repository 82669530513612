import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit {
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public notificationStatus: boolean = false;
  public subject: string;
  public toEmail: string;
  public isParent: boolean = true;
  public emailVerified:boolean = true;
  constructor(private appService: AppService,public translate: TranslateService,
    private router: Router) {window.scroll(0,0) }

  ngOnInit() {
    if (localStorage.getItem("notiSetting") != null && localStorage.getItem("notiSetting") == "true") {
      this.notificationStatus = true;
    }
    this.getProfile()
  }

  getProfile() {
    this.appService.get(urls.getProfile).subscribe((response: any) => {
      this.isParent = response.result.user.role === 1;
      if(response.result.user.hasOwnProperty('isEmailVerified')){
        this.emailVerified = response.result.user.isEmailVerified
      }
      
    })
  }

  updateNotification(status) {
    this.apiParam.appJson = true;
    let params = {
      "settingFor": "noti_setting",
      "settingValue": (status).toString()
    }
    this.appService.post(urls.updateNotification, params)
      .subscribe(
        (response: any) => {
          if (response.result.settings.noti_setting) {
            this.notificationStatus = true;
            localStorage.setItem("notiSetting", "true")
          } else {
            this.notificationStatus = false;
            localStorage.setItem("notiSetting", "false")
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  addProp(e) {

    if (e.target.checked) {
      this.updateNotification(true);
    } else {
      this.updateNotification(false);
    }
  }

  navigatePage(type) {
    this.router.navigate([type]);
  }

  public sendMail() {
    this.appInvite();
  }
  appInvite() {
    this.appService.get(urls.appInvite)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.subject = "Spotivity App"
          this.toEmail = ""
          window.location.href = "mailto:" + this.toEmail + "?subject=" + this.subject + "&body=" + response.result.appInvite.link;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  copyURLLink()
  {
    this.appService.get(urls.appInvite).subscribe((data:any)=>{
      let link = data.result.appInvite.link;
      navigator.clipboard.writeText(link).then(() => {
        this.appService.showSuccess("Copied to clipboard");
    });
    });
  }
  sendEmail(){
    this.appService.post(urls.sendAgain,'').subscribe((res:any)=>{
             if(res.sc ==1){
                 this.appService.showSuccess(res['result'].message)
             }
             else{
               this.appService.showError(res['error'].msg)
             }
    })
  }
}
