import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-insight-tool',
  templateUrl: './insight-tool.component.html',
  styleUrls: ['./insight-tool.component.scss']
})
export class InsightToolComponent implements OnInit {
  public takeTest: boolean = false;
  constructor(public translate: TranslateService) { }

  ngOnInit() {
    window.scroll(0, 0)
  }

}
