import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { ParentsComponent } from './parents.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: ParentsComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule,TranslateModule
  ],
  exports: [RouterModule],
  declarations: [ParentsComponent],
  providers: []
})
export class ParentsModule { }
