import { Component, OnInit, NgZone } from '@angular/core';
import { AppService } from "../../../../shared/services/app.service";
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { urls, toastMessage } from '../../../../shared/services/constant';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {

  public viewLoading: boolean = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public page_no: number = 1;
  public pageSize: number = 10;
  public modal: any;
  public newCard: FormGroup;
  public model: any = {};
  public ccExp: any;
  public submitnewCard: boolean = false;
  public ownername: any;
  public years: any = [];
  public selectedMonth: any;
  public selectedYear: any;
  public showMonthError: any;
  public showYearError: any;
  public expiryMonth: any = 'MM';
  public expiryYear: any = 'YYYY';
  public monthArr: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public navigationFrom:any;
  public Amount: any;
  constructor(private appService: AppService,private activatedRoute: ActivatedRoute,public zone: NgZone,
    private formBuilder: FormBuilder, private router: Router,public translate: TranslateService) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formInitialization();
    this.ownername = localStorage.getItem('owner');
    let i: number = 0;
    while (i < 40) {
      this.years.push({
        'year': new Date().getFullYear() + i
      })
      i++;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if(Object.keys(params).length != 0){
        this.navigationFrom = params.nav_from;
      }
    });
       if(this.navigationFrom != undefined){
         this.Amount = localStorage.getItem('pay')
       }
  }
  formInitialization() {
    this.newCard = this.formBuilder.group({
      Name: ['', [Validators.required]],
      cardNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(19), Validators.pattern('[0-9]+')]],
      cvv: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(4)]],
    });
  }
  get f() {
    return this.newCard.controls;
  }
  opendropdown() {
    this.modal = document.getElementById("myDropdown").classList.toggle("display");
  }
  showdropdown() {
    this.modal = document.getElementById("myDropdown1").classList.toggle("dish");
  }
  setMonth(month) {
    this.expiryMonth = month;
    this.selectedMonth = month;
    this.showMonthError = '';
    this.modal = document.getElementById("myDropdown").classList.remove("display");
  }
  setYear(year) {
    this.expiryYear = year;
    this.selectedYear = year;
    this.showYearError = '';
    this.modal = document.getElementById("myDropdown1").classList.remove("dish");
  }
  getToken() {
    this.submitnewCard = true;
    if (this.expiryMonth == 'MM') {
      this.showMonthError = "Month is required"
    }
    if (this.expiryYear == 'YYYY') {
      this.showYearError = "Year is required"
    }
    if (this.expiryYear == ((new Date()).getFullYear()) && this.expiryMonth < ((new Date()).getMonth())) {
      this.appService.showError(toastMessage.currentMonthCheck)
    }
    else if ((this.newCard.valid && this.expiryMonth != 'MM' && this.expiryYear != 'YYYY')) {
      (<any>window).Stripe.card.createToken({
        name: this.model.Name,
        number: this.model.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.model.cvv
      }, (status: number, response: any) => {

        if (status === 200) {
           this.addNewCard(response.id);
        } else {
          if (response.error.message)
            this.appService.showError(response.error.message)
          else
            this.appService.showError('Something went wrong!!!!')
        }
      });
    }
  }
  keypress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
        return event;
    }
    else {
        return false;
    }
}
  addNewCard(token) {
    let newCardData = {
      token: token
    }

    this.appService.post(urls.addCard, newCardData)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.appService.showSuccess(toastMessage.cardAdd);
            if(this.navigationFrom != undefined ){
              this.zone.run(() => this.router.navigate(['/program/card-list'], {queryParams: {nav_from: this.navigationFrom}})).then();
            }
            else{
              this.zone.run(() => this.router.navigate(['/program/card-list'])).then();
            }
          }
          if (response.sc == 0) {
            this.appService.showError(response['error'].msg);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
}
