import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { urls, defaultLocation } from '../../../../shared/services/constant';
import { PopupService } from 'src/app/core/services/popup.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-saved-locations',
  templateUrl: './saved-locations.component.html',
  styleUrls: ['./saved-locations.component.scss']
})
export class SavedLocationsComponent implements OnInit {
  public locationList: any = [];
  public latitude: any;
  public longitude: any;
  public currentLocation: string = "Current Location";
  public id: any;
  public locArr: any;
  public viewLoading: boolean = false;
  private popupVisible: boolean = false;
  public membershipData:any = '';
  constructor(private appService: AppService, private popupService: PopupService,public translate:TranslateService,
    private dataService: DataService, private toastr: ToastrService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.fetchLocation();
    this.getUserProfile();
    this.memberInfo();
    this.dataService.sendMessage('Home');
  }
  fetchLocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          let data = {
            lat: position.coords.latitude,
            long: position.coords.longitude
          };
          this.latitude = JSON.stringify(data.lat);
          this.longitude = JSON.stringify(data.long);
        },
        error => {
          this.latitude = defaultLocation.latitude;
          this.longitude = defaultLocation.longitude;
        },
        {
          enableHighAccuracy: true
        }
      );
    }
  }
  getUserProfile() {
    this.locationList = [];
    this.locArr = ['', '', ''];
    this.viewLoading = true;
    this.appService.get(urls.getProfile).subscribe(
      data => {
        if (data['result'] && data['result'].locations == '') {
          let arr = data['result'].locations;
          for (let i = 0; i < arr.length; i++) {
            this.locationList.push(arr[i]);
          }
          this.viewLoading = false;
        }
        else if (data['result'] && data['result'].locations != '') {
          this.locArr = ['', '', ''];
          let arr = data['result'].locations;

          if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
              switch (arr[i].address_type) {
                case 1:
                  if (arr[i].address_type == 1) {
                    this.locArr[0] = arr[i];

                  }
                  else {
                    this.locArr[0] = '';
                  }
                case 2:
                  if (arr[i].address_type == 2) {
                    this.locArr[1] = arr[i];

                  }
                  else {
                    this.locArr[1] = '';
                  }
                case 3:
                  if (arr[i].address_type == 3) {
                    if (this.locArr[2] == '') {
                      this.locArr[2] = arr[i];
                      this.locArr[3] = '';

                    }
                    else if (this.locArr[3] == '') {
                      this.locArr[3] = arr[i];
                      this.locArr[4] = '';

                    }
                    else if (this.locArr[4] == '') {
                      this.locArr[4] = arr[i];

                    }
                  }
              }
            }
            for (let i = 0; i < this.locArr.length; i++) {
              this.locationList.push(this.locArr[i]);
            }
          }
          this.viewLoading = false;
        }
      }, error => {
        console.log('error', error);
        this.viewLoading = false;
      }
    )
  }
  memberInfo(){
    this.membershipData = '';
    this.viewLoading = true;
    this.appService.get(urls.membershipList + '/info').subscribe((res:any)=>{
      if(res.sc ==1){
        if(res.result && res.result.data){
          this.membershipData = res.result.data
        }
      }
      else{
        this.appService.showError(res.error.msg);
      }
      this.viewLoading = false;
    })
  }
  removemembership(){
    this.appService.delete(urls.membershipList,'').subscribe((res:any)=>{
      if(res.sc ==1){
        if(res.result){
          this.appService.showSuccess(res.result.message);
          this.getUserProfile();
          this.memberInfo();
        }
      }
      else{
        this.appService.showError(res.error.msg);
      }
    })
  }
  setLocation(value) {
    if (value == "current") {
      this.fetchLocation();
    }
    else {
      let obj = value;
      this.latitude = obj.lat;
      this.longitude = obj.long;
      this.currentLocation = obj.address;
    }
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
  saveLocation(name) {
    let a = name;
    let b = '';
    let c = { a, b }
    this.dataService.sendMessage(c);
    this.popupVisibilityChange();
  }
  edit(id, name) {
    let a = name;
    let b = id;
    let c = { a, b }
    this.dataService.sendMessage(c);
    this.popupVisibilityChange();
  }
  popupVisibilityChange() {
    this.getPopup('addPlaces');
    this.popupService.popupVisibilityChange.subscribe(value => {
      if (this.popupService.isPopupVisible) {
        this.popupService.togglePopupVisibility(false);
        this.getUserProfile();
          this.memberInfo();
      }

    });
  }
  removeLocation(id: any) {
    this.id = {
      location_id: id
    }
    this.locationList.findIndex(item => item._id == id);
    this.appService.delete(urls.deleteLocation, id).subscribe((response: any) => {
      if (response && response.sc === 1) {
        this.appService.showSuccess(response.result.message);
        this.getUserProfile();
          this.memberInfo();
      } else {
        this.appService.showError(response.error.msg);
      }
    }, (error) => {
      console.log(error);
    });
  }
}
