import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../shared/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'program-detail',
    loadChildren: './component/detail/detail.module#DetailModule'
  },
  {
    path: 'program-detail/:token',
    loadChildren: './component/detail/detail.module#DetailModule',
    canActivate : [AuthGuardService]
  },
  {
    path: 'map-view',
    loadChildren: './component/map-view/map-view.module#MapViewModule'

  },
  {
    path: 'favorites',
    loadChildren: './component/favorite/favorite.module#FavoriteModule',
  },
  {
    path: 'bookmarks',
    loadChildren: './component/bookmarks/bookmarks.module#BookmarksModule',
    canActivate : [AuthGuardService]
  },
  {
    path: 'map-list',
    loadChildren: './component/map-list/map-list.module#MapListModule'
  },
  {
    path: 'product-list',
    loadChildren: './component/product-list/product-list.module#ProductListModule'
  },
  {
    path: 'product-details',
    loadChildren: './component/product-details/product-details.module#ProductDetailsModule',
  },
  {
    path: 'cart',
    loadChildren: './component/cart/cart.module#CartModule'
  },
  {
    path: 'card-list',
    loadChildren: './component/card/card.module#CardModule'
  },
  {
    path: 'addCard',
    loadChildren: './component/add-card/add-card.module#AddCardModule'
  },
  {
    path: 'reviews',
    loadChildren: './component/reviews/reviews.module#ReviewsModule'
  },
  {
    path: 'child-progress',
    loadChildren: './component/child-progress/child-progress.module#ChildProgressModule'
  },
  {
    path: 'videos',
    loadChildren: './component/videos/videos.module#VideosModule'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramRoutingModule { }
