import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public activeIndex = 0;
  public currentURL = "";
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public description: any;
  public title: string;
  public faqArray: any = [];
  constructor(private appService: AppService,public translate:TranslateService,
    private router: Router) {
      window.scroll(0,0)
    this.currentURL = this.router.url.split('/')[2];
  }

  ngOnInit() {
    this.getPageInfo();
  }

  getPageInfo() {
    this.apiParam.url = urls.getFaq;
    this.faqApi();
  }

  onSearchChange(data) {
    if (data.length >= 3) {
      this.searchFaqList(data);
    }
    if(data.length ==0){
      this.getPageInfo();
    }
  }

  searchFaqList(data) {
    this.faqArray = [];
    this.apiParam.url = urls.faqSearch + data;
    this.faqApi();
  }

  faqApi(){
    this.appService.get(this.apiParam.url)
    .subscribe(
      (response: any) => {
        this.apiResponse = response;         
        if (this.apiResponse && this.apiResponse.sc == 1) {
          this.faqArray = [];
          let AccData = this.apiResponse.result.faq;            
          if (AccData && AccData.length > 0) {
            AccData.forEach((ele, ind) => {                
              let obj = { "ques": ele.ques, "ans": ele.ans, "id": ele._id }
              this.faqArray.push(obj)
            });
          }
        } 
      })
  }
}
