import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class  AuthServiceService {

  constructor(private router: Router) { }

   //Set User Secure Token
  setSecureToken(secure_token: string, first_name: string, last_name: string) {
    localStorage.setItem("LoggedIn", secure_token)
    localStorage.setItem("fName", first_name)
    localStorage.setItem("lName", last_name)
  }

  //Set User Secure Token
  getSecureToken() {
    return localStorage.getItem("LoggedIn")
  }

  //Check User is LoggedIn or not!
  isLoggednIn() {
    return this.getSecureToken() !== null;
  }

  //Logout method
  logout() {
    localStorage.clear();
    this.router.navigate(["/"]);
  }
}
