import { Component, OnInit } from '@angular/core';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { PopupService } from '../../../../core/services/popup.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {
  public msgs: any;
  public canAddUserRoleType: string;
  public addChildFailure = false;

  constructor(private childServiceService: ChildServiceService,
    private popupService: PopupService) { }

  ngOnInit() {
    this.msgs = this.childServiceService.errorMessInfo;
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
  }
  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }
}
