import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls ,toastMessage} from '../../../../shared/services/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})

export class MoreInfoComponent implements OnInit {
  public infoForm: FormGroup;
  public submitted = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public schoolArray: any = [];
  public ethnicityArray: any = [];
  public ethnicityTypeArray: any = [];
  public stateArray: any = [];
  public cityArray = [];
  public hideStateUl: boolean = false;
  public hideCityUl: boolean = false;
  public imgUrl: any;
  public stateId: any;
  public cityId: any;
  public countryFixed: string = 'United States';
  public searchValue: string = ''
  public cityValue: string = ''
  public otherRace: boolean = false
  public viewLoading: boolean = false;
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,public translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit() {
    window.scroll(0, 0)
    this.formInitialization();
    this.getProfile();
    this.getEthnicityType();
    this.getEthnicityList();
  }
  formInitialization() {
    this.infoForm = this.formBuilder.group({
      ethnicity_type: ['', Validators.required],
      ethnicity: ['', Validators.required],
      other_ethnicity: [''],

    });
  }
  get f() { return this.infoForm.controls; }

  onSubmit(user) {
    let data = Object.assign({}, user);
    data.profile_completed = false;
    this.submitted = true;
    if (this.infoForm.invalid) {
      return;
    } else {
      this.apiParam.params = data;
      this.appService.post(urls.editProfile, this.apiParam.params)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.appService.showSuccess(toastMessage.basicInfoUpdate)
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }

  getProfile() {
    this.viewLoading = true;
    this.appService.get(urls.getProfile)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.infoForm.controls.ethnicity_type.setValue((response.result.user.ethnicity_type._id) ? response.result.user.ethnicity_type._id : "")
            this.infoForm.controls.ethnicity.setValue((response.result.user.ethnicity._id) ? response.result.user.ethnicity._id : "")
            this.infoForm.controls.other_ethnicity.setValue(response.result.user.other_ethnicity)
            if (response.result.user.ethnicity._id == '5ba0ddfa7b5d563b685a7236') {
              this.otherRace = true;
            }
          }
          this.viewLoading = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  getEthnicityType() {
    this.viewLoading = true;
    let params = {};
    this.appService.get(urls.ethnicityType)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            if (response.result.ethnicityType && response.result.ethnicityType.length > 0) {
              response.result.ethnicityType.forEach((ele, ind) => {
                let obj = {
                  "ethnicity_type_name": ele.ethnicity_type_name,
                  "id": ele._id,
                }
                this.ethnicityTypeArray.push(obj)
              });
            }
          }
          this.viewLoading = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getEthnicityList() {
    let params = {};
    this.viewLoading = true;
    this.appService.post(urls.listEthnicity, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.ethnicityArray = [];
            if (response.result.ethnicity && response.result.ethnicity.length > 0) {
              response.result.ethnicity.forEach((ele, ind) => {
                let obj = {
                  "insensitive": ele.insensitive,
                  "name": ele.name,
                  "id": ele._id,
                }
                this.ethnicityArray.push(obj)
              });
            }
          }
          this.viewLoading = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getSchoolist() {
    this.apiParam.appJson = true;
    let params = {};
    this.appService.post(urls.listSchool, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.schoolArray = [];
            if (response.result.schools && response.result.schools.length > 0) {
              response.result.schools.forEach((ele, ind) => {
                let obj = {
                  "insensitive": ele.insensitive,
                  "school_name": ele.school_name,
                  "id": ele._id,
                }
                this.schoolArray.push(obj)
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  onSearchChange(searchValue) {

    this.hideStateUl = true;
    if (searchValue.length >= 3) {
      this.getStateList(searchValue);
    }
  }

  getStateList(searchValue) {
    let params = {
      "country_id": 231,
      "state": searchValue
    };
    this.appService.post(urls.stateList, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            localStorage.setItem("countryId", response.result.country[0]._id);
            this.stateArray = [];
            if (response.result.state && response.result.state.length > 0) {
              response.result.state.forEach((ele, ind) => {
                let obj = {
                  "name": ele.name,
                  "_id": ele._id,
                  "id": ele.id,
                }
                this.stateArray.push(obj)
              });

            }
            else {
              if (!this.stateArray.length) {
                observableOf(null).pipe(delay(800)).subscribe(() => {
                  this.searchValue = '';
                  this.infoForm.patchValue({ state: '' });
                  this.infoForm.patchValue({ city: '' });
                  this.cityValue = ''
                })
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  onSelected(state) {
    this.stateId = state._id
    this.infoForm.patchValue({ state: state.name });
    this.searchValue = state.name;
    this.infoForm.patchValue({ city: '' });
    this.cityValue = ''
    this.hideStateUl = false;
    let params = {
      "country_id": 231,
      "state_id": state.id
    };
    this.appService.post(urls.stateList, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {

            this.cityArray = [];
            if (response.result.city && response.result.city.length > 0) {
              response.result.city.forEach((ele, ind) => {
                let obj = {
                  "name": ele.name,
                  "id": ele._id,
                }
                this.cityArray.push(obj)
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )


  }
  onSelectedCity(city) {
    this.cityId = city.id
    this.infoForm.patchValue({ city: city.name });
    this.cityValue = city.name;
    this.hideCityUl = false;
  }

  showCityList() {
    if (this.cityArray.length) {
      this.hideCityUl = true;
    }
  }
  ifNumericKey(event) {
    return ((event.keyCode >= 97 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 8 || event.keyCode == 9)
  }

  checkEthincity(type) {
    this.otherRace = false;
    if (type == "5ba0ddfa7b5d563b685a7236") {
      this.otherRace = true;
    }

  }
}
