import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import _ from 'lodash';
import { urls } from 'src/app/shared/services/constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-academic-record',
  templateUrl: './academic-record.component.html',
  styleUrls: ['./academic-record.component.scss']
})
export class AcademicRecordComponent implements OnInit {
  public grades = [9, 10, 11, 12, 13];
  public subjects: any;
  public subSubjectsList: any ={};
  public subs:any = {};
  public selectedGrade = 9;
  public score: any = {};
  public viewLoading: boolean = false;
  public isSubmit : boolean = true;
  public modal: any;
  public btn: any;
  public isGraduated:any;
  public empty_grade:any = [];
  public disabled:boolean = false;
  constructor(private appService: AppService,private router: Router,public translate: TranslateService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.getSubjectList();
    this.check();

    $(document).click(function(event) {
      //if you click on anything except the modal itself or the "open modal" link, close the modal
      if (!$(event.target).closest(".panel-collapse.in.collapse.show").length) {
        $("body").find(".panel-collapse.in.collapse").removeClass("show");
      }
    });
  }

  getSubjectList(){
    this.viewLoading = true;
    this.appService.get(urls.subjectList).subscribe(res=>{
       if(res['result'] && res['result'].list){
         this.subjects = res['result'].list;
         for(let i = 0;i<this.subjects.length;i++){
          const arr = this.subjects[i].subjectList;
          this.subSubjectsList[this.subjects[i].subjectType]= arr.reduce((acc,curr)=> (acc[curr]={ s1: null, s2: null },acc),{});
          this.subs[this.subjects[i].subjectType]= arr.reduce((acc,curr)=> (acc[curr]={ s1: null, s2: null },acc),{});
         }
         this.getRecord();
         this.viewLoading =false;
       }
    })
  }
  selectGrade(grade) {
    this.selectedGrade = grade;
    this.getRecord();
  }
  validate() {
     let isSubmit = true;
    if (this.selectedGrade == undefined) {
      this.appService.showError('Please select a grade');
      let isSubmit = false
    }
    return isSubmit
  }
  returnZero() {
    return 0
  }
  onchange(event){
    if(event.target.valueAsNumber == 0 && event.target.value.length >2){
        event.target.value = event.target.valueAsNumber
    }
  }
  keypress(event) {
      if (event.charCode >= 48 && event.charCode <= 57) {
        return event;
      }
      else {
        return false;
      }

  }
keyup(event){
  if(event.target.value.length <=3 && event.target.valueAsNumber <= 100){
      this.isSubmit = true;
    }
    else if(event.target.value==""){
      this.isSubmit = true;
    }
    else{
      this.isSubmit = false;
    }
}
open(pop: any) {
  this.modal = document.getElementById(pop);
  this.modal.style.display = "block";
}

closemodal() {
  this.btn = document.getElementsByClassName("close")[0];
  this.modal.style.display = "none";
}
  addGrades() {
    if (this.validate()) {
      this.viewLoading = true;
      let result = this.pruneEmpty(this.subSubjectsList)
      let formData = {
        grade: this.selectedGrade,
        score: result
      }
      this.appService.post(urls.updateRecord, formData).subscribe((response: any) => {
        if (response.sc == 1) {
          if (response['result']) {
            this.appService.showSuccess(response['result'].message);
            this.router.navigate(["/personal-insight"])
          }
        }
        else {
          this.appService.showError(response['error'].msg)
        }
        this.viewLoading = false;
      })
    };
  }
  checked(event){
    this.isGraduated = event;
     if(event == true){
         this.checkCompleted();
     }
  }
  checkCompleted(){
    this.viewLoading = true;
    this.appService.get(urls.checkgrades).subscribe((res:any)=>{
          if(res && res['result'] && res['result'].grades){
            this.viewLoading = false;
            if(res['result'].grades.length == 5){
              this.graduated();
            }
            else{
              this.isGraduated = false;
              this.empty_grade = this.grades.filter(x => !res['result'].grades.includes(x));
              this.open('graduated');
            }
          }
    })
  }
  graduated(){
    this.viewLoading = true;
    this.appService.put(urls.isGraduated,'').subscribe((res:any)=>{
        if(res && res['result']){
          this.appService.showSuccess(res['result'].message);
          this.router.navigate(['/personal-insight'])
        }
        else{
          this.appService.showError(res['error'].msg)
        }
        this.viewLoading = false;
    })
  }
  pruneEmpty(obj) {
    return function prune(current) {
      _.forOwn(current, function (value, key) {
        if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
          (_.isString(value) && _.isEmpty(value)) ||
          (_.isObject(value) && _.isEmpty(prune(value)))) {
  
          delete current[key];
        }
      });
      if (_.isArray(current)) _.pull(current, undefined);
  
      return current;
  
    }(_.cloneDeep(obj));
  }
  getRecord() {
    this.viewLoading = true;
    return this.appService.get(urls.getGrade + `?grade=${this.selectedGrade}`).subscribe((response: any) => {
      if (response['result']) {
        for(let i = 0;i<this.subjects.length;i++){
          const arr = this.subjects[i].subjectList;
          this.subSubjectsList[this.subjects[i].subjectType]= arr.reduce((acc,curr)=> (acc[curr]={ s1: null, s2: null },acc),{});
         }
        if(response['result'].score){
          let result = this.subSubjectsList
          this.subSubjectsList = _.merge(result,response['result'].score)
       }
       else {
        for(let i = 0;i<this.subjects.length;i++){
          const arr = this.subjects[i].subjectList;
          this.subSubjectsList[this.subjects[i].subjectType]= arr.reduce((acc,curr)=> (acc[curr]={ s1: null, s2: null },acc),{});
         }
        }
    }
      this.viewLoading = false
    })
  }
  check(){
    this.appService.get(urls.checkGraduated).subscribe((res:any)=>{
          if(res && res['result'] && res['result'].is_graduated){
            this.isGraduated = res['result'].is_graduated
            if(this.isGraduated == true){
              this.disabled =true;
            }
          }    
    })
  }
}
