import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';

@Component({
  selector: 'app-rewards-center',
  templateUrl: './rewards-center.component.html',
  styleUrls: ['./rewards-center.component.scss']
})
export class RewardsCenterComponent implements OnInit {
  public unverified_coin: number=null;
  public totalCoin: number = null;
  public showLoader: boolean;
  constructor(private appService: AppService,public translate: TranslateService) { }

  ngOnInit() {
    this.getTotal();
    window.scroll(0,0);
  }
  getTotal() {
    this.showLoader = true;
    this.appService.post(urls.getTotalPoint)
      .subscribe((res: any) => {
        this.showLoader = false;
        this.totalCoin = res.result.Total_Coin;
        this.unverified_coin=res.result.unverified_coin
      })
  }
}
