import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls, fileSize, toastMessage } from '../../../../shared/services/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})

export class BasicInfoComponent implements OnInit {
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public email: any;
  public editProfileForm: FormGroup;
  public submitted = false;
  public basicProfileApiResponse: boolean = true;
  public date: any
  public dob: any
  public disableSinceDay: number = new Date().getDate() + 1;
  public disableSinceModelDay: number = new Date().getDate();
  public disableSinceMonth: number = new Date().getMonth() + 1;
  public disableSinceYear: number = new Date().getFullYear() - 13;
  public imgUrl: any;
  public genderDisplay: any;
  public role: number = 0
  public userData : any;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'mmm dd, yyyy',
    minYear: 1900,
    maxYear: this.disableSinceYear,
    disableSince: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceDay }
  };
  public viewLoading: boolean = false;
  user:any;
  CountryISO = CountryISO;
  public userInfo: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
    picture: '',
    gender: ''
  }
  // Initialized to specific date (09.10.2018)
  public model: any = { date: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceModelDay } };
  constructor(
    private appService: AppService,private router: Router,public translate: TranslateService,
    private formBuilder: FormBuilder,private db: AngularFirestore,private afAuth: AngularFireAuth,
    private toastr: ToastrService) {window.scroll(0,0) }

  ngOnInit() {
    this.afAuth.auth.signInAnonymously().then((user) => {
      console.log(user)
      })
      .catch(error => console.log(error));
    this.getProfile();
    this.formInitialization();
    this.genderDisplay = 1;
  }

  formInitialization() {
    this.editProfileForm = this.formBuilder.group({
      first_name: [this.userInfo.first_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      last_name: [this.userInfo.last_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      email: [this.userInfo.email, [Validators.required, Validators.email]],
      phone_no: [this.userInfo.phone_no, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      gender: [this.userInfo.gender, Validators.required],
      image: [''],
      dob: ['', Validators.required]
    });
  }
  setRoleValidators(){
    const dobControl= this.editProfileForm.get('dob');

      if (this.role == 1) {
        dobControl.setValidators(null);
      }

      if (this.role == 2) {
        dobControl.setValidators([Validators.required]);
      }
      dobControl.updateValueAndValidity();
    
  }
  // convenience getter for easy access to form fields
  get f() { return this.editProfileForm.controls; }

  onSubmit(user) {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    }
    else if (this.genderDisplay == 0) {
      this.appService.showError('Gender is not selected');
  } else {
     // this.basicProfileApiResponse = false;
      let data = Object.assign({}, user);
      if(this.role == 2){
        let myDate = user.dob.date.day + '-' + user.dob.date.month + '-' + user.dob.date.year
        let splitData = myDate.split("-");
        let newDate = splitData[1] + "/" + splitData[0] + "/" + splitData[2];
        data.dob = new Date(newDate).getTime();
        data.dob = (data.dob).toString()
      }
      else{
        data.dob = "";
      }
      let number =this.editProfileForm.controls.phone_no.value.number.replace(user.phone_no.dialCode,'')
      data.country_code = this.editProfileForm.controls.phone_no.value.dialCode.split('+')[1];
      data.phone_no = number
      data.url = urls.editProfile
      data.profile_completed = false;
      this.appService.makeFileRequest(data)
        .subscribe(
          (response: any) => {
            let result = JSON.parse(response);
            if (result && result.sc == 1) {
              console.log(result)
              this.userData = result.result
              localStorage.setItem("fName", (result.result.user.first_name) ? result.result.user.first_name : "")
              localStorage.setItem("lName", (result.result.user.last_name) ? result.result.user.last_name : "")
              localStorage.setItem("profilePic", (typeof result.result.user.profile_pic === 'undefined' || result.result.user.profile_pic === null) ? "" : result.result.user.profile_pic)
              this.appService.showSuccess(toastMessage.basicInfoUpdate);
              this.updateFirebase(result.result.user._id);
              this.router.navigate(['/profile'])
            } else {
              this.appService.showError(result.error.msg)
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }
  updateFirebase(id){
    this.db.collection("Users",ref => ref.where('user_ID', '==', id)).snapshotChanges().subscribe(res => {
      if (res.length > 0)
      {
         return this.db.collection('Users').doc(id).update({
          email : this.userData.user.email,
          display_name : this.userData.user.first_name + ' ' + this.userData.user.last_name,
          user_image :  this.userData.user.profile_pic
          })
        .then(()=> {
          console.log("Document successfully updated!");
          // this.toastr.success('User updated successfully');
       })
      .catch((error)=> {
          console.error("Error updating document: ", error);
       });
      }
    });
  }
  getProfile() {
    this.viewLoading = true;
    this.appService.get(urls.getProfile)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            localStorage.setItem("linkedin", response.result.user.connect_by_linkedin)
            this.user = response.result.user
            this.role = response.result.user.role
            this.date = new Date(response.result.user.dob)
            this.editProfileForm.controls.email.setValue(response.result.user.email)
            this.editProfileForm.controls.first_name.setValue(response.result.user.first_name)
            this.editProfileForm.controls.last_name.setValue(response.result.user.last_name)
            this.editProfileForm.controls.phone_no.patchValue('+'+response.result.user.country_code.concat(response.result.user.phone_no))
            this.editProfileForm.controls.gender.setValue(response.result.user.gender)
            this.userInfo.picture = (response.result.user.profile_pic) ? response.result.user.profile_pic : ""
            this.editProfileForm.patchValue({
              dob: {
                date: {
                  year: this.date.getFullYear(),
                  month: this.date.getMonth() + 1,
                  day: this.date.getDate()
                }
              }
            });
            this.setRoleValidators();
          }
          this.viewLoading = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getProfilePic() {
    let dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else if (this.userInfo.picture) {
      st = { background: `url(${this.userInfo.picture})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }
  onFileChanged(event) {
    let imageType = event.target.files[0].type.split("/")
    if (imageType[0] == "image") {
      if (event.target.files[0].size < fileSize && imageType[0] == "image") {
        if (event.target.files.length) {
          let files = event.target.files;
          this.imgUrl = event.target.value;
          var that = this;
          let reader = new FileReader();
          reader.onload = function (event: any) {
            that.imgUrl = event.target.result;
          };
          reader.readAsDataURL(files[0]);
        }
        const file = event.target.files[0]
        this.editProfileForm.patchValue({ image: file });
      } else {
        this.appService.showError(toastMessage.imageSize);
      }
    } else {
      this.appService.showError(toastMessage.imageCheck);
    }
  }

  clearDate(): void {
    this.editProfileForm.patchValue({ dob: null });
  }
  
  // optional date changed callback
  onDateChanged(event: IMyDateModel): void {
    this.editProfileForm.patchValue({ dob: event.epoc * 1000 });
    // date selected
  }

  callType(value) {
    this.editProfileForm.patchValue({ gender: value });
  }
}
