import { Component, OnInit } from '@angular/core';
import { urls } from '../../../../shared/services/constant';
import { ApiParams, ApiResponse } from "../../../../shared/model/apiResponse";
import { AppService } from "../../../../shared/services/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { AuthServiceService } from "../../../../core/services/authService/auth-service.service";
import { PopupService } from "../../../../core/services/popup.service";
import { of as observableOf } from 'rxjs';
import { map, filter, throttleTime, delay } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

@Component({
    selector: 'app-mycheck-in',
    templateUrl: './mycheck-in.component.html',
    styleUrls: ['./mycheck-in.component.scss']
})

export class MycheckInComponent implements OnInit {
    public showFiler: boolean = false;
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public latitude: string;
    public longitude: string;
    public programArray: any = [];
    public searchArray: any = [];
    public season: any;
    public springSeason: string = '';
    public winterSeason: string = '';
    public fallSeason: string = '';
    public summerSeason: string = '';
    public resultsScrollObservable: any;
    public scrollSubscription: any;
    public page_no: number = 1;
    public lastElementData: any;
    public program_type: number = 3;
    public premium_type: any = 2;
    public lasTimestamp: number = 0;
    public faIconClass: string = 'fa fa-caret-right';
    public showNextData = true;
    public bucketArray: any = [];
    public checkedBucket: any = [];
    public checkedProgram: any = [];
    public checkedDays: any = [];
    public showNoResultFound: boolean = false;
    public seasonData: any = '';
    public timeData: any = '';
    public hideProgramUl: boolean = false;
    public type: any = '';
    public locationList: any = [];
    public isClicked: boolean = false;
    private bucketId: string = '';
    private programData: any = [];
    private programArr: any = [];
    private isUserLoggedIn: boolean = true;
    public viewLoading:boolean = true;
    constructor(private appService: AppService,
        private router: Router,public translate: TranslateService,
        private popupService: PopupService,
        private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.bucketId = params['id'];
        })
        this.activatedRoute.queryParams.subscribe(params => {
            this.type = params['type'];
            if (this.type === 'refersh') {
                let clean_uri = location.protocol + '//' + location.host + location.pathname;
                window.history.replaceState({}, document.title, clean_uri);
                this.page_no = 1;
                this.lasTimestamp = 0;
                this.premium_type = 2;
                this.programArray = [];
            }
        });
    }

    ngOnInit() {
        // Analytics.record({ name: 'Check IN' });
        observableOf(null).pipe(delay(400)).subscribe(() => {
            window.scrollTo(0, 0);

        });

        this.resultsScrollObservable = fromEvent(window, 'scroll');
        /**
         * Captures scrolling behavior within teh application, so we can implement infinite
         *  scroll or hiding elements once a certain amount of scrolling is done.
         */
        this.scrollSubscription = this.resultsScrollObservable
            .pipe(
                throttleTime(250),
                map((event: any) => {
                    if (event) {
                        const elem = event.srcElement ? event.srcElement : event.target;
                        const srcElement = elem.body;
                        const clientHeight = srcElement ? srcElement.clientHeight : 0;
                        const el = document.scrollingElement || document.documentElement;
                        const scrollTop = el.scrollTop;
                        const position = scrollTop + clientHeight;
                        const height = srcElement ? srcElement.scrollHeight : 0;
                        const positionPercent = +((position / height).toFixed(2));
                        return positionPercent > .85;
                    }
                }),
                filter((doSearch: boolean) => doSearch)
            )
            .subscribe((doSearch: boolean) => {
                if (this.showNextData) {
                    this.page_no = this.page_no + 1;
                }

            });
        this.isLoggedIn();
        this.getProgramList();
    }

    getProgramList() {
        this.viewLoading = true;
        this.apiParam.appJson = true;
        let params = {
            'timestamp': 0,
            'limit': 10,
            'page_no': this.page_no,
            'bucket_id': "3"
        };
        this.appService.post(urls.programHomeList, params)
            .subscribe(
                (response: any) => {
                    this.showNextData = false;
                    if (response['result'] && response['result'].home && response['result'].home.length) {
                        this.programData = response['result'].home[0];
                        let ProgramData: any = response['result'].home[0].programList;
                        if (ProgramData && ProgramData.length > 0) {
                            ProgramData.forEach((ele, ind) => {
                                this.programArray.push(ele);
                            });
                            this.lastElementData = ProgramData[ProgramData.length - 1];
                            this.program_type = this.lastElementData.program_type;
                            this.premium_type = (this.lastElementData.free === 0) ? this.lastElementData.paid : this.lastElementData.free;
                            this.lasTimestamp = this.lastElementData.created_at;
                            this.showNextData = true;
                        }
                    }
                    this.viewLoading = false;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    showSeason(ele) {
        let a = '';
        let b = '';
        let c = '';
        let d = '';
        if (ele.spring_season === 1) {
            a = 'Spring, ';
        }
        if (ele.winter_season === 1) {
            b = 'Winter, ';
        }
        if (ele.fall_season === 1) {
            c = 'Fall, ';
        }
        if (ele.summer_season === 1) {
            d = 'Summer,';
        }
        let season = '';
        season = a + b + c + d;
        if (d && d.length)
            season = season.slice(0, -1);
        else
            season = season.slice(0, -2);
        return season;
    }

    isLoggedIn() {
        let user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true
        }
        else {
            this.isUserLoggedIn = false
        }
    }

    programDetail(id) {
        if (this.isUserLoggedIn)
            this.router.navigate(['program/program-detail/' + id]);
        else
            this.getPopup('onboarding')
    }

    getPopup(test): void {
        this.popupService.showPopup(test);

    }

    showBuckets(ele) {
        let buckets = '';
        for (let i = 0; i < ele.buckets.length; i++) {
            buckets = buckets + ele.buckets[i].name + ', ';
        }
        if (buckets && buckets.length)
            buckets = buckets.slice(0, -2);
        return buckets;
    }

}

