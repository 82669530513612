import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './component/landing/landing.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../../modules/user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
// import { keys } from 'keys';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    CommonModule,
    LandingRoutingModule,
    SharedModule,
    UserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AmplifyUIAngularModule,
    AngularFireAnalyticsModule,
    TranslateModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [LandingComponent]
})
export class LandingModule { }
