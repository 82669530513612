import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        if (this.isUserAuthenticated()) {
            return true;
        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }
    public isUserAuthenticated() {
        return !!localStorage.getItem('LoggedIn');
    }
}
