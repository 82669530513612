export class ApiResponse {
    result: any;
    error: Error;
    sc: number;
    time: any;
    message?: string;
}
export class Error {
    errorCode: number;
    msg: string;
}
export class ApiParams {
    url: string;
    params: any;
    doStringify: boolean = true;
    addAuthInHeaders: boolean = true;
    appJson: boolean = true;
}
