import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
import { ChildServiceService } from '../../../../core/services/child/child-service.service';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-search-parent',
  templateUrl: './search-parent.component.html',
  styleUrls: ['./search-parent.component.scss']
})
export class SearchParentComponent implements OnInit {

  @Output() isAlreadyAdd: EventEmitter<any> = new EventEmitter<any>();
  public searchChildForm: FormGroup;
  public submitted = false;
  public loginRole: any;
  public canAddUserRoleType: string;
  public msgS: any;
  public type: any
  CountryISO = CountryISO;
  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,public translate:TranslateService,
    private popupService: PopupService,
    private childServiceService: ChildServiceService) {window.scroll(0,0) }

  ngOnInit() {
    this.loginRole = localStorage.getItem("loginRole");
    this.canAddUserRoleType = localStorage.getItem("canAddUserRoleType");
    if (this.loginRole == 1) {
      this.msgS = "Please enter your child’s phone number to add your child";
    } else {
      this.msgS = "Please enter your parent’s phone number to add your parent/guardian"
    }
    this.searchChildForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]]
    });
  }
  get f() { return this.searchChildForm.controls; }

  onSubmit(user) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.searchChildForm.invalid) {
      return;
    } else {
      let number = user.phone_no.number.replace(user.phone_no.dialCode,'')
      let params = {
        "phone_no": number
      };
      this.appService.post(urls.searchUser, params).subscribe((response: any) => {
        if (response && response.sc == 1) {
          if (response.result.member_status == 1 || response.result.member_status == 4) {
            localStorage.setItem("child_no", number)
            localStorage.setItem("childCountryCode",user.phone_no.dialCode)
            this.childServiceService.childInfo = response.result;
            this.type = (this.loginRole == 1) ? "child" : "parent";
            if (response.result.member_status == 1) {
              this.childServiceService.errorMessInfo = "Hi " + localStorage.getItem("fName") + ", " + "\"" + number + "\"" + " is not registered into our system. Please create an account for your " + this.type + " with this phone number.";
              this.childServiceService.checkWhetherParentChildExists = true;
              this.childServiceService.messageInfor = this.type;
            }
            else {
              sessionStorage.setItem("newChildName", response.result.user.first_name);
              this.childServiceService.errorMessInfo = "Hi " + localStorage.getItem("fName") + ", " + "\"" + number + "\"" + " is already registered into our system with a name " + "\"" + response.result.user.first_name + "\"" + ".";
            }
            this.router.navigate(["/parent/add-parent"]);
          }
          else if (response.result.member_status == 2) {
            sessionStorage.setItem("newChildName", response.result.user.first_name);
            this.childServiceService.errorMessInfo = "Entered phone number " + "\"" + number + "\"" + " is already your child with a name " + "\"" + response.result.user.first_name + "\"" + ".";
            this.getPopup('addProfileError');
          }
          else {
            sessionStorage.setItem("newChildName", response.result.user.first_name);
            this.childServiceService.errorMessInfo = "Entered phone number " + "\"" + number + "\"" + " is already your parent with a name " + "\"" + response.result.user.first_name + "\"" + ".";
            this.getPopup('addProfileError');
          }
        }
        else {
          this.appService.showError(response.error.msg);
        }
      },
        (error) => {
          console.log(error);
        }
      )
    }
  }

  getPopup(poupType): void {
    this.popupService.showPopup(poupType);
  }

  redirect() {
    this.router.navigate(["/user/profile"]);
  }
}
