import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgramRoutingModule } from './program-routing.module';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { HttpCancelService } from '../../shared/services/httpcancel.service';
import {DetailModule } from './component/detail/detail.module';
import { FavoriteModule } from './component/favorite/favorite.module';
import { MapListModule } from './component/map-list/map-list.module';
import { MapViewModule } from './component/map-view/map-view.module';
import { ProductDetailsModule } from './component/product-details/product-details.module';
import { ProductListModule } from './component/product-list/product-list.module';
import { CartModule } from './component/cart/cart.module';
import { CardModule } from './component/card/card.module';
import { AddCardModule } from './component/add-card/add-card.module';
import { ReviewsModule } from './component/reviews/reviews.module';
import { ChildProgressModule } from './component/child-progress/child-progress.module';
import { VideosModule } from './component/videos/videos.module';
import { BookmarksModule } from './component/bookmarks/bookmarks.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    ProgramRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    InfiniteScrollModule,
    DetailModule,
    FavoriteModule,
    MapListModule,
    MapViewModule,
    ProductDetailsModule,
    ProductListModule,
    CartModule,
    CardModule,
    AddCardModule,
    ReviewsModule,
    ChildProgressModule,
    VideosModule,
    BookmarksModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule
  ],
  providers: [
    HttpCancelService,
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: []
})
export class ProgramModule { }
