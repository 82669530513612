import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserSettingComponent } from './component/user-setting/user-setting.component';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
const routes: Routes = [
  {
    path: 'user-settings',
    component: UserSettingComponent,
   canActivate : [AuthGuardService]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
