import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialShareRoutingModule } from './social-share-routing.module';
import { SocialShareComponent } from './component/social-share/social-share.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
@NgModule({
  imports: [
    CommonModule,
    SocialShareRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [SocialShareComponent]
})
export class SocialShareModule { }
