import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})

export class EditProfileComponent implements OnInit {
  public moreInfo = false;
  public socialInfo = false;
  public parentInfo = true;
  public currentURL: string;
  public newURL: string;
  public URL: string;
  public currentRolee: any;
  public model: any = { date: { year: 2005, month: 10, day: 9 } };

  constructor(private router: Router,public translate: TranslateService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.urlAfterRedirects;
        this.currentURL = this.currentURL.split("/").pop();
        if (this.currentURL === 'basic-info') {
          this.parentInfo = true;
          this.socialInfo = false;
          this.moreInfo = false;
        }
        if (this.currentURL === 'more-info') {
          this.moreInfo = true;
          this.parentInfo = false;
          this.socialInfo = false;
        }
        if (this.currentURL === 'social-info') {
          this.parentInfo = false;
          this.socialInfo = true;
          this.moreInfo = false;
        }
        this.newURL = this.currentURL.split("?").shift();
        if (this.newURL === 'social-info') {
          this.parentInfo = false;
          this.socialInfo = true;
          this.moreInfo = false;
        }
        this.URL = this.currentURL.split("#").shift();
        if (this.URL === 'social-info') {
          this.parentInfo = false;
          this.socialInfo = true;
          this.moreInfo = false;
        }
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.currentRolee = localStorage.getItem('loginRole');
  }
}
