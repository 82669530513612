import { Component, OnInit } from '@angular/core';
import { ApiParams, ApiResponse } from "../../../../shared/model/apiResponse";
import { AppService } from '../../../../shared/services/app.service';
import { ToastrService } from "ngx-toastr";
import { PopupService } from "../../../../core/services/popup.service";
import { ActivatedRoute, Router } from "@angular/router";
import { urls, toastMessage } from '../../../../shared/services/constant';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public productDetail: any = {};
    public navigationFrom: any;
    public productId: string = '';
    public url: string = '';
    public activeIndex: number;
    public model: any = {};
    public cartCount: number = 0;
    public viewLoading: boolean = false;
    constructor(private appService: AppService,
        private router: Router,
        private toastr: ToastrService,public translate: TranslateService,
        private popupService: PopupService,
        private activatedRoute: ActivatedRoute,) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.productId = params['id'];
        });
    }

    ngOnInit() {
        this.getProductDetail();
        window.scrollTo(0, 0);
    }

    getPrimaryProductImage(product) {
        let imgObj = product.images.filter(function (image) {
            return image.primary == true;
        });
        this.url = imgObj[0].url;
        this.activeIndex = product.images.findIndex(obj => obj.primary == true);
    }

    changeImage(image, index) {
        this.url = image.url;
        this.activeIndex = index;
    }

    getProductDetail() {
        this.viewLoading = true;
        this.appService.get(urls.productDetails + this.productId)
            .subscribe(
                (response: any) => {
                    this.viewLoading = false;
                    if (response.sc == 1) {
                        this.productDetail = response.result;
                        this.cartCount = this.productDetail.cartLength;
                        this.model.quantity = 1;
                        this.model.product_id = this.productId;
                        this.getPrimaryProductImage(this.productDetail);
                    }
                    if (response.sc == 0) {
                        this.appService.showError(response.error.msg);
                        this.router.navigate(['/home/list'])
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    getPopup(popupType): void {
        this.popupService.showPopup(popupType);
    }


    addToCart() {
        if (!this.model.size || !this.model.size.length) {
            this.appService.showError(toastMessage.productSize);
        }
        else if (!this.model.color || !this.model.color.length) {
            this.appService.showError(toastMessage.productColor);
        }
        else {
            this.appService.post(urls.addToCart, this.model)
                .subscribe(
                    (response: any) => {
                        if (response.sc == 1) {
                            this.cartCount++;
                            this.appService.showSuccess(response.result.message)
                        }
                        if (response.sc == 0) {
                            this.appService.showError(response.error.msg);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        }
    }
}
