import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { ChildProgressComponent } from './child-progress.component';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: ChildProgressComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule,TranslateModule
  ],
  exports: [RouterModule,],
  declarations: [ChildProgressComponent],
})
export class ChildProgressModule { }
