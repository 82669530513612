import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { AppService } from '../../../../shared/services/app.service';
import { urls ,toastMessage} from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reedem-otp',
  templateUrl: './reedem-otp.component.html',
  styleUrls: ['./reedem-otp.component.scss']
})
export class ReedemOtpComponent implements OnInit, AfterViewInit {

  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public timer: boolean = false;
  public otpSend: boolean = true;
  public counter: string;
  public seconds = 10;
  public userPhone = localStorage.getItem("userPhone");
  public otpForm: FormGroup;
  public submitted = false;
  public otpApiResponse: boolean = true;
  public timerGoingOn: boolean = false;
  public disableResend: boolean = false;
  @ViewChild('otpFirst', {static: false}) otpFirst:ElementRef;
  @ViewChild('otpSecond', {static: false}) otpSecond:ElementRef;
  @ViewChild('otpThird', {static: false}) otpThird:ElementRef;
  constructor(private popupService: PopupService,
    private appService: AppService,public translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthServiceService) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpFirst: ['', Validators.required],
      otpSecond: ['', Validators.required],
      otpThird: ['', [Validators.required]],
      otpFour: ['', [Validators.required]]
    });
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

  get f() { return this.otpForm.controls; }

  onSubmit(user) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    } else {
      this.otpApiResponse = false;
      let params = {
        "phone_no": localStorage.getItem("userPhone"),
        "otp": user.otpFirst + user.otpSecond + user.otpThird + user.otpFour
      };
      this.appService.post(urls.otpConfirmation, params)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name)
              localStorage.setItem("LoggedIn", response.result.token)
              localStorage.setItem("firstTime",response.result.user.is_first_time)
              localStorage.setItem("userRole",response.result.user.role);
              this.otpForm.reset(this.otpForm.value);
              localStorage.setItem("profilePic", (typeof response.result.user.profile_pic === 'undefined' || response.result.user.profile_pic === null) ? "" : response.result.user.profile_pic)
              localStorage.setItem("canAddUserRoleType", response.result.user.role == 1 ? "Child" : "Parent");
              if (response.result.user.role == 1) {
                localStorage.setItem("first_name", response.result.user.first_name)
                this.getPopup('linkSocialAccount');
              } else {
                this.getPopup('interest');
              }

            } else {
              this.appService.showError(response.error.msg);
              this.otpApiResponse = true;
              this.otpForm.reset();
              this.submitted = false;
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }


  resendOtp() {
    this.disableResend = true;
    let params = {
      "phone_no": localStorage.getItem("userPhone"),
      "country_code": localStorage.getItem('countryCode')
    };
    this.appService.post(urls.resendOtp, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.disableResend = false;
            this.appService.showSuccess(toastMessage.otpSent)
            this.countdown();
            this.otpApiResponse = true;
            this.otpForm.reset();
            this.submitted = false;
          } else {
            this.countdown();
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  ngAfterViewInit() {
    this.onkeypressChangeFocusOfOTPinput();
  }
  public onkeypressChangeFocusOfOTPinput() {
    setTimeout(() => {
      let elem: any = document.getElementsByClassName("otp-input");
      for (let i = 0; i < elem.length; i++) {
        elem[i].addEventListener('keyup', (function (e) {
          if(e.keyCode != 8){
            if (this.value.length == this.maxLength && elem[i + 1]) {
              elem[i + 1].focus();
            }
          }
          else{
            if (this.value.length == 0 && elem[i - 1]) {
              elem[i - 1].focus();
            }
          }
        }));
      }
    }, 500)
  }


  timerValue = 0

  public countdown() {
    this.timerValue = 60;
    this.timerGoingOn = true;
    this.reduce();
  }

  public reduce() {
    if (this.timerValue) {
      observableOf(null).pipe(delay(1000)).subscribe(() => {
        this.timerValue = this.timerValue - 1;
        this.reduce();
      })
    } else {
      this.timerGoingOn = false;
    }
  }
}
