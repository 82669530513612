import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AppService} from "../../../../shared/services/app.service";
import {AuthServiceService} from "../../../../core/services/authService/auth-service.service";
import {ApiParams, ApiResponse} from "../../../../shared/model/apiResponse";
import {urls} from '../../../../shared/services/constant';
import {map, filter, debounceTime, tap, switchAll, throttleTime, timestamp, delay} from 'rxjs/operators';
import {of as observableOf} from 'rxjs';
import {fromEvent} from 'rxjs';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
    public programOwner: string = '';
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public page_no: number = 1;
    public pageSize: number = 9;
    public showNextData = true;
    public productList: any = [];
    public resultsScrollObservable: any;
    public scrollSubscription: any;
    public data: any;
    public viewLoading: boolean = false;
    public config: SwiperConfigInterface;
    public bucketArray: any = [];
    public bucket_id: any;
    public activeIndex: any ;
    public noData: boolean = true;
    public show: boolean = false;
    constructor(private appService: AppService,
                private router: Router,public translate: TranslateService,
                private authService: AuthServiceService,
                private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.programOwner = params['id'];
        });
        this.config = {
            slidesPerView: 5,
            pagination: false,
            keyboard: false,
            navigation: true,
        };
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.getBucketList();

        observableOf(null).subscribe(() => {
             window.scrollTo(0, 0);
      
        });
      
          this.resultsScrollObservable = fromEvent(window, 'scroll');
          /**
           * Captures scrolling behavior within teh application, so we can implement infinite
           *  scroll or hiding elements once a certain amount of scrolling is done.
           */
          this.scrollSubscription = this.resultsScrollObservable
            .pipe(
              throttleTime(250),
              map((event: any) => {
      
                console.log(event)
                if (event) {
                  const elem = event.srcElement ? event.srcElement : event.target;
                  const srcElement = elem.body;
                  const clientHeight = srcElement ? srcElement.clientHeight : 0;
                  const el = document.scrollingElement || document.documentElement;
                  const scrollTop = el.scrollTop;
                  const position = scrollTop + clientHeight;
                  const height = srcElement ? srcElement.scrollHeight : 0;
                  const positionPercent = +((position / height).toFixed(2));
                  return positionPercent > .75;
                }
              }),
              filter((doSearch: boolean) => doSearch)
            )
            .subscribe((doSearch: boolean) => {
              if (this.showNextData) {
                this.page_no = this.page_no + 1;
                this.getProductByBucket(this.bucket_id,this.activeIndex-1);
              }
            });
     }
    ngOnDestroy(){
        this.scrollSubscription.unsubscribe();
      }
    getBucketList() {
        let params = {};
        this.apiParam.appJson = true;
         this.appService.post(urls.listBucket, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        if (response.result.bucket && response.result.bucket.length > 0) {
                            this.bucketArray = response.result.bucket;
                            this.bucket_id = this.bucketArray[0]._id
                            this.getProductByBucket(this.bucketArray[0]._id,0)
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    navigateToProductDetail(id) {
        this.router.navigate(['/program/product-details'], {queryParams: {id: id}});
    }

    getPrimaryProductImage(product) {

        let imgObj = product.images.filter(function (image) {
            return image.primary == true;
        });
        if(imgObj.length == 0){
            this.show == true;
        }
        else{
            return imgObj[0].url;
        }
      
    }
    getProductByBucket(id,index){
       this.bucket_id = id;
       if(this.activeIndex != index +1){
           this.page_no = 1;
           this.productList = [];
       }
       this.activeIndex = index + 1;
       this.apiParam.appJson = true;
       this.viewLoading = true;
       this.appService.get(urls.productListByBucket + `?programOwner=${this.programOwner}&bucket_id=${id}&page_no=${this.page_no}&page_size=${this.pageSize}`)
           .subscribe(
               (response: any) => {

                   this.showNextData = false;
                   this.viewLoading = false;
                   if (response['result'] && response['result'].products) {
                       this.data = response['result'].cartLength;
                       if (response['result'] &&  response['result'].products &&  response['result'].products.length >0) {
                           this.noData = true;
                           response['result'].products.forEach((ele, ind) => {
                            this.productList.push(ele);
                          });
                           this.showNextData = true;
                       }  
                       else{
                         if(this.productList.length == 0){
                            this.noData = false;  
                         }
                       }  
                   }
               },
               (error) => {
                   console.log(error);
               }
           );
    }
}
