import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { throttleTime, map, filter } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { urls } from 'src/app/shared/services/constant';
import { AppService } from 'src/app/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-child-progress',
  templateUrl: './child-progress.component.html',
  styleUrls: ['./child-progress.component.scss']
})
export class ChildProgressComponent implements OnInit {
  public showLoader: boolean;
  public programId: any;
  public childId: any;
  public navigationFrom: any;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public showNextData = true;
  public progressList: any = [];
  public resultsScrollObservable: any;
  public scrollSubscription: any;
  public skip = 1;
  public limit = 10;
  public owner_id :any;
  constructor(private activatedRoute: ActivatedRoute,private appService: AppService, 
    private router: Router,public translate: TranslateService) {
    this.showLoader = false; 
    this.activatedRoute.queryParams.subscribe(params => {
    this.navigationFrom = params.nav_from;
    this.programId = params.program_id;
    if(params.child_id){
      this.childId = params.child_id
    }
    if(params.owner_id){
      this.owner_id = params.owner_id
    }
  })
}
ngOnInit() {
  observableOf(null).subscribe(() => {
    window.scrollTo(0, 0);
    if(this.childId == undefined){
      this.getChildProgressReports(this.skip,this.limit);
    }
    else{
      this.getChildProgressForParent(this.skip,this.limit)
    }

  });

  this.resultsScrollObservable = fromEvent(window, 'scroll');
  /**
   * Captures scrolling behavior within teh application, so we can implement infinite
   *  scroll or hiding elements once a certain amount of scrolling is done.
   */
  this.scrollSubscription = this.resultsScrollObservable
    .pipe(
      throttleTime(250),
      map((event: any) => {
        if (event) {
          const elem = event.srcElement ? event.srcElement : event.target;
          const srcElement = elem.body;
          const clientHeight = srcElement ? srcElement.clientHeight : 0;
          const el = document.scrollingElement || document.documentElement;
          const scrollTop = el.scrollTop;
          const position = scrollTop + clientHeight;
          const height = srcElement ? srcElement.scrollHeight : 0;
          const positionPercent = +((position / height).toFixed(2));
          return positionPercent > .85;
        }
      }),
      filter((doSearch: boolean) => doSearch)
    )
    .subscribe((doSearch: boolean) => {
      if (this.showNextData) {
        this.skip = this.skip + 1; ;
        if(this.childId == undefined){
          this.getChildProgressReports(this.skip,this.limit);
        }
        else{
          this.getChildProgressForParent(this.skip,this.limit)
        }
      }
    });

}
ngOnDestroy() {
  this.scrollSubscription.unsubscribe();
}
  getChildProgressReports(skip,limit){
    this.showLoader = true;
    this.apiParam.appJson = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.childProgress + `${this.programId}?skip=${skip}&limit=${limit}`)
      .subscribe(
        (response: any) => {
          this.showLoader = false;
          this.showNextData = false;
          if (response['result'] && response['result'].list && response['result'].list.length) {
            let data: any = response['result'].list;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.progressList.push(ele);
              });
            }
            this.showNextData = true
          }
        },
        (error) => {
          console.log(error);
        }
      );
        
  }
  getChildProgressForParent(skip,limit){
    this.showLoader = true;
    this.apiParam.appJson = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.childProgress + `${this.programId}?childId=${this.childId}&skip=${skip}&limit=${limit}`)
      .subscribe(
        (response: any) => {
          this.showLoader = false;
          this.showNextData = false;
          if (response['result'] && response['result'].list && response['result'].list.length) {
            let data: any = response['result'].list;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.progressList.push(ele);
              });
            }
            this.showNextData = true
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  goToDetails() {
    this.router.navigate(['program/program-detail/' + this.programId], { queryParams: { nav_from: this.navigationFrom } });
  }
  goTofiles(){
    this.router.navigate(['/user/agency-programs'], { queryParams: { id: this.owner_id } })
  }
  goToEnrolled(){
    this.router.navigate(['/user/child-programs'],{queryParams: { id: this.childId }});
  }
  openReport(reportLink){
    window.open(reportLink, "_blank"); 
  }
}
