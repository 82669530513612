import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component'
import { FaqsComponent } from './component/faqs/faqs.component';
import { AuthGuardService } from './shared/services/auth-guard.service';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: './modules/landing/landing.module#LandingModule'
  },
  {
    path: 'about-us',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'app/about-us',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'privacy-policy',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'app/privacy-policy',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'terms-and-conditions',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'app/terms-and-conditions',
    loadChildren: './modules/info/info.module#InfoModule'
  },
  {
    path: 'user',
    loadChildren: './modules/user/user.module#UserModule',
  },
  {
    path: 'program',
    loadChildren: './modules/program/program.module#ProgramModule',
  },
  {
    path: 'settings',
    loadChildren: './modules/settings/settings.module#SettingsModule',
  },
  {
    path: 'forum',
    loadChildren: './modules/forum/forum.module#ForumModule',
  },
  {
    path: 'genie',
    loadChildren: './modules/genie/genie.module#GenieModule',
  },
  {
    path: 'social-share',
    loadChildren: './modules/social-share/social-share.module#SocialShareModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'wallet',
    loadChildren: './modules/wallet/wallet.module#WalletModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'parent',
    loadChildren: './modules/parent/parent.module#ParentModule',
  },
  {
    path: 'home',
    loadChildren: './modules/home/home.module#HomeModule',
  },
  {
    path: 'notifications',
    loadChildren: './modules/notifications/notifications.module#NotificationsModule',
    canActivate: [AuthGuardService],
  },
  { path: 'faqs', component: FaqsComponent },
  { path: 'app/faqs', component: FaqsComponent },
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }