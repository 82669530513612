import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppService {
    constructor(private http: HttpClient,
        private toastr: ToastrService) {
    }

    public post(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public careerpostFilter(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public careerGradeFilter(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public twitterAuth(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }

    public put(path, payload): Observable<any> {
        return new Observable(observer => {
            this.http.put(path, payload, { headers: this.getHeader() })
                .subscribe(
                    repsonse => { observer.next(repsonse); },
                    error => observer.error(error)
                );
        });
    }
    public InstituteaddBookmarks(path, payload): Observable<any> {
        return new Observable(observer => {
            this.http.put(path, payload, { headers: this.getHeader() })
                .subscribe(
                    repsonse => { observer.next(repsonse); },
                    error => observer.error(error)
                );
        });
    }
    public InstituteRemoveBookmarks(path,id) {
        return new Observable(observer => {
            const options = {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('LoggedIn'),
                }),
                body: {
                  location_id: id,
                },
              };
             this.http.delete(path, options)
                .subscribe(
                    repsonse => observer.next(repsonse),
                    error => observer.error(error)
                )
        });
    }
    public activityaddBookmarks(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public activityRemoveBookmarks(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public getphysicalcharacteristicsValue(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public careeraddBookmarks(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public careerRemoveBookmarks(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }

    public get(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getActivityBookmarksList(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getNotificationList(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getNotificationCount(path) 
    {
        this.showLoader(true);
        return new Observable(observer => {
        this.http.get(path, { headers: this.getHeader() })
        .subscribe(
        repsonse => {
        this.showLoader(false);
        observer.next(repsonse);
        },
        error => {
        observer.error(error);
        this.showLoader(false)
        }
        )
        });
    }
        
    public getCareerBookmarksList(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getSchoolBookmarksList(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public postPreferenceValue(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public getPrefernceData(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getInstitute(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public InstituteDetails(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }

    public getPolls(path) {
        this.showLoader(true);
        return new Observable(observer => {
           this.http.get(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }
    public getActivityValue(path, payload?): Observable<any> {
        this.showLoader(true);
        return new Observable(observer => {
            this.http.post(path, payload, { headers: this.getfiltterHeader() })
                .subscribe(
                    repsonse => {
                        observer.next(repsonse);
                        this.showLoader(false);
                    },
                    error => {
                        observer.error(error);
                        this.showLoader(false)
                    }
                );
        });
    }
    public delete(path,id) {
        return new Observable(observer => {
            const options = {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('LoggedIn'),
                }),
                body: {
                  location_id: id,
                },
              };
             this.http.delete(path, options)
                .subscribe(
                    repsonse => observer.next(repsonse),
                    error => observer.error(error)
                )
        });
    }

    public userLogin(path, contact) {
        return new Observable(observer => {
            this.http.post(path, contact)
                .subscribe(
                    repsonse => { observer.next(repsonse); },
                    error => observer.error(error)
                );
        });
    }

    public getHeader() {
        let token: any = localStorage.getItem('LoggedIn') ? localStorage.getItem('LoggedIn') : '';
        const headers: any = new HttpHeaders({ 'Authorization': token });
        return headers;
    }
    
    public getfiltterHeader() {
        let token: any = localStorage.getItem('LoggedIn') ? localStorage.getItem('LoggedIn') : '';
        const headers: any = new HttpHeaders({ 'Authorization': token , 'Content-Type': 'application/json'});
        return headers;
    }

    showLoader(showLoader: boolean) {
        document.getElementById('loading').style.display = showLoader ? 'block' : 'none';
    }

    public makeFileRequest(postData): Observable<any> {
        this.showLoader(true);
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            let url = postData.url;
            if (postData.fb_id) {
                formData.append('fb_id', postData.fb_id);
            }
            if (postData.gplus_id) {
                formData.append('gplus_id', postData.gplus_id);
            }
            if (postData.intstrgm_id) {
                formData.append('intstrgm_id', postData.intstrgm_id);
            }
            if (postData.password) {
                formData.append('password', postData.password);
            }
            if (postData.referral_code) {
                formData.append('referral_code', postData.referral_code);
            }
            if (postData.role) {
                formData.append('role', postData.role);
            }

            formData.append('profile_completed', 'false');
            formData.append('image', postData.image);
            formData.append('profile_pic', postData.profile_pic);
            formData.append('email', postData.email);
            formData.append('first_name', postData.first_name);
            formData.append('last_name', postData.last_name);
            formData.append('phone_no', postData.phone_no);
            formData.append('gender', postData.gender);
            formData.append('dob', postData.dob);
            formData.append('country_code',postData.country_code)
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    this.showLoader(false);
                    if (xhr.status === 200) {
                        observer.next(xhr.response);
                        observer.complete(xhr.response);
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', '*/*');
            xhr.setRequestHeader('Authorization', localStorage.getItem('LoggedIn'));
            xhr.send(formData);
        });
    }

    public twitterLogin(postData): Observable<any> {
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            let url = 'https://api.twitter.com/oauth2/token';
            formData.append('grant_type', 'client_credentials');
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('xhr.response', xhr.response);
                        observer.next(xhr.response);
                        observer.complete(xhr.response);
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            const credentials = `1A3EDFmu2VLuyjLTjYq6TzuEK:6LytYfA9lwwuGlM1BtsXjcWLq2zX9ehEXtfMQLoHhxDU9hoFGt`;
            btoa(credentials)
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
            xhr.setRequestHeader('Authorization', 'Basic ' + credentials);
            xhr.send(formData);
        });
    }

    public socialShare(postData): Observable<any> {
        this.showLoader(true);
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            let url = postData.url;
            formData.append('title', postData.title);
            formData.append('image', postData.image);
            if(postData.twit) {
              formData.append('twit', JSON.stringify(postData.twit));
            }
            if (postData.linkedin) {
              formData.append('linkedin', JSON.stringify(postData.linkedin));
            }
            formData.append('share_on', JSON.stringify(postData.share_on));
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('xhr.response', xhr.response);
                        observer.next(xhr.response);
                        observer.complete(xhr.response);
                        this.showLoader(false);
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', '*/*');
            xhr.setRequestHeader('Authorization', localStorage.getItem('LoggedIn'));
            xhr.send(formData);
        });
    }
    public minHeightGlobally() {
        var footer = document.getElementsByClassName('footer-wrap');
        var footerHeight = footer[0]?.clientHeight;
        var screenHeight = window.innerHeight;
        var totalHeight = screenHeight - footerHeight;
        
        var elements = document.getElementById('mainWrapper');
        if (elements !== undefined) {
            elements.style.minHeight = totalHeight + 'px';
        }
    }

    showError(msg) {
        const toast = document.getElementById('toast-container');
        if (toast) {
          toast.innerHTML = '';
        }
        this.toastr.error(msg, '', {
          timeOut: 2000
        });
      }
    
      showSuccess(msg) {
        const toast = document.getElementById('toast-container');
        if (toast) {
          toast.innerHTML = '';
        }
        this.toastr.success(msg, '', {
          timeOut: 1000
        });
      }
      showInfo(msg) {
        const toast = document.getElementById('toast-container');
        if (toast) {
          toast.innerHTML = '';
        }
        this.toastr.info(msg, '', {
          timeOut: 1000
        });
      }
}
