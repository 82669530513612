import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosComponent } from './videos.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component:VideosComponent
  }
];
@NgModule({
  imports: [
    CommonModule,RouterModule.forChild(routes),TranslateModule
  ],
  exports: [RouterModule],
  declarations: [VideosComponent]
})
export class VideosModule { }
