import { Component, OnInit, HostListener } from '@angular/core';
import { AuthServiceService } from '../../../core/services/authService/auth-service.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { PopupService } from '../../../core/services/popup.service';
import { AppService } from '../../services/app.service';
import { urls } from '../../services/constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    public showMenu: boolean = false;
    public showNav: boolean = false;
    public showdrop: boolean = false;
    public name: string = '';
    public isLogin: boolean = false;
    public profilePic: string = './assets/img/blank-image.png';
    public currentURL: any;
    public mobileApp: boolean = false;
    public mobile: boolean = false;
    public innerWidth: any;
    public modal: any;
    public agencyopen: boolean = false;
    public notiCount: any;
    public lat: any;
    public long: any;
    public isNull: any;
    public skip: number = 0;
    public limit: number = 400;
    public typeValue: number = 2;
    public CountBoolValue = false;
    public countValue = 0;
    public ValueToken: any;
    constructor(private authService: AuthServiceService, private appService: AppService,
        private popupService: PopupService, public translate: TranslateService,
        private router: Router) {
        this.currentURL = this.router.url.split("/")
        if (this.currentURL[1] && this.currentURL[1] == 'app') {
            this.mobileApp = true;
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 992) {
            this.mobile = true;
        }
        if (this.innerWidth > 992) {
            this.mobile = false;
        }
    }
    ngOnInit() {
        this.getNotificationCount();
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 992) {
            this.mobile = true;
        }
        else if (this.innerWidth > 992) {
            this.mobile = false;
        }
        this.lat = Number(localStorage.getItem('lat'));
        this.long = Number(localStorage.getItem('lng'));
        if (this.isLoggedIn) {
            this.checkNull();
        }
    }

    getDrop() {
        this.showdrop = !this.showdrop;
    }

    getNav() {
        this.showNav = !this.showNav;
    }

    getClass(type) {
        if (type == true) {
            return 'open';
        }
        if (type == false) {
            this.agencyopen = false;
        }
    }
    public get isLoggedIn() {
        this.profilePic = localStorage.getItem("profilePic") ? localStorage.getItem("profilePic") : "./assets/img/blank-image.png";
        this.name = localStorage.getItem("fName")
        return !!localStorage.getItem("LoggedIn");
    }

    checkUserLogin() {
        if (this.authService.isLoggednIn()) {
            this.router.navigate(["/program/map-list"]);
        } else {
            this.router.navigate(["/"]);
        }
    }
    getProfileDp() {
        let dummyImg = './assets/img/blank-image.png';
        let st: any;
        if (this.profilePic) {
            st = { background: `url(${this.profilePic || dummyImg})` };
        } else {
            st = { background: `url(${dummyImg})` };
        }
        return st;
    }

    getProfileMenu() {
        this.showMenu = !this.showMenu;
    }

    logout() {
        this.authService.logout();
    }

    goToSearchMap() {
        this.router.navigate(["/program/map-list"]);
    }

    goToSetting() {
        this.showMenu = false;
        this.router.navigate(["/settings/user-settings"]);
    }

    goToProfile() {
        this.showMenu = false;
        this.router.navigate(["/user/profile"]);
    }

    goToForum() {
        this.showMenu = false;
        this.router.navigate(["/forum/headings-list"]);
    }

    getToHome() {
        if (this.isLoggedIn) {
            this.router.navigate(['/home/list']);
        }
        else {
            this.router.navigate(["/"]);
        }
    }
    goToNotifications() {
        window.scroll(0, 0);
        this.router.navigate(["/notifications"]);
    }

    goToHome() {
        this.router.navigate(["/home/list"]);
    }

    getPopup(poupType): void {
        this.popupService.showPopup(poupType);
    }

    goToPost() {
        this.router.navigate(["/social-share"]);
    }
    checkNull() {
        const params = {
            'timestamp': 0,
            'limit': 5,
            'page_no': 1,
            premium_type: 2,
            program_type: 3,
            latitude: this.lat,
            longitude: this.long
        };
        this.appService.post(urls.nullCheck, params).subscribe(
            (response: any) => {
                if (response['result'] && response['result'].hasOwnProperty('isNull')) {
                    this.isNull = response['result'].isNull;
                    localStorage.setItem('isNull', this.isNull)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    goToBookmarks() {
        if (this.authService.isLoggednIn()) {
            this.router.navigate(["/program/bookmarks"]);
        }
        else {
            this.getPopup('onboarding');
        }
    }
    notification() {
        this.countValue = 0;
        this.CountBoolValue = false;
        this.router.navigate(['../notifications'])
    }
    getNotificationCount() {
        this.appService.getNotificationCount(urls.AlertsNotificationCountAPI).subscribe(
            (response: any) => {
                if (response.sc === 1) {
                    if (response.result.notCcount === 0) {
                        this.CountBoolValue = false;
                        this.countValue = response.result.notCcount;
                    }
                    else {
                        this.CountBoolValue = true;
                        this.countValue = response.result.notCcount;
                    }
                }
            });
        this.ValueToken = setInterval(() => {
            if (localStorage.getItem('LoggedIn')) {
                this.appService.getNotificationCount(urls.AlertsNotificationCountAPI).subscribe(
                    (response: any) => {
                        if (response.sc === 1) {
                            if (response.result.notCcount === 0) {
                                this.CountBoolValue = false;
                                this.countValue = response.result.notCcount;
                            }
                            else {
                                this.CountBoolValue = true;
                                this.countValue = response.result.notCcount;
                            }
                        }
                    });
            }
            else {
                clearInterval(this.ValueToken);
            }
        }, 1000 * 15);
    }
}
