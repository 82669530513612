import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { throttleTime, map, filter } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  public navigationFrom: any;
  public program_id: any;
  public modal: any;
  public btn: any;
  public viewLoading: boolean = false;
  public skip = 1;
  public limit = 10;
  public resultsScrollObservable: any;
  public showNextData = true;
  public scrollSubscription: any;
  public videosList: any = [];
  public videoDetail: any;
  public embededVideo: boolean = false;
  public embededCode: any = '';
  public videoID:any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public translate: TranslateService,
    private appService: AppService, private sanitizer: DomSanitizer) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.navigationFrom = params.nav_from;
      this.program_id = params.program_id
      if(params.videoId){
        this.videoID = params.videoId
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    observableOf(null).subscribe(() => {
      window.scrollTo(0, 0);
      this.getVideoList(this.skip, this.limit);
    });

    this.resultsScrollObservable = fromEvent(window, 'scroll');
    /**
     * Captures scrolling behavior within teh application, so we can implement infinite
     *  scroll or hiding elements once a certain amount of scrolling is done.
     */
    this.scrollSubscription = this.resultsScrollObservable
      .pipe(
        throttleTime(250),
        map((event: any) => {
          if (event) {
            const elem = event.srcElement ? event.srcElement : event.target;
            const srcElement = elem.body;
            const clientHeight = srcElement ? srcElement.clientHeight : 0;
            const el = document.scrollingElement || document.documentElement;
            const scrollTop = el.scrollTop;
            const position = scrollTop + clientHeight;
            const height = srcElement ? srcElement.scrollHeight : 0;
            const positionPercent = +((position / height).toFixed(2));
            return positionPercent > .85;
          }
        }),
        filter((doSearch: boolean) => doSearch)
      )
      .subscribe((doSearch: boolean) => {
        if (this.showNextData) {
          this.skip = this.skip + 1;
          this.getVideoList(this.skip, this.limit);
        }
      });
  }
  getVideoList(skip, limit) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.videoList + this.program_id + `?skip=${skip}&limit=${limit}`).subscribe((response) => {
      this.viewLoading = false;
      this.showNextData = false;
      if (response['result'] && response['result'].list) {
        let data: any = response['result'].list;
        if (data && data.length > 0) {
          data.forEach((ele, ind) => {
            this.videosList.push(ele);
          });
        }
        this.showNextData = true;
        if(this.videoID != undefined){
          this.open('video',this.videoID)
        }
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }
  goToDetails() {
    this.router.navigate(['program/program-detail/' + this.program_id], { queryParams: { nav_from: this.navigationFrom } });
  }
  open(pop: any, id) {
    this.videoDetail = undefined;
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
    this.getVideoDetail(id)
  }
  getVideoDetail(id) {
    this.viewLoading = true;
    this.appService.get(urls.videoDetail + id).subscribe((response) => {
      this.viewLoading = false;
      if (response['result']) {
        this.videoDetail = response['result']
        let youTube = this.videoDetail.url.includes(environment.youtubeLink);
        if (youTube) {
          this.embededVideo = true;
          this.embededCode = environment.youtubeEmbedLink + this.videoDetail.url.slice(17);
        } else {
          youTube = this.videoDetail.url.includes(environment.youtubeWatchLink);
          if (youTube) {
            this.embededVideo = true;
            let youtubeUrl = (this.videoDetail.url.slice(32).split('&'))
            this.embededCode = environment.youtubeEmbedLink + youtubeUrl[0];
          }
        }
      }
    })
  }
  getSafeUrl(data) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
  closemodal() {
    this.videoDetail = undefined;
    this.embededCode = ""
    this.embededVideo = false;
    this.btn = document.getElementsByClassName("close")[0];
    this.modal.style.display = "none";
    if(this.videoID != undefined){
      const params = { ...this.activatedRoute.snapshot.queryParams };
        delete params.videoId;
        this.router.navigate([], { queryParams: params });
    }
  }
  bookmark(id, bookmark) {
    this.viewLoading = true
    let body = {
      _id: id,
      isBookmarked: bookmark
    }
    this.appService.post(urls.bookmarkVideo, body)
      .subscribe((response: any = {}) => {
        if (response.sc == 1 && bookmark == true) {
          this.appService.showSuccess(response['result'].message);
          this.videoDetail['isBookmarked'] = bookmark
        }
        else if (response.sc == 1 && bookmark == false) {
          this.appService.showSuccess(response['result'].message);
          this.videoDetail['isBookmarked'] = bookmark
        }
        else if (response.sc == 0) {
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
      })
  }
  like(id, like) {
    this.viewLoading = true
    let body = {
      _id: id,
      isLiked: like
    }
    this.appService.post(urls.likeVideo, body)
      .subscribe((response: any = {}) => {
        if (response.sc == 1 && like == true) {
          this.appService.showSuccess('Video Liked successfully');
          this.videoDetail['isLiked'] = like
        }
        else if (response.sc == 1 && like == false) {
          this.videoDetail['isLiked'] = like
        }
        else if (response.sc == 0) {
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
      })
  }
  unlike(id, unlike) {
    this.viewLoading = true
    let body = {
      _id: id,
      isDisliked: unlike
    }
    this.appService.post(urls.unlikeVideo, body)
      .subscribe((response: any = {}) => {
        if (response.sc == 1 && unlike == true) {
          this.appService.showSuccess('Video disliked successfully');
          this.videoDetail['isDisliked'] = unlike
        }
        else if (response.sc == 1 && unlike == false) {
          this.videoDetail['isDisliked'] = unlike
        }
        else if (response.sc == 0) {
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
      })
  }
  flagVideo(id) {
    this.viewLoading = true;
    let body = {
      videoId: id
    }
    this.appService.post(urls.reportVideo, body).subscribe((response: any = {}) => {
      if (response.sc == 1) {
        this.appService.showSuccess(response['result'].message)
      }
      else {
        this.appService.showError(response['error'].msg)
      }
      this.viewLoading = false;
    })
  }
}
