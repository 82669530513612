import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WalletComponent} from './component/wallet/wallet.component';
import {WalletHistoryComponent} from './component/wallet-history/wallet-history.component';
import {DistributeRpComponent} from './component/distribute-rp/distribute-rp.component';
import {DonateRpComponent} from './component/donate-rp/donate-rp.component';
import {LeaderboardComponent} from './component/leaderboard/leaderboard.component';
import { SavedCardsComponent } from './component/saved-cards/saved-cards.component';

const routes: Routes = [{
  path: '',
  component: WalletComponent
},
  {
    path: 'wallet-history',
    component: WalletHistoryComponent
  },
  {
    path: 'leader-board',
    component: LeaderboardComponent
  },
  {
    path: 'distribute-reward-point',
    component: DistributeRpComponent
  },
  {
    path: 'donate-reward-point',
    component: DonateRpComponent
  },
  {
    path: 'saved-cards',
    component: SavedCardsComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule {
}
