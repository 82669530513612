import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './components/home/home.component';
import { ProgramlistComponent } from './components/programlist/programlist.component';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
    imports: [
        CommonModule,
        HomeRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMyDatePickerModule.forRoot(),
        SwiperModule,
        NgxSliderModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        TranslateModule
    ],
    declarations: [HomeComponent, ProgramlistComponent],
    exports: [HomeComponent],
    providers: [
      {
        provide: SWIPER_CONFIG,
        useValue: DEFAULT_SWIPER_CONFIG
      },
      ScreenTrackingService,
      UserTrackingService
    ]
})

export class HomeModule { }
