import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MapListComponent } from './map-list.component';
import { SharedModule } from '../../../../shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: MapListComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, SharedModule,
    NgxSliderModule,
    TranslateModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey
    })
  ],
  exports: [RouterModule,],
  declarations: [MapListComponent],
  providers: []
})
export class MapListModule { }
