import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RewardsCenterComponent } from './rewards-center.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: RewardsCenterComponent
  }
];
@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes),TranslateModule
  ],
  declarations: [RewardsCenterComponent]
})
export class RewardsCenterModule { }
