import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PopupService {

    private subject = new Subject<any>();

    private popupSubject = new Subject<any>();

    public userDetails: any = {};
    public FacebookDetails: any = {};

    isPopupVisible: boolean = false;
    popupVisibilityChange: Subject<boolean> = new Subject<boolean>();

    constructor() {
      this.popupVisibilityChange.subscribe((value) => {
        this.isPopupVisible = value
      });
    }

    togglePopupVisibility(bool) {
      this.popupVisibilityChange.next(bool);
    }

    showPopup(popup: string) {
        this.popupSubject.next(popup);

    }
    hidePopup(popup: string) {
        this.popupSubject.next(popup);
    }

    getPopup(): Observable<any> {
        return this.popupSubject.asObservable();
    }
    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
