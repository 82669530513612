import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from "../../../../shared/services/app.service";
import { PopupService } from "../../../../core/services/popup.service";
import { urls, toastMessage } from '../../../../shared/services/constant';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-add-locations',
  templateUrl: './add-locations.component.html',
  styleUrls: ['./add-locations.component.scss']
})
export class AddLocationsComponent implements OnInit {

  constructor(private appService: AppService,
    private popupService: PopupService,public translate: TranslateService) { }
  public isAddButtonClicked: boolean = false;
  public homeString: string;
  public homeLocation: any;
  public addressId: any;
  public location1Address: string;
  public location2Address: string;
  public location3Address: string;
  public location1Location: any;
  public location2Location: any;
  public location3Location: any;
  public queryField: FormControl = new FormControl();
  public schoolList: any = [];
  public schoolListSorting = [];
  public schoolString: string;
  public schoolLocation: any;
  public path: any;
  public displayClnt: boolean = false;
  public isAddButtonClickedForHome: boolean = false;
  public isAddButtonClickedForSchool: boolean = false;
  public isAddLocation1: boolean = false;
  public isAddLocation2: boolean = false;
  public isAddLocation3: boolean = false;
  public savedPlaceLocation: string = '';
  public body: any;
  public userSettings2: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings1: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings2: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings3: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  @ViewChild("placesRef",{static: false}) placesRef : GooglePlaceDirective;
  ngOnInit() {
  }
  searchNameForHome(address, searchLocationLat,searchLocationLon) {
    this.homeString = address;
    this.homeLocation = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallback1(selectedData: any) {
    console.log(selectedData.geometry.location.lat())
    // if (selectedData.response == true) {
      this.searchNameForHome(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.homeString = null;
    // }
  }
  SaveLocationForHome() {
    if (this.homeString) {
      this.isAddButtonClickedForHome = false;
      this.isAddButtonClicked = false;
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  searchNameForFirstLocation(address, searchLocationLat,searchLocationLon) {
    this.location1Address = address;
    this.location1Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation1(selectedData: any) {
    console.log(selectedData)
    // if (selectedData.response == true) {
      this.searchNameForFirstLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.location1Address = null;
    // }
  }
  SaveLocation1() {
    if (this.location1Address) {
      this.isAddLocation1 = false;
      this.isAddButtonClicked = false;
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  //For 2nd Location
  searchNameForSecondLocation(address, searchLocationLat,searchLocationLon) {
    this.location2Address = address;
    this.location2Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation2(selectedData: any) {
    // if (selectedData.response == true) {
      this.searchNameForSecondLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.location2Address = null;
    // }
  }
  SaveLocation2() {
    if (this.location2Address) {
      this.isAddLocation2 = false;
      this.isAddButtonClicked = false;
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  //For 3rd Location
  searchNameForThirdLocation(address, searchLocationLat,searchLocationLon) {
    this.location3Address = address;
    this.location3Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation3(selectedData: any) {
    if (selectedData.response == true) {
      this.searchNameForThirdLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
      this.location3Address = null;
    }
  }
  SaveLocation3() {
    if (this.location3Address) {

      this.isAddLocation3 = false;
      this.isAddButtonClicked = false;
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  openModalBox(name) {
    this.isAddButtonClicked = true;
    if (name == 'Home') {
      this.isAddButtonClickedForHome = true;
      if (this.homeString) {
        this.userSettings2 = {
          "inputString": this.homeString
        };
      }
    }
    if (name == 'Location1') {
      this.isAddLocation1 = true;
      if (this.location1Address) {
        this.locationSettings1 = {
          "inputString": this.location1Address
        };
      }
    }
    if (name == 'Location2') {
      this.isAddLocation2 = true;
      if (this.location2Address) {
        this.locationSettings2 = {
          "inputString": this.location2Address
        };
      }
    }
    if (name == 'Location3') {
      this.isAddLocation3 = true;
      if (this.location3Address) {
        this.locationSettings3 = {
          "inputString": this.location3Address
        };
      }
    }
  }

  backToAddPlaces() {
    this.isAddButtonClicked = false;
    this.isAddButtonClickedForHome = false;
    this.isAddLocation1 = false;
    this.isAddLocation2 = false;
    this.isAddLocation3 = false;
  }
  SaveMyPlaces() {
    let address = [];
    if (this.homeString) {
      address.push({
        'address': this.homeString,
        'lat': this.homeLocation.lat,
        'long': this.homeLocation.lng,
        'address_type': '1'
      });
    }
    if (this.schoolString) {
      address.push({
        'address': this.schoolLocation,
        'address_type': '2'
      });
    }
    if (this.location1Address) {
      address.push({
        'address': this.location1Address,
        'lat': this.location1Location.lat,
        'long': this.location1Location.lng,
        'address_type': '3'
      });
    }
    if (this.location2Address) {
      address.push({
        'address': this.location2Address,
        'lat': this.location2Location.lat,
        'long': this.location2Location.lng,
        'address_type': '3'
      });
    }
    if (this.location3Address) {
      address.push({
        'address': this.location3Address,
        'lat': this.location3Location.lat,
        'long': this.location3Location.lng,
        'address_type': '3'
      });
    }
    if (address.length > 0) {
      let data = {
        'addresses': address
      };
      this.appService.post(urls.addLocation, data)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.getPopup('');
              this.popupService.togglePopupVisibility(true);
            }
            else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }
}
