import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../shared/services/app.service';
import { urls } from '../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../shared/model/apiResponse';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  public activeIndex = 0;
  public currentURL = "";
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public description: any;
  public showNoResultFound: boolean = false;
  public type: any = '';
  public title: string;
  public faqArray: any = [];
  constructor(private appService: AppService,
    private router: Router,
    private titles: Title, private meta: Meta,
    private activatedRoute: ActivatedRoute) {
    this.currentURL = this.router.url.split("/").pop()
    this.activatedRoute.queryParams.subscribe(params => {
      this.type = params['type'];
      if (this.type == 'refersh') {
        let clean_uri = location.protocol + "//" + location.host + location.pathname;
        window.history.replaceState({}, document.title, clean_uri);
      }
    });
  }

  ngOnInit() {
    this.getPageInfo();
    this.appService.minHeightGlobally(); this.titles.setTitle('FAQ about Summer Jobs, Volunteer, Internship Programs for Teens Chicago');
    this.meta.updateTag({ name: 'description', content: 'Check out our FAQ and get registered to find out summer jobs for teens, internships for high school students, volunteer opportunities for teen in Chicago.' });
    this.meta.updateTag({ name: 'keywords', content: 'Summer Jobs for Teens Chicago, Jobs For Teens Chicago, Summer Internship Opportunities, Summer Internship Programs For Teens, Internships for High School Students, Teen Summer Internships Chicago, Volunteer Opportunities for Teens, Volunteering for Teens Chicago' });
  }
  public get isLoggedIn() {
    return !!localStorage.getItem("LoggedIn");
  }
  getPageInfo() {
    this.apiParam.url = urls.getFaq;
    this.faqApiMethod();
  }

  onSearchChange(data) {
    this.showNoResultFound = false;
    if (data.length >= 3) {
      this.searchFaqList(data);
    } else {
      this.getPageInfo();
    }
  }

  searchFaqList(data) {
    this.faqArray = [];
    this.apiParam.url = urls.faqSearch + data;
    this.faqApiMethod();
  }

   faqApiMethod(){
    this.appService.get(this.apiParam.url)
    .subscribe(
      (response: any) => {
        this.apiResponse = response;
        if (this.apiResponse && this.apiResponse.sc == 1) {
          this.faqArray = [];
          let AccData = this.apiResponse.result.faq;
          if (AccData && AccData.length > 0) {
            AccData.forEach((ele, ind) => {
              let obj = { "ques": ele.ques, "ans": ele.ans, "id": ele._id }
              this.faqArray.push(obj)
            });
          } else {
            this.showNoResultFound = true;
          }
        } 
      })
   }

  ngOnDestroy() {
    this.titles.setTitle('After School Programs for Teens Chicago � Art, Education, Jobs, Sports');
    this.meta.updateTag({ name: 'description', content: 'Spotivity empowers Chicago teens & parents with meaningful after school activities like sports, mentoring, tutoring, jobs, internships, volunteer & summer camps.' });
    this.meta.updateTag({ name: 'keywords', content: 'After School Activities for Teenagers, After School Activities for Teens, After School Programs Chicago, After School Sports Program, Education Programs for Teens Chicago' });
  }

}