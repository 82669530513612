import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SetPasswordComponent } from './set-password.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: SetPasswordComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule,TranslateModule
  ],
  exports: [RouterModule, SetPasswordComponent],
  declarations: [SetPasswordComponent],
  providers: []
})
export class SetPasswordModule { }
