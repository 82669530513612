import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { TouchScrollDirective } from './../shared/directive/touch-scroll.directive';
import { LandingHeaderComponent } from './component/landing-header/landing-header.component';
import { PopupManagementComponent } from './component/popup-management/popup-management.component';
import { FilterPipe } from './pipes/filterPipe';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { LinkifyPipe } from '../modules/forum/pipe/linkfy-pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [HeaderComponent, FooterComponent, TouchScrollDirective, LandingHeaderComponent, PopupManagementComponent, FilterPipe, PageNotFoundComponent,LinkifyPipe],
  exports: [HeaderComponent,FooterComponent,TouchScrollDirective,LandingHeaderComponent,PopupManagementComponent, FilterPipe ,LinkifyPipe]
})
export class SharedModule { }
