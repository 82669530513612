import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';

@Component({
  selector: 'app-create-transcript',
  templateUrl: './create-transcript.component.html',
  styleUrls: ['./create-transcript.component.scss']
})
export class CreateTranscriptComponent implements OnInit {
  public navigationFrom:any;
  public totalAmount:any;
  public viewLoading: boolean = false;
  public paymentRequired: boolean = true;
  public generateTrans: boolean = true;
  public modal: any;
  public btn: any;
  public paymentType: any;
  public totalCoin: number = null;
  constructor(private router: Router,private appService: AppService,public translate: TranslateService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.totalAmount = '$49.99';
    this.checkForPayment();
    this.getTotal();
  }
  payment(){
    if(this.paymentType == 2){
          if(this.totalCoin < 250){
            this.appService.showError('You dont have sufficient reward points');
          }
          else{
            this.navigationFrom = 'create-transcript';
            this.totalAmount = '$25';
            localStorage.setItem('pay',this.totalAmount)
            this.router.navigate(['/program/card-list'], {queryParams: {nav_from: this.navigationFrom}});
            }
    }
    if(this.paymentType == 3){
      if(this.totalCoin < 500){
        this.appService.showError('You dont have sufficient reward points');
      }
      else{
        this.navigationFrom = 'create-transcript';
        this.generate(this.paymentType)
        }
      }
    if(this.paymentType == 1){
        this.navigationFrom = 'create-transcript';
        localStorage.setItem('pay',this.totalAmount)
        this.router.navigate(['/program/card-list'], {queryParams: {nav_from: this.navigationFrom}});
    }

  }
  onChangeType(type){
    this.paymentType = type;
    localStorage.setItem('type',type)
  }
  getTotal() {
    this.appService.post(urls.getTotalPoint)
      .subscribe((res: any) => {
        this.totalCoin = res.result.Total_Coin;
      })
  }
  checkForPayment(){
       this.viewLoading = true;
       this.appService.get(urls.checkForTransPay).subscribe((res:any)=>{
            this.viewLoading = false;
            if(res['result'].hasOwnProperty('paymentRequired')){
              this.paymentRequired = res['result'].paymentRequired
            }
            else if(res['result'].hasOwnProperty('canGenerateTranscipt')){
              this.generateTrans = res['result'].canGenerateTranscipt
              this.appService.showError(res['result'].message)
            }
       })
  }
  openModal(pop: any) {
    this.modal = document.getElementById(pop);
    this.modal.style.display = "block";
}
closemodal() {
  this.btn = document.getElementsByClassName("close")[0];
  this.modal.style.display = "none";
}
generate(type){
     this.viewLoading = true;
     let formData = {
      'mode': type,
    }
     this.appService.post(urls.transcriptPayment,formData)
        .subscribe(
          (response: any) => {
            console.log(response)
            if (response.sc == 1) {
              this.appService.showSuccess(response['result'].message.message);
              this.router.navigate(['/user/profile']);
            }
            if (response.sc == 0) {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
}
}
