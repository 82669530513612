import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../../../core/services/popup.service';
import { AppService } from '../../../../shared/services/app.service';
import { urls, toastMessage } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-interest',
    templateUrl: './interest.component.html',
    styleUrls: ['./interest.component.scss']
})
export class InterestComponent implements OnInit {
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public interestArray: any = [];
    public interestId: any = [];
    public addActive: any = -1;
    public activeObj: any = {}
    public dispValue:boolean = true;
    constructor(private popupService: PopupService,
        private appService: AppService,
        private toastr: ToastrService,
        public translate: TranslateService) {
    }

    ngOnInit() {
        let params = {};
        this.apiParam.appJson = true;
        this.appService.post(urls.listBucket, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc == 1) {
                        this.interestArray = [];
                        if (response.result.bucket && response.result.bucket.length > 0) {
                            response.result.bucket.forEach((ele, ind) => {
                                let obj = {
                                    "icon": ele.icon,
                                    "name": ele.name,
                                    "position": ele.position,
                                    "id": ele._id,
                                }
                                this.interestArray.push(obj)
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    getPopup(test): void {
        this.popupService.showPopup(test);
    }

    getInterestIcon(img) {
        let st: any;
        st = { background: `url(${img})` };
        return st;
    }

    addInterest(id, i) {
        const index: number = this.interestId.indexOf(id);
        if (index !== -1) {
            this.addActive = -1;
            this.activeObj[i] = false;
            this.interestId.splice(index, 1);
        } else {
            this.addActive = i;
            this.activeObj[i] = true;
            this.interestId.push(id);
        }
        if(this.interestId.length>0)
        {
            this.dispValue = false;
        }
        else
        {
            this.dispValue = true;
        }
    }

    saveInterest() {
        if (this.interestId.length > 0) {
            let params = {
                "bucket_ids": JSON.stringify(this.interestId)
            };
            this.apiParam.appJson = true;
            this.appService.post(urls.addBucket, params)
                .subscribe(
                    (response: any) => {
                        if (response && response.sc == 1) {
                            this.getPopup('ethnicity');
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        } else {
            this.appService.showError(toastMessage.interestEmpty);
        }


    }
    onSkip() {
        this.getPopup('ethnicity');
    }
}
