import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChildServiceService {

  childInfo:any;
  successMessInfo:any;
  errorMessInfo:any;

  checkWhetherParentChildExists:any = false;
  messageInfor:any;
  constructor() { }

}
