import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { throttleTime, map, filter } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { urls } from 'src/app/shared/services/constant';
import { AppService } from 'src/app/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-child-programs',
  templateUrl: './child-programs.component.html',
  styleUrls: ['./child-programs.component.scss']
})
export class ChildProgramsComponent implements OnInit {
  public viewLoading: boolean;
  public childId: any;
  public navigationFrom: any;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public showNextData = true;
  public programsList: any = [];
  public resultsScrollObservable: any;
  public scrollSubscription: any;
  public skip = 1;
  public limit = 10;
  constructor(private activatedRoute: ActivatedRoute,private appService: AppService, 
    private router: Router,public translate: TranslateService) {
    this.viewLoading = false; 
    this.activatedRoute.queryParams.subscribe(params => {
    this.childId = params.id
  })
}
ngOnInit() {
  observableOf(null).subscribe(() => {
    window.scrollTo(0, 0);
    this.getChildPrograms(this.skip,this.limit);
  });

  this.resultsScrollObservable = fromEvent(window, 'scroll');
  /**
   * Captures scrolling behavior within teh application, so we can implement infinite
   *  scroll or hiding elements once a certain amount of scrolling is done.
   */
  this.scrollSubscription = this.resultsScrollObservable
    .pipe(
      throttleTime(250),
      map((event: any) => {
        if (event) {
          const elem = event.srcElement ? event.srcElement : event.target;
          const srcElement = elem.body;
          const clientHeight = srcElement ? srcElement.clientHeight : 0;
          const el = document.scrollingElement || document.documentElement;
          const scrollTop = el.scrollTop;
          const position = scrollTop + clientHeight;
          const height = srcElement ? srcElement.scrollHeight : 0;
          const positionPercent = +((position / height).toFixed(2));
          return positionPercent > .85;
        }
      }),
      filter((doSearch: boolean) => doSearch)
    )
    .subscribe((doSearch: boolean) => {
      if (this.showNextData) {
        this.skip = this.skip + 1;
        this.getChildPrograms(this.skip,this.limit);
      }
    });

}
ngOnDestroy() {
  this.scrollSubscription.unsubscribe();
}
getChildPrograms(skip,limit){
    this.viewLoading = true;
    this.apiParam.appJson = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.childPrograms + `${this.childId}?skip=${skip}&limit=${limit}`)
      .subscribe(
        (response: any) => {
          this.viewLoading = false;
          this.showNextData = false;
          if (response['result'] && response['result'].list && response['result'].list.length) {
            let data: any = response['result'].list;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.programsList.push(ele);
              });
            }
            console.log(this.programsList)
            this.showNextData = true
          }
        },
        (error) => {
          console.log(error);
        }
      );
        
  }
  goToReports(id) {
    this.navigationFrom = "enrolledPrograms"
    this.router.navigate(['/program/child-progress'], { queryParams: { 'nav_from': this.navigationFrom , 'program_id': id ,'child_id' : this.childId} });
  }
  showSeason(ele) {
    let a = '';
    let b = '';
    let c = '';
    let d = '';
    if (ele.spring_season === 1) {
        a = 'Spring, ';
    }
    if (ele.winter_season === 1) {
        b = 'Winter, ';
    }
    if (ele.fall_season === 1) {
        c = 'Fall, ';
    }
    if (ele.summer_season === 1) {
        d = 'Summer,';
    }
    let season = '';
    season = a + b + c + d;
    if (d && d.length)
        season = season.slice(0, -1);
    else
        season = season.slice(0, -2);
    return season;
}
showBuckets(ele) {
  let buckets = '';
  for (let i = 0; i < ele.bucketData.length; i++) {
      buckets = buckets + ele.bucketData[i].name + ', ';
  }
  if (buckets && buckets.length)
      buckets = buckets.slice(0, -2);
  return buckets;
}
}
