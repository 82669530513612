import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../shared/services/app.service';
import { PopupService } from '../../../../core/services/popup.service';
import { urls } from '../../../../shared/services/constant';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

@Component({
    selector: 'app-bookmarks',
    templateUrl: './bookmarks.component.html',
    styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
    public programArr: any = [];
    public videoArr: any = [];
    public newsArr: any = [];
    public genieArr: any = [];
    public genieActivity: any = [];
    public genieSchool: any = [];
    public genieCareer: any = [];
    public keywordArr:any=[];
    private isUserLoggedIn = true;
    public indexvariable = 0;
    public showLoader: boolean;
    public navigationFrom: any;

    public config: SwiperConfigInterface;


    constructor(private router: Router,
        private appService: AppService,public translate: TranslateService,
        private popupService: PopupService) {
        window.scroll(0, 0)
        this.showLoader = false;
        if (screen.width < 993) {
            this.config = {
                slidesPerView: 1,
                pagination: false,
                keyboard: false,
                navigation: true,
            };
        } else {
            this.config = {
                slidesPerView: 2,
                pagination: false,
                keyboard: false,
                navigation: true,
            };
        }
    }

    ngOnInit() {
        this.getBookmarksList();
        this.isLoggedIn();
        // Analytics.record({ name: 'Bookmark Visited' });
    }

    viewAll(type) {
        this.router.navigate(['program/favorites'], { queryParams: { 'type': type } })
    }

    goToVideo(id) {
        if (this.isUserLoggedIn) {
            this.navigationFrom = "home"
            this.router.navigate(['/program/videos'], { queryParams: { 'nav_from': this.navigationFrom, 'program_id': id } })
        } else {
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if (this.popupService.isPopupVisible) {
                    this.popupService.togglePopupVisibility(false);
                    this.router.navigate(['/']);
                }
            });
        }
    }

    getBookmarksList() {
        this.showLoader = true;
        this.appService.get(urls.bookmarks).subscribe(
            (response: any) => {
                console.log(response);
                this.showLoader = false;
                if (response['result']) {
                    if (response['result'].programs) {
                        this.programArr = response['result'].programs
                    }
                    if (response['result'].videos) {
                        this.videoArr = response['result'].videos
                    }
                    if (response['result'].newsroom) {
                        this.newsArr = response['result'].newsroom
                    }
                    if (response['result'].genie) {
                        this.genieArr = response['result'].genie
                    }
                    if(response['result'].keyword){
                        this.keywordArr = response['result'].keyword
                    }
                    if(response['result'].activity){
                        this.genieActivity = response['result'].activity
                    }
                    if(response['result'].school){
                        this.genieSchool = response['result'].school
                    }
                    if(response['result'].career){
                        this.genieCareer = response['result'].career
                    }
                }
            },
            (error) => {
                this.showLoader = false;
                console.log(error);
            }
        );
    }

    showSeason(ele) {
        if (ele) {
            let a = '';
            let b = '';
            let c = '';
            let d = '';
            if (ele.spring_season === 1) {
                a = 'Spring, ';
            }
            if (ele.winter_season === 1) {
                b = 'Winter, ';
            }
            if (ele.fall_season === 1) {
                c = 'Fall, ';
            }
            if (ele.summer_season === 1) {
                d = 'Summer,';
            }
            let season = '';
            season = a + b + c + d;
            if (d && d.length) {
                season = season.slice(0, -1);
            } else {
                season = season.slice(0, -2);
            }
            return season;
        }
    }

    isLoggedIn() {
        const user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true;
        } else {
            this.isUserLoggedIn = false;
        }
    }

    programDetail(id) {
        if (this.isUserLoggedIn) {
            this.navigationFrom = "home"
            this.router.navigate(['program/program-detail/' + id], { queryParams: { nav_from: this.navigationFrom } });
        } else {
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if (this.popupService.isPopupVisible) {
                    this.popupService.togglePopupVisibility(false);
                    this.router.navigate(['/']);
                }

            });
        }
    }

    getPopup(test): void {
        this.popupService.showPopup(test);
    }

    showBuckets(ele) {
        let buckets = '';
        for (let i = 0; i < ele.buckets.length; i++) {
            buckets = buckets + ele.buckets[i].name + ', ';
        }
        if (buckets && buckets.length) {
            buckets = buckets.slice(0, -2);
        }
        return buckets;
    }

    increaseIndexValue() {
        if (this.indexvariable < 3) {
            this.indexvariable = this.indexvariable + 1;
        }
    }

    decreaseIndexValue() {
        if (this.indexvariable > 0) {
            this.indexvariable = this.indexvariable - 1;
        }
    }

}
