import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ActivatedRoute, Router } from '@angular/router';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)
@Component({
  selector: 'app-leaderboard',
  templateUrl: 'leaderboard.component.html',
  styleUrls: ['leaderboard.component.css']
})

export class LeaderboardComponent implements OnInit {
  public list1: any;
  public list2: any;
  public list3: any;
  public profile_completed = false;
  public showContact = false;
  public schoolExist = false;
  public childId: string = '';
  public role: any;
  public navigationFrom: any;
  constructor(private appService: AppService, private activatedRoute: ActivatedRoute, 
    private router: Router,public translate: TranslateService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.childId = params['id'];
    })
  }
  getLeaderBoardTab1(pageNo, pageSize, id) {

    let url = '';
    if (this.childId) {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=0' + '&child_id=' + id;
    }
    else {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=0'
    }
    this.appService.get(urls.userLeaderBoard + url).subscribe(value => {
      // @ts-ignore
      this.list1 = value.result.list;
      // @ts-ignore
      this.showContact = value.result.contact_sync;
      if (this.showContact) {
        document.getElementById('contact').style.display = 'block';
      } else {
        document.getElementById('school').style.display = 'block';
      }
    });
  }

  getLeaderBoardTab2(pageNo, pageSize, id) {

    let url = '';
    if (this.childId) {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=1' + '&child_id=' + id;
    }
    else {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=1'
    }
    this.appService.get(urls.userLeaderBoard + url).subscribe(value => {
      // @ts-ignore
      this.list2 = value.result.list;
    });
  }

  getLeaderBoardTab3(pageNo, pageSize, id) {

    let url = '';
    if (this.childId) {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=2' + '&child_id=' + id;
    }
    else {
      url = '?page=' + pageNo + '&page_size=' + pageSize + '&tab=2'
    }
    this.appService.get(urls.userLeaderBoard + url).subscribe(value => {
      // @ts-ignore
      this.list3 = value.result.list;
    });
  }

  openTab(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tab-pane');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('nav-link');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(cityName).style.display = 'block';
    evt.currentTarget.className += ' active';
  }

  ngOnInit() {
    window.scroll(0, 0)
    this.role = localStorage.getItem("loginRole");
    this.appService.get(urls.getProfile).subscribe((response: any) => {
      this.profile_completed = response.result.user.profile_completed;
    });
    this.getLeaderBoardTab1(1, 100, this.childId);
    this.getLeaderBoardTab2(1, 100, this.childId);
    this.getLeaderBoardTab3(1, 100, this.childId);
    // Analytics.record({ name: 'Leaderboard' });
  }

  gotoWalletHistory() {
    this.navigationFrom = "leadership";
    this.router.navigate(['../wallet/wallet-history'], { queryParams: { nav_from: this.navigationFrom } });
  }

}
