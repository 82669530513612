import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WalletRoutingModule} from './wallet-routing.module';
import {WalletComponent} from './component/wallet/wallet.component';
import {WalletHistoryComponent} from './component/wallet-history/wallet-history.component';
import {DistributeRpComponent} from './component/distribute-rp/distribute-rp.component';
import {DonateRpComponent} from './component/donate-rp/donate-rp.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LeaderboardComponent} from './component/leaderboard/leaderboard.component';
import { SavedCardsComponent } from './component/saved-cards/saved-cards.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    WalletRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [WalletComponent, WalletHistoryComponent, DistributeRpComponent, DonateRpComponent, LeaderboardComponent,SavedCardsComponent]
})
export class WalletModule {
}
