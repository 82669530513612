import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { ProductListComponent } from './product-list.component';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
  };
const routes: Routes = [
  {
    path: '',
    component: ProductListComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule, SwiperModule,TranslateModule
  ],
  exports: [RouterModule,],
  declarations: [ProductListComponent],
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }]
})
export class ProductListModule { }
