import { Component, OnInit } from '@angular/core';
import { AppService } from "../../../../shared/services/app.service";
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { urls ,toastMessage} from '../../../../shared/services/constant';
import { PopupService } from 'src/app/core/services/popup.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  public viewLoading: boolean = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public page_no: number = 1;
  public pageSize: number = 10;
  public name: any;
  public cardList: any = [];
  public modal: any;
  public activeIndex: number;
  public newCardForm: FormGroup;
  public newCard: FormGroup;
  public model: any = {};
  public refNum: any;
  public Cardcvv: any;;
  public submitnewCard: boolean = false;
  public show: boolean = false;
  public ownername: any;
  public totalAmount: any;
  public Amount: any;
  public years: any = [];
  public selectedMonth: any;
  public selectedYear: any;
  public expiryMonth: any = 'MM';
  public expiryYear: any = 'YYYY';
  public showMonthError: any;
  public showYearError: any;
  public monthArr: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public navigationFrom:any;
  public paymentType:any;
  constructor(private appService: AppService, private popupService: PopupService, private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder, private router: Router,public translate: TranslateService) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formInitialization();
    this.activatedRoute.queryParams.subscribe(params => {
      if(Object.keys(params).length != 0){
        this.navigationFrom = params.nav_from;
      }
    });
       if(this.navigationFrom != undefined){
         this.Amount = localStorage.getItem('pay');
         this.paymentType = localStorage.getItem('type')
       }
    this.getCardList();
    let i: number = 0;
    while (i < 40) {
      this.years.push({
        'year': new Date().getFullYear() + i
      })
      i++;
    }
  }
  formInitialization() {
    this.newCardForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      cardNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(19), Validators.pattern('[0-9]+')]],
      cvv: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(4)]],
    });
  }
  get f() {
    return this.newCardForm.controls;
  }
  getCardList() {
    this.cardList = [];
    this.activeIndex = undefined;
    this.submitnewCard = false;
    this.viewLoading = true;
    this.apiParam.appJson = true;
    this.appService.get(urls.cardList)
      .subscribe(
        (response: any) => {
          this.viewLoading = false;
          this.totalAmount = response['result'].total_payable;
          this.ownername = response['result'].owner_name;
          localStorage.setItem('owner', this.ownername);
          if (response['result'] && response['result'].cards && response['result'].cards.data && response['result'].cards.data.length) {
            let data: any = response['result'].cards.data;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.cardList.push(ele);
              })
            }
          }
          else {
            this.show = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  myFunction(index, id) {
    this.refNum = id;
    this.activeIndex = index + 1;
    this.submitnewCard = false;
    this.showMonthError = '';
    this.showYearError = '';
    this.modal = document.getElementById("addCard").classList.remove("show");
  }
  opendropdown() {
    this.modal = document.getElementById("myDropdown").classList.toggle("display");
  }
  showdropdown() {
    this.modal = document.getElementById("myDropdown1").classList.toggle("dish");
  }
  showadd() {
    this.activeIndex = undefined;
    this.model.Name = '',
      this.model.cardNumber = '',
      this.expiryMonth = 'MM',
      this.expiryYear = 'YYYY',
      this.selectedMonth = '',
      this.selectedYear = '',
      this.model.cvv = '',
      this.model.cardName = ''
    this.modal = document.getElementById("addCard").classList.add("show");
    this.modal = document.getElementById("myDIV" + this.activeIndex).classList.remove("view");
  }
  shownew() {
    if(this.navigationFrom != undefined){
      this.router.navigate(['/program/addCard'], {queryParams: {nav_from: this.navigationFrom}});
    }
    else{
      this.router.navigate(['/program/addCard']);
    }
  }
  setMonth(month) {
    this.expiryMonth = month;
    this.selectedMonth = month;
    this.showMonthError = '';
    this.modal = document.getElementById("myDropdown").classList.remove("display");
  }
  setYear(year) {
    this.expiryYear = year;
    this.selectedYear = year;
    this.showYearError = '';
    this.modal = document.getElementById("myDropdown1").classList.remove("dish");
  }
  getToken() {
    this.submitnewCard = true;
    this.activeIndex = undefined;
    if (this.expiryMonth == 'MM') {
      this.showMonthError = "Month is required"
    }
    if (this.expiryYear == 'YYYY') {
      this.showYearError = "Year is required"
    }
    if (this.expiryYear == ((new Date()).getFullYear()) && this.expiryMonth < ((new Date()).getMonth())) {
      this.appService.showError(toastMessage.currentMonthCheck)
    }
    else if ((this.newCardForm.valid && this.expiryMonth != 'MM' && this.expiryYear != 'YYYY')) {
      (<any>window).Stripe.card.createToken({
        name: this.model.Name,
        number: this.model.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.model.cvv
      }, (status: number, response: any) => {

        if (status === 200) {
          this.addNewCard(response.id);
        } else {
          if (response.error.message)
            this.appService.showError(response.error.message)
          else
            this.appService.showError('Something went wrong!!!!')
        }
      });
    }
  }
  addNewCard(token) {
    let newCardData = {
      token: token
    }

    this.appService.post(urls.addCard, newCardData)
      .subscribe(
        (response: any) => {
          if (response.sc == 1) {
            this.appService.showSuccess(toastMessage.cardAdd);
            this.modal = document.getElementById("addCard").classList.remove("show");
            this.activeIndex = undefined;
            this.getCardList();
          }
          if (response.sc == 0) {
            this.appService.showError(response['error'].msg);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  payment() {
    if (this.Cardcvv == undefined) {
      this.appService.showError(toastMessage.addCvv)
    }
    else {
      let formData = {
        'card_id': this.refNum
      }
      this.appService.post(urls.payment, formData)
        .subscribe(
          (response: any) => {
            if (response.sc == 1) {
              this.appService.showSuccess(toastMessage.payment);
              this.router.navigate(['/home/list']);
            }
            if (response.sc == 0) {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }
  keypress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
        return event;
    }
    else {
        return false;
    }
}
  payTranscript(){
    if (this.Cardcvv == undefined) {
      this.appService.showError(toastMessage.addCvv)
    }
    else {
      let formData = {
        'card_id': this.refNum,
        'mode': this.paymentType,
      }
      this.appService.post(urls.transcriptPayment, formData)
        .subscribe(
          (response: any) => {
            console.log(response)
            if (response.sc == 1) {
              this.appService.showSuccess(response['result'].message.message);
              this.router.navigate(['/user/profile']);
              localStorage.removeItem('pay');
              localStorage.removeItem('type')
            }
            if (response.sc == 0) {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }

}
