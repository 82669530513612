import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-physical-instructions',
  templateUrl: './physical-instructions.component.html',
  styleUrls: ['./physical-instructions.component.scss']
})
export class PhysicalInstructionsComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
