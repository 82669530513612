import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from 'src/app/shared/services/constant';
import { fromEvent } from 'rxjs';
import { throttleTime, map, filter } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.scss']
})
export class NewsroomComponent implements OnInit {
  public viewLoading: boolean = false;
  public config: SwiperConfigInterface;
  public years: any = [];
  public activeIndex: any ;
  public modal: any;
  public selectedMonth: any;
  public selectedYear: any;
  public currentMonth:any;
  public skip = 1;
  public limit = 10;
  public resultsScrollObservable: any;
  public showNextData = true;
  public scrollSubscription: any;
  public newsList :any = [];
  public monthArr: any = [
		{
			name: "January",
			value: '1'
		},
		{
			name: "February",
			value: '2'
		},
		{
			name: "March",
			value: '3'
		},
		{
			name: "April",
			value: '4'
		},
		{
			name: "May",
			value: '5'
		},
		{
			name: "June",
			value: '6'
		},
		{
			name: "July",
			value: '7'
		},
		{
			name: "August",
			value: '8'
		},
		{
			name: "September",
			value: '9'
		},
		{
			name: "October",
			value: '10'
		},
		{
			name: "November",
			value: '11'
		},
		{
			name: "December",
			value: '12'
		},
	];
  constructor(private appService: AppService,public translate: TranslateService) {
    this.config = {
    slidesPerView: 5,
    pagination: false,
    keyboard: false,
    navigation: true,
}; 
}

  ngOnInit() {
    window.scroll(0,0);
    this.currentMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear()
    observableOf(null).subscribe(() => {
      window.scrollTo(0, 0);
      this.getNewsByYear(this.skip,this.limit);
    });
  
    this.resultsScrollObservable = fromEvent(window, 'scroll');
    /**
     * Captures scrolling behavior within teh application, so we can implement infinite
     *  scroll or hiding elements once a certain amount of scrolling is done.
     */
    this.scrollSubscription = this.resultsScrollObservable
      .pipe(
        throttleTime(250),
        map((event: any) => {
          if (event) {
            const elem = event.srcElement ? event.srcElement : event.target;
            const srcElement = elem.body;
            const clientHeight = srcElement ? srcElement.clientHeight : 0;
            const el = document.scrollingElement || document.documentElement;
            const scrollTop = el.scrollTop;
            const position = scrollTop + clientHeight;
            const height = srcElement ? srcElement.scrollHeight : 0;
            const positionPercent = +((position / height).toFixed(2));
            return positionPercent > .85;
          }
        }),
        filter((doSearch: boolean) => doSearch)
      )
      .subscribe((doSearch: boolean) => {
        if (this.showNextData) {
          this.skip = this.skip + 1;
          this.getNewsByYear(this.skip,this.limit);
        }
      });
    this.generateArrayOfYears();
    this.selectedMonth = new Date().toLocaleString('default', { month: 'long' });
  }
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = 2021
    this.years = []
  
    for (var i = max; i >= min; i--) {
      this.years.push({
        'year': i
      })
    }
  }
  opendropdown() {
    this.modal = document.getElementById("myDropdown").classList.toggle("display");
  }
  setYear(year){
    if(year == new Date().getFullYear() && this.currentMonth > new Date().getMonth() + 1){
      this.appService.showError('You cannot select future time')
    }
    else{
      this.selectedYear = year;
      this.skip = 1;
      this.limit = 10;
      this.newsList=[];
      this.getNewsByYear(this.skip,this.limit)
    }
  }
  setMonth(name,month){
    if(this.selectedYear == new Date().getFullYear() && month > new Date().getMonth() + 1){
      this.appService.showError('You cannot select future time');
      this.modal = document.getElementById("myDropdown").classList.remove("display");
    }
    else{
      this.currentMonth = month;
      this.selectedMonth = name;
      this.modal = document.getElementById("myDropdown").classList.remove("display");
      this.skip = 1;
      this.limit = 10;
      this.newsList=[];
      this.getNewsByYear(this.skip,this.limit)
    }
  }
  getNewsByYear(skip,limit){
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.getNewsList + `?skip=${skip}&limit=${limit}&month=${this.currentMonth}&year=${this.selectedYear}`).subscribe(
      (response: any) => {
        this.viewLoading = false;
        this.showNextData = false;
        if (response['result'] && response['result'].list && response['result'].list.length) {
          let data: any = response['result'].list;
          if (data && data.length > 0) {
            data.forEach((ele, ind) => {
              this.newsList.push(ele);
            });
          }
          this.showNextData = true
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  bookmark(id,bookmark){
    this.viewLoading = true
    let body = {
      _id : id,
      isBookmarked  : bookmark
    }
    this.appService.post(urls.bookmarkNews, body) 
    .subscribe ((response: any = {}) => {
        if(response.sc == 1 && bookmark == 'true'){
          this.appService.showSuccess(response['result'].message);
          this.newsList=[];
          this.skip = 1;
          this.limit = 10;
          this.getNewsByYear(this.skip,this.limit);
        }
        else if(response.sc == 1 && bookmark == 'false'){
          this.appService.showSuccess(response['result'].message);
          this.newsList=[];
          this.skip = 1;
          this.limit = 10;
          this.getNewsByYear(this.skip,this.limit);
        }
        else if(response.sc == 0){
          this.appService.showError(response['error'].msg);
        }
        this.viewLoading = false;
    })
  }
}
