import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-insight-instructions',
  templateUrl: './insight-instructions.component.html',
  styleUrls: ['./insight-instructions.component.scss']
})
export class InsightInstructionsComponent implements OnInit {
  public fiveFactors: boolean = false
  constructor(public translate: TranslateService) { window.scroll(0, 0) }

  ngOnInit() {
  }

}
