import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { SignupComponent } from './component/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { OtpComponent } from './component/otp/otp.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { InterestComponent } from './component/interest/interest.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../shared/shared.module';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { EthnicityComponent } from './component/ethnicity/ethnicity.component';
import { CheckInComponent } from './component/check-in/check-in.component';
import { InsightToolComponent } from './component/insight-tool/insight-tool.component';
import { InsightInstructionsComponent } from './component/insight-instructions/insight-instructions.component';
import { InsightTestComponent } from './component/insight-test/insight-test.component';
import { InsightResultComponent } from './component/insight-result/insight-result.component';
import { LinkSocialAccountComponent } from './component/link-social-account/link-social-account.component';
import { AddPlacesComponent } from './component/add-places/add-places.component';
// import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { OnboardingPopupComponent } from './component/onboarding-popup/onboarding-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BasicInfoComponent } from './component/basic-info/basic-info.component';
import { MoreInfoComponent } from './component/more-info/more-info.component';
import { SocialInfoComponent } from './component/social-info/social-info.component';
import { AddLocationsComponent } from './component/add-locations/add-locations.component';
import { ResetPasswordModule } from './component/reset-password/reset-password.module';
import { SetPasswordModule } from './component/set-password/set-password.module';
import { ProfileModule } from './component/profile/profile.module';
import { SavedLocationsModule } from './component/saved-locations/saved-locations.module';
import { ParentsModule } from './component/parents/parents.module';
import { BadgesModule } from './component/badges/badges.module';
import { MycheckInModule } from './component/mycheck-in/mycheck-in.module';
import { ChangePasswordModule } from './component/change-password/change-password.module';
import { LinkedAgenciesModule } from './component/linked-agencies/linked-agencies.module';
import { AgencyProgramsModule } from './component/agency-programs/agency-programs.module';
import { FileSaverModule } from 'ngx-filesaver';
import { CreateTranscriptModule } from './component/create-transcript/create-transcript.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ChildProgramsModule } from './component/child-programs/child-programs.module';
import { RewardsCenterModule } from './component/rewards-center/rewards-center.module';
import { PitModule } from './component/pit/pit.module';
import { EqPqInstructionsModule } from './component/eq-pq-instructions/eq-pq-instructions.module';
import { EqPqTestModule } from './component/eq-pq-test/eq-pq-test.module';
import { NewsroomModule } from './component/newsroom/newsroom.module';
import { AcademicInstructionsModule } from './component/academic-instructions/academic-instructions.module';
import { AcademicRecordModule } from './component/academic-record/academic-record.module';
import { PhysicalRecordModule } from './component/physical-record/physical-record.module';
import { PhysicalInstructionsModule } from './component/physical-instructions/physical-instructions.module';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReedemOtpComponent } from './component/reedem-otp/reedem-otp.component';
@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    SharedModule,
    NgxMyDatePickerModule.forRoot(),
    GooglePlaceModule,
    // Ng4GeoautocompleteModule.forRoot(),
    NgbModule,
    ResetPasswordModule,
    SetPasswordModule,
    ProfileModule,
    SavedLocationsModule,
    ParentsModule,
    BadgesModule,
    MycheckInModule,
    ChangePasswordModule,
    LinkedAgenciesModule,
    AgencyProgramsModule,
    FileSaverModule,
    CreateTranscriptModule,
    RewardsCenterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    ChildProgramsModule,
    PitModule,
    EqPqInstructionsModule,
    EqPqTestModule,
    NewsroomModule,
    AcademicInstructionsModule,
    AcademicRecordModule,
    PhysicalRecordModule,
    PhysicalInstructionsModule,
    TranslateModule,
    NgxIntlTelInputModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [SignupComponent, OtpComponent, ForgotPasswordComponent, InterestComponent, EthnicityComponent, CheckInComponent,InsightToolComponent, InsightInstructionsComponent, InsightTestComponent, InsightResultComponent, LinkSocialAccountComponent, AddPlacesComponent, OnboardingPopupComponent, AddLocationsComponent, EditProfileComponent, BasicInfoComponent, MoreInfoComponent, SocialInfoComponent, ReedemOtpComponent],
  exports: [SignupComponent, OtpComponent, ForgotPasswordComponent, InterestComponent, EthnicityComponent, CheckInComponent, InsightResultComponent, LinkSocialAccountComponent, AddPlacesComponent, OnboardingPopupComponent,AddLocationsComponent, ReedemOtpComponent]

})

export class UserModule { }