import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ParentRoutingModule } from './parent-routing.module';
import { AddParentComponent } from './component/add-parent/add-parent.component';
import { SearchParentComponent } from './component/search-parent/search-parent.component';
import { ProfileErrorPopupComponent } from './component/profile-error-popup/profile-error-popup.component';
import { ProfileSuccessPopupComponent } from './component/profile-success-popup/profile-success-popup.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment.prod';

@NgModule({
  imports: [
    CommonModule,
    ParentRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMyDatePickerModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    NgxIntlTelInputModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService
  ],
  declarations: [AddParentComponent, SearchParentComponent, ProfileErrorPopupComponent, ProfileSuccessPopupComponent],
  exports: [AddParentComponent, SearchParentComponent, ProfileErrorPopupComponent, ProfileSuccessPopupComponent]
})
export class ParentModule { }
