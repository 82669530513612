import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../shared/services/app.service';
import { urls, toastMessage} from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { ToastrService } from 'ngx-toastr';
import { PopupService } from '../../../../core/services/popup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare var google: any;

@Component({
    selector: 'app-ethnicity',
    templateUrl: './ethnicity.component.html',
    styleUrls: ['./ethnicity.component.scss']
})

export class EthnicityComponent implements OnInit {

    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public schoolArray: any = [];
    public ethnicityArray: any = [];
    public ethnicityTypeArray: any = [];
    public stateArray: any = [];
    public cityArray = [];
    public profileForm: FormGroup;
    public locationForm: FormGroup;
    public submitted = false;
    public isLocationFormSubmit = false;
    public otherRace: boolean = false;
    public hideStateUl: boolean = false;
    public hideCityUl: boolean = false;
    public stateId: any;
    public cityId: any;
    public searchValue: string = ''
    public cityValue: string = '';
    public showLocationTab: boolean = true;
    public geolocationPosition: any;

    constructor(private appService: AppService,
        private popupService: PopupService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        public translate: TranslateService) {
    }

    ngOnInit() {
        this.getEthnicityList();
        this.getEthnicityType();
        this.profileForm = this.formBuilder.group({
            ethnicity_type: ['', Validators.required],
            ethnicity: ['', Validators.required],
            other_ethnicity: [''],
        });
        this.locationForm = this.formBuilder.group({
            zipcode: ['', Validators.required]
        })
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.profileForm.controls;
    }

    get g() {
        return this.locationForm.controls;
    }

    onSubmit(user) {
        let data = Object.assign({}, user);
        this.submitted = true;
        // stop here if form is invalid
        if (this.profileForm.invalid) {
            return;
        } else {
            this.appService.post(urls.completeProfile, data)
                .subscribe(
                    (response: any) => {
                        if (response && response.sc == 1) {
                            this.getPopup('addPlaces');
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        }
    }

    submitLocation(zipcode) {
        let that = this
        this.isLocationFormSubmit = true;
        // Stop here if form is invalid
        if (this.locationForm.invalid) {
            return;
        }
        else {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': zipcode.zipcode }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                    that.sendlatlong(latitude, longitude);
                } else {
                    this.alertify.error('Zip code not found.');
                }
            });

        }
    }

    sendlatlong(latitude, longitude) {
        let data = {
            'lat': latitude,
            'long': longitude,
            'zipcode': this.locationForm.controls.zipcode.value
        }
        this.appService.post(urls.completeProfile, data)
            .subscribe(
                (response: any) => {
                    if (response && response.sc == 1) {
                        this.showLocationTab = false;
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    onLocationSkip() {
        this.showLocationTab = false;
    }

    onEthnicitySkip() {
        this.getPopup('addPlaces');
    }

    fetchLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.geolocationPosition = position;
                    let data = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    };
                    this.appService.post(urls.completeProfile, data)
                        .subscribe(
                            (response: any) => {
                                if (response && response.sc == 1) {
                                    this.showLocationTab = false;
                                }
                            },
                            (error) => {
                                console.log(error);
                            }
                        )
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.appService.showError(toastMessage.permissionDenied);
                            break;
                          case 2:
                            this.appService.showError(toastMessage.postionUnavailable);
                            break;
                          case 3:
                            this.appService.showError(toastMessage.timeout);
                            break;
                    }
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }

    redirect() {
        this.getPopup('');
        this.router.navigate([this.router.url], { queryParams: { "type": "refersh" } })
    }

    getPopup(popupType): void {
        this.popupService.showPopup(popupType);
    }

    getEthnicityList() {
        this.apiParam.appJson = true;
        let params = {};
        this.appService.post(urls.listEthnicity, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc == 1) {
                        this.ethnicityArray = [];
                        if (response.result.ethnicity && response.result.ethnicity.length > 0) {
                            response.result.ethnicity.forEach((ele, ind) => {
                                let obj = {
                                    "insensitive": ele.insensitive,
                                    "name": ele.name,
                                    "id": ele._id,
                                }
                                this.ethnicityArray.push(obj)
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    getEthnicityType() {
        this.apiParam.appJson = true;
        let params = {};
        this.appService.get(urls.ethnicityType)
            .subscribe(
                (response: any) => {
                    if (response && response.sc == 1) {

                        if (response.result.ethnicityType && response.result.ethnicityType.length > 0) {
                            response.result.ethnicityType.forEach((ele, ind) => {
                                let obj = {
                                    "ethnicity_type_name": ele.ethnicity_type_name,
                                    "id": ele._id,
                                }
                                this.ethnicityTypeArray.push(obj)
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    checkEthincity(type) {
        this.otherRace = false;
        if (type == "5ba0ddfa7b5d563b685a7236") {
            this.otherRace = true;
        }
    }

    ifNumericKey(event) {
        return ((event.keyCode >= 97 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 8 || event.keyCode == 9)
    }
}