import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ActivatedRoute } from '@angular/router';
import { of as observableOf, fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll, throttleTime, timestamp, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrls: ['./wallet-history.component.scss']
})
export class WalletHistoryComponent implements OnInit {
  public userId: any = ''
  public page: number = 1;
  public viewLoading: boolean = false;
  public pageSize: number = 10;
  public pointList: Array<any> = []
  public distributed_coin: number = null
  public total_Coin: number = null
  public unverified_coin:number=null
  public today: Date = new Date();
  public yesterday: Date =new Date();
  public navigationFrom: any;
  public showNextData = true;
  public resultsScrollObservable: any;
  public scrollSubscription: any;
  constructor(private appService: AppService, private activatedRoute: ActivatedRoute,public translate: TranslateService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.navigationFrom = params.nav_from;
  });
   }

  ngOnInit() {
    observableOf(null).subscribe(() => {
      window.scrollTo(0, 0);
      this.walletHistory(this.page, this.pageSize);

  });

    this.resultsScrollObservable = fromEvent(window, 'scroll');
    this.scrollSubscription = this.resultsScrollObservable
      .pipe(
        throttleTime(250),
        map((event: any) => {

          console.log(event)
          if (event) {
            const elem = event.srcElement ? event.srcElement : event.target;
            const srcElement = elem.body;
            const clientHeight = srcElement ? srcElement.clientHeight : 0;
            const el = document.scrollingElement || document.documentElement;
            const scrollTop = el.scrollTop;
            const position = scrollTop + clientHeight;
            const height = srcElement ? srcElement.scrollHeight : 0;
            const positionPercent = +((position / height).toFixed(2));
            return positionPercent > .85;
          }
        }),
        filter((doSearch: boolean) => doSearch)
      )
      .subscribe((doSearch: boolean) => {
        if (this.showNextData) {
          this.page = this.page + 1;
          this.walletHistory(this.page, this.pageSize);
        }
      });
     
  }
  ngOnDestroy(){
    this.scrollSubscription.unsubscribe();
  }
    


  walletHistory(page, pageSize) {
    this.viewLoading = true;
    this.appService.post(urls.walletHistory + 'page=' + page + '&page_size=' + pageSize)
      .subscribe((res: any) => {
        if (res.sc == 1) {
          this.viewLoading = false;
          this.showNextData = false;
          if (res.result.coin_data) {
            this.distributed_coin = res.result.coin_data.Distributed_coin
            this.total_Coin = res.result.coin_data.Total_Coin
            this.unverified_coin=res.result.coin_data.unverified_coin
          }
          if (res.result.list) {
            (res.result.list).forEach((element: any) => {
              let obj = {
                message: element.Message,
                point: element.Point,
                is_received: element.is_received,
                time_stamp: element.time_stamp
              }
              this.pointList.push(obj);
            });
          }
          this.showNextData = true
        }
      })
  }
}
