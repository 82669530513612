import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { BadgesComponent } from './badges.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: BadgesComponent
  }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(routes), FormsModule,TranslateModule
  ],
  exports: [RouterModule],
  declarations: [BadgesComponent],
  providers: []
})
export class BadgesModule { }
