import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-academic-instructions',
  templateUrl: './academic-instructions.component.html',
  styleUrls: ['./academic-instructions.component.scss']
})
export class AcademicInstructionsComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
