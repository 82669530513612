import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { UserModel } from '../../../../shared/model/userModel';
import { AppService } from '../../../../shared/services/app.service';
import { urls, facebookConfig, googleConfig, instUrl, fileSize, toastMessage } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Router } from '@angular/router';
import { PopupService } from '../../../../core/services/popup.service';
declare var FB: any;
declare var gapi: any;
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthServiceService } from "../../../../core/services/authService/auth-service.service";
// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO } from 'ngx-intl-tel-input';

// const amplifyConfig = {
// Auth: {
// identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
// region: 'us-east-1'
// }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
// AWSPinpoint: {
// appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
// region: 'us-east-1',
// mandatorySignIn: false,
// }
// }

// Analytics.configure(analyticsConfig)

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit, OnChanges {
    @Output() isSocialLogin: EventEmitter<any> = new EventEmitter<any>();
    @Input() userDetails: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    public oneTime = 1;
    public userModel: UserModel = new UserModel();
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public imgUrl: any;
    public timer: boolean = true;
    public currentDate: any;
    public refCode: boolean = false;
    public signupApiResponse: boolean = true;
    public studentRole: boolean = true;
    public disableSinceDay: number = new Date().getDate() + 1;
    public disableSinceModelDay: number = new Date().getDate();
    public disableSinceMonth: number = new Date().getMonth() + 1;
    public disableSinceYear: number = new Date().getFullYear() - 13;
    public country_code: any = '1';
    public myOptions: INgxMyDpOptions = {
        dateFormat: 'mmm dd, yyyy',
        minYear: 1900,
        maxYear: 2200,
        disableSince: { year: this.disableSinceYear, month: this.disableSinceMonth, day: this.disableSinceDay }
    };

    // Initialized to specific date (09.10.2018)
    public userInfo: any = {
        first_name: '',
        last_name: '',
        email: '',
        picture: '',
        profileId: '',
        gplus_id: '',
        insta_profileId: ''
    };
    public genderDisplay: any;
    public registerForm: FormGroup;
    public submitted = false;
    public showSignUpModel: boolean = true;
    public signUpForm: FormGroup;
    public submitSignUpForm: boolean = false;
    CountryISO = CountryISO;
    public toggleCheckedValue: boolean = true;
    constructor(private formBuilder: FormBuilder,
        private appService: AppService,
        private router: Router,
        private popupService: PopupService,
        private authService: AuthServiceService,
        public zone: NgZone,
        public translate: TranslateService
    ) { }

    getPopup(poupType): void {
        this.popupService.showPopup(poupType);
    }

    ngOnChanges() {
        if (this.userDetails) {
            this.userInfo.first_name = this.userDetails.first_name;
            this.userInfo.last_name = this.userDetails.last_name;
            if (this.userDetails.email) {
                this.userInfo.email = this.userDetails.email;
            }
            else {
                this.userInfo.email = '';
            }
            this.userInfo.picture = this.userDetails.profile_pic;
            this.userInfo.profileId = this.userDetails.profileId;
            this.userInfo.gplus_id = this.userDetails.gplus_id;
            this.formInitialization();
        }
        if (this.popupService.userDetails && this.popupService.userDetails.hasOwnProperty('first_name')) {
            this.userInfo.first_name = this.popupService.userDetails.first_name;
            this.userInfo.last_name = this.popupService.userDetails.last_name;
            if (this.popupService.userDetails.email) {
                this.userInfo.email = this.popupService.userDetails.email;
            }
            else {
                this.userInfo.email = '';
            }
            this.userInfo.picture = this.popupService.userDetails.profile_pic;
            this.userInfo.profileId = this.popupService.userDetails.profileId;
            this.userInfo.gplus_id = this.popupService.userDetails.gplus_id;
            this.userInfo.insta_profileId = this.popupService.userDetails.insta_profileId;
            this.formInitialization();
            this.popupService.userDetails = {};
        }
        if (this.popupService.FacebookDetails && this.popupService.FacebookDetails.hasOwnProperty('first_name')) {
            {
                this.userInfo.first_name = this.popupService.FacebookDetails.first_name;
                this.userInfo.last_name = this.popupService.FacebookDetails.last_name;
                if (this.popupService.FacebookDetails.email) {
                    this.userInfo.email = this.popupService.FacebookDetails.email;
                }
                else {
                    this.userInfo.email = '';
                }
                this.userInfo.picture = this.popupService.FacebookDetails.profile_pic;
                this.userInfo.profileId = this.popupService.FacebookDetails.profileId;
                this.userInfo.gplus_id = this.popupService.FacebookDetails.gplus_id;
                this.userInfo.insta_profileId = this.popupService.FacebookDetails.insta_profileId;
                this.formInitialization();
                this.popupService.FacebookDetails = {};
            }
        }
        this.genderDisplay = 0;
    }

    onDateChanged(event: IMyDateModel): void {
        this.registerForm.patchValue({ dob: event.epoc * 1000 });
    }

    onFileChanged(event) {
        let imageType = event.target.files[0].type.split("/")
        if (imageType[0] == "image") {
            if (event.target.files[0].size < fileSize && imageType[0] == "image") {
                if (event.target.files.length) {
                    let files = event.target.files;
                    this.imgUrl = event.target.value;
                    var that = this;
                    let reader = new FileReader();
                    reader.onload = function (event: any) {
                        that.imgUrl = event.target.result;
                    };
                    reader.readAsDataURL(files[0]);
                }
                const file = event.target.files[0]
                this.registerForm.patchValue({ image: file });
            } else {
                this.appService.showError(toastMessage.imageSize);
            }
        } else {
            this.appService.showError(toastMessage.imageCheck);
        }
    }

    genderType(value) {
        this.registerForm.patchValue({ gender: value });
    }

    ngOnInit() {
        this.formInitialization();
        this.setRoleValidators();
        FB.init(facebookConfig);
        this.country_code = '1'
    }

    formInitialization() {
        this.registerForm = this.formBuilder.group({
            first_name: [this.userInfo.first_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$'), Validators.maxLength(20)]],
            last_name: [this.userInfo.last_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$'), Validators.maxLength(20)]],
            phone_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
            role: ['2'],
            gender: ['', Validators.required],
            image: [],
            dob: ['', []],

        });
        this.signUpForm = this.formBuilder.group({
            email: [this.userInfo.email ? this.userInfo.email : '',
            [Validators.required,
            Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            password: ['', [Validators.required, Validators.minLength(8),
            Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#&])[A-Za-z\d@$!%*^#&]{8,}$/)]],
            confirmPassword: ['', [Validators.required]],
            referral_code: ['']
        }, { validator: this.MatchPassword });
    }
    setRoleValidators() {
        const dobControl = this.registerForm.get('dob');
        this.registerForm.get('role').valueChanges
            .subscribe(role => {
                if (role === '1') {
                    dobControl.setValidators(null);
                }

                if (role === '2') {
                    dobControl.setValidators([Validators.required]);
                }
                dobControl.updateValueAndValidity();
            });
    }
    MatchPassword(AC: AbstractControl) { // here we have the 'passwords' group
        let password = AC.get('password').value; // to get value in input tag
        let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (password != confirmPassword || confirmPassword != password) {
            console.log('false');
            AC.get('confirmPassword').setErrors({ MatchPassword: true })
        } else {
            console.log('true');
            return null
        }
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.registerForm.controls;
    }

    get g() {
        return this.signUpForm.controls;
    }
    setDate() {
        if (this.registerForm.value['dob'] == '') {
            this.registerForm.controls['dob'].patchValue({ date: { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() } })
        }
        else {
            this.registerForm.controls['dob'].patchValue(this.registerForm.value['dob'])
        }
    }
    onSubmit(user) {
        let data = Object.assign({}, user);
        if (this.studentRole == false) {
            data.dob = "";
            this.submitted = true;
        }
        else {
            if (user.dob) {
                let myDate = user.dob.date.day + '-' + user.dob.date.month + '-' + user.dob.date.year
                let splitData = myDate.split("-");
                let newDate = splitData[1] + "/" + splitData[0] + "/" + splitData[2];
                data.dob = new Date(newDate).getTime();
                data.dob = (data.dob).toString();
                this.submitted = true;
            }
            else {
                this.appService.showError(toastMessage.dobCheck);
                this.submitted = false;
            }
        }
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        else if (this.genderDisplay == 0) {
            this.appService.showError('Gender is not selected');
        }
        else {
            let number = user.phone_no.number.replace(user.phone_no.dialCode, '')
            let data_profile = {
                phone_no: number
            }
            this.appService.post(urls.checkEmail, data_profile).subscribe(
                data_profile => {

                    if (data_profile['result']) {
                        this.signupApiResponse = false;
                        data.profile_pic = this.userInfo.picture
                        if (this.userInfo.gplus_id) {
                            data.gplus_id = (this.userInfo.gplus_id).toString()
                        } else if (this.userInfo.profileId) {
                            data.fb_id = (this.userInfo.profileId).toString()
                        } else if (this.userInfo.insta_profileId) {
                            data.intstrgm_id = (this.userInfo.insta_profileId).toString();
                        } else {
                            data.fb_id = '';
                            data.gplus_id = '';
                            data.intstrgm_id = '';
                        }
                        data.email = this.signUpForm.value.email;
                        data.password = this.signUpForm.value.password;
                        data.url = urls.userRegister;
                        data.phone_no = number
                        data.country_code = user.phone_no.dialCode.split('+')[1];
                        if (this.signUpForm.value.referral_code) {
                            data.referral_code = this.signUpForm.value.referral_code;
                        }
                        this.appService.makeFileRequest(data)
                            .subscribe(
                                (response: any) => {
                                    let result = JSON.parse(response);
                                    if (result && result.sc == 1) {
                                        // Analytics.record({ name: 'Account Sign Up' });
                                        localStorage.setItem('userPhone', data.phone_no)
                                        localStorage.setItem('countryCode', user.phone_no.dialCode.split('+')[1])
                                        this.registerForm.reset(this.registerForm.value);
                                        this.getPopup('otp');
                                    } else {
                                        this.appService.showError(result.error.msg);
                                    }
                                },
                                (error) => {
                                    console.log(error);
                                }
                            )
                    }
                    if (data_profile['error']) {
                        this.appService.showError(data_profile['error'].msg);
                    }
                }, error => {
                    console.log(error);
                }
            )
        }
    }
    getProfilePic() {
        let dummyImg = './assets/img/blank-image.png';
        let st: any;
        if (this.imgUrl) {
            st = { background: `url(${this.imgUrl || dummyImg})` };
        } else if (this.userInfo.picture) {
            st = { background: `url(${this.userInfo.picture})` };
        } else {
            st = { background: `url(${dummyImg})` };
        }
        return st;
    }

    referralCode() {
        this.refCode = !this.refCode;
    }

    ngOnDestroy() {
        this.onClose.emit();
    }

    onChangeRole(roleType) {
        this.registerForm.patchValue({ role: roleType });
        if (roleType == 1) {
            this.studentRole = false;
        } else {
            this.studentRole = true;
        }
    }
    studentParentToggle() {
        if (this.toggleCheckedValue) {
            this.toggleCheckedValue = false;
            this.onChangeRole(1);
            this.appService.showSuccess("you using Spotivity as a guardian");
        }
        else {
            this.toggleCheckedValue = true;
            this.onChangeRole(2);
            this.appService.showSuccess("you using Spotivity as a student");
        }
    }
    noWhite(event) {
        let key = event.keyCode;
        if (key === 32) {
            event.preventDefault();
        }
    }

    userDetailsModel(user) {
        this.submitSignUpForm = true;
        if (this.signUpForm.invalid) {
            return
        }
        else {
            let data = {
                email: user.email
            }
            if (user.referral_code) {
                data['referral_code'] = user.referral_code;
            }
            this.appService.post(urls.checkEmail, data).subscribe(
                data => {
                    if (data['result']) {
                        this.showSignUpModel = false;
                    }
                    if (data['error']) {
                        this.appService.showError(data['error'].msg);
                    }
                }, error => {
                    console.log(error);
                }
            )
        }
    }

    onBoarding() {
        this.getPopup('');
        this.router.navigate(["program/map-list"]);
    }

    facebook() {
        FB.login(response => {
            this.statusChangeCallback(response);
        }, { scope: 'public_profile,email' });
    }

    statusChangeCallback(response) {
        if (response.status === 'connected') {
            let accessToken = response.authResponse.accessToken;
            let params = {
                "accessToken": accessToken
            };
            this.apiParam.appJson = true;
            this.appService.post(urls.facebookLogin, params)
                .subscribe(
                    (response: any) => {
                        if (response && response.sc == 1) {
                            if (response.result.user.status == 3) {
                                this.isSocialLogin.emit(response.result.user);
                                observableOf(null).pipe(delay(400)).subscribe(() => {
                                    this.getPopup('signup');
                                    if (!response.result.user.hasOwnProperty('email')) {
                                        this.appService.showError(toastMessage.emailNotFound);
                                    }
                                    else if (response.result.user.email) {
                                        (<FormControl>this.signUpForm.controls['email']).setValue(response.result.user.email)
                                    }
                                })
                            }
                            else if (response.result.user.status == 0) {
                                localStorage.setItem('userPhone', response.result.user.phone_no)
                                this.resendOtp(response.result.user.phone_no);
                                this.getPopup('otp');
                            }
                            else {
                                localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "")
                                this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name)
                                this.getPopup('');
                                this.router.navigate(["home/list"]);

                                let currentURL = this.router.url.split("/#")[0];
                                this.router.navigate([currentURL], { queryParams: { "type": "refersh" } })
                            }
                        } else {
                            this.appService.showError(response.error.msg);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )

        } else if (response.status === 'not_authorized') {
            console.log('not_authorized Please log into this app.');
        } else {
            console.log('Please log into Facebook.');
        }
    }

    gplus() {
        const gplus_signinCallback = (authResult) => {
            if (authResult['status']['signed_in']) {
                let params = {
                    "accessToken": authResult.access_token
                };
                this.apiParam.appJson = true;
                this.appService.post(urls.googleLogin, params)
                    .subscribe(
                        (response: any) => {
                            this.zone.run(() => {
                                if (response && response.sc == 1) {
                                    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    if (response.result.user.status == 3) {
                                        this.isSocialLogin.emit(response.result.user);
                                        observableOf(null).pipe(delay(400)).subscribe(() => {
                                            this.getPopup('signup');
                                        });
                                        if (response.result.user.email) {
                                            (<FormControl>this.signUpForm.controls['email']).setValue(response.result.user.email)
                                        }
                                    }
                                    else if (response.result.user.status == 0) {
                                        localStorage.setItem('userPhone', response.result.user.phone_no)
                                        this.resendOtp(response.result.user.phone_no);
                                        this.getPopup('otp');
                                    }

                                    else {
                                        localStorage.setItem("profilePic", (response.result.user.profile_pic) ? response.result.user.profile_pic : "")
                                        this.authService.setSecureToken(response.result.token, response.result.user.first_name, response.result.user.last_name)
                                        this.getPopup('');
                                        this.router.navigate(["home/list"]);
                                    }
                                } else {
                                    this.appService.showError(response.error.msg);
                                }
                            })
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
            }
        }

        gapi.auth.authorize(googleConfig, gplus_signinCallback);
    }

    resendOtp(phone) {
        this.apiParam.url = urls.resendOtp;
        let params = {
            "phone_no": phone
        };
        this.apiParam.appJson = true;
        this.appService.post(urls.resendOtp, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc == 1) {
                    }
                }
            )
    }

    instagram() {
        let path = 'login';
        localStorage.setItem('path', path);
        window.location.href = instUrl + '&redirect_uri=' + window.location.origin + '&response_type=token';
    }
}