import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { PopupService } from "../../../../core/services/popup.service";
import { AppService } from "../../../../shared/services/app.service";
import * as alertify from 'alertifyjs';
import { urls, facebookConfig, linkedUrl, twitUrl, instUrl } from '../../../../shared/services/constant';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var FB: any;
@Component({
  selector: 'app-link-social-account',
  templateUrl: './link-social-account.component.html',
  styleUrls: ['./link-social-account.component.scss']
})
export class LinkSocialAccountComponent implements OnInit {

  constructor(private appService: AppService,
    private popupService: PopupService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService
  ) { }

  public linkedin: boolean = false;
  public code: any;
  public state: any;
  public linkdinUrl: any;
  public inst: boolean = false;
  public twitter: boolean = false;
  public twitterURL: string;
  public oauthCallbackConfirmed: boolean;
  public oauthToken: string;
  public disableButton: boolean = true;
  public facebook: boolean = false;
  public instagram: boolean = false;
  public facebookURL: string;
  public linkedinToken: any;
  public linkedinConnect: any;
  public twitterToken: any;
  public twitterConnect: any;
  public twitterVerifier: any;
  public facebookToken: any;
  public facebookConnect: any;
  public instagramToken: any;
  public instagramConnect: any;
  public arr: any;
  public fb: any;
  public linked: any;
  public insta: any;
  public params: any = {};
  cookieValue: any = {};
  ngOnInit() {
    if (localStorage.getItem('linkedin') && localStorage.getItem('linkedin') != 'false') {
      this.linkedin = true;
      this.disableButton = false;
    }
    if (localStorage.getItem('twitter') && localStorage.getItem('twitter') != 'false') {
      this.twitter = true;
      this.disableButton = false;
    }
    if (localStorage.getItem('facebook') && localStorage.getItem('facebook') != 'false') {
      this.facebook = true;
      this.disableButton = false;
    }
    if (localStorage.getItem('instagram') && localStorage.getItem('instagram') != 'false') {
      this.instagram = true;
      this.disableButton = false;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state'];
      if (this.code && this.state) {
        let clean_uri = location.protocol + '//' + location.host + location.pathname;
        this.getLinkedinToken(this.code, true);
        window.history.replaceState({}, document.title, clean_uri);
      }
      if (params['oauth_token'] && params['oauth_verifier']) {
        this.twitterContent(true, params['oauth_token'], params['oauth_verifier']);
      }
    });
    FB.init(facebookConfig);
    //For Instagram
    this.activatedRoute.queryParams.subscribe(params => {
      let token;
      let varrr = (window.location.href.split('='));
      if (varrr.length == 2) {
        token = (window.location.href.split('='))["1"];
      }

      if (token) {
        this.getInstagramLogin(token);
      }
    });
  }

  redirect() {
    this.getPopup('');
    this.router.navigate(["/home/list"]);
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

  connectLinkedIn(type) {
    if (type == 'disconnect') {
      alertify.confirm('Are you sure?',
        'Do you really want to disconnect your LinkedIn account? By clicking on "OK" you will no longer get reward points by social posts.',
        () => {
          this.getLinkedinToken('', false);
          this.linkedin = false;
          this.disableButton = false;
        }
        , function () {
        });
    }
    else {
      localStorage.setItem('linkedin', 'true');
      localStorage.setItem('linkedin_redirect_url', window.location.origin);
      window.location.href = linkedUrl + '&redirect_uri=' + window.location.origin;
    }
  }

  getLinkedinToken(code, isConnect) {
    localStorage.setItem('LinkedIn_Token', code);
    localStorage.setItem('linkedin', isConnect);
  }

  twitterLogin(type) {
    if (type == 'disconnect') {
      alertify.confirm('Are you sure?',
        'Do you really want to disconnect your Twitter account? By clicking on "OK" you will no longer get reward points by social posts.',
        () => {
          this.twitterContent(false, '', '');
          this.twitter = false;
          this.disableButton = false;
        }
        , function () {
        });
    }
    else {
      let body = {
        'type': 2
      }
      this.appService.post(urls.twitterConnect, body)
        .subscribe(
          (rsp: any) => {
            if (rsp.sc === 1) {
              this.oauthCallbackConfirmed = true;
              var qsParams = this.getQueryStringParameters(rsp.result['url']);
              localStorage.setItem('twitterSecretKey', qsParams.oauth_secret);
              localStorage.setItem('twitter', 'true');
              this.twitterURL = rsp.result['url'];
              window.location.href = this.twitterURL;
            } else {
              this.oauthCallbackConfirmed = false;
              console.log('error: ' + rsp);
            }
          },
          error => {
            this.oauthCallbackConfirmed = false;
            console.log(error);
          }
        );
      if (this.oauthCallbackConfirmed === true) {
        window.location.href = twitUrl + environment.twitKey;
        window.location.href = twitUrl + this.oauthToken;
      }
    }
  }

  getQueryStringParameters = url => {
    if (url) {
      if (url.split('?').length > 0) {
        var query = url.split('?')[1];
      }
    } else {
      url = window.location.href;
      query = window.location.search.substring(1);
    }
    return (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {});
  };

  twitterContent(isConnect, token, verifier) {
    localStorage.setItem('twit_token', token);
    localStorage.setItem('verifier', verifier);
    localStorage.setItem('twitter', isConnect);
  }
  facebookLogin(type) {
    if (type == 'disconnect') {
      alertify.confirm('Are you sure?',
        'Do you really want to disconnect your Facebook account? By clicking on "OK" you will no longer get reward points by social posts.',
        () => {
          localStorage.setItem('Fb_Token', '');
          localStorage.setItem('facebook', 'false');
          this.facebook = false;
          this.disableButton = false;
        }
        , function () {
        });
    }
    else {
      FB.login(response => {
        this.statusChangeCallback(response);
      }, { scope: 'public_profile,email' });
    }
  }

  statusChangeCallback(response) {
    if (response.status === 'connected') {
      let accessToken = response.authResponse.accessToken;
      localStorage.setItem('Fb_Token', accessToken);
      localStorage.setItem('facebook', 'true');
      this.facebook = true;
      this.disableButton = false;
    }
  }

  instagramLogin(type) {

    if (type == 'disconnect') {
      alertify.confirm('Are you sure?',
        'Do you really want to disconnect your Instagram account? By clicking on "OK" you will no longer get reward points by social posts.',
        () => {
          localStorage.setItem('instagram', 'false');
          localStorage.setItem('Insta_Token', '');
          this.instagram = false;
          this.disableButton = false;
        }
        , function () {
        });
    }
    else {
      let path = 'connect';
      localStorage.setItem('instagram', 'true');
      localStorage.setItem('instagram_redirect_url', window.location.origin);
      localStorage.setItem('path', path);
      window.location.href = instUrl + '&redirect_uri=' + window.location.origin + '&response_type=token';
    }
  }
  getInstagramLogin(token) {
    let Token = token;
    localStorage.setItem('Insta_Token', Token);
  }
  saveData() {

    let params = {
      inst: '',
      connect_by_inst: false,
      isWeb: true,
      fb: '',
      connect_by_fb: false,
      linkedin: '',
      connect_by_linkedin: false,
      twit: '',
      connect_by_twit: false
    };

    if (localStorage.getItem('instagram') && localStorage.getItem('instagram') === 'true') {
      params.inst = JSON.stringify({
        oauth2_access_token: localStorage.getItem("Insta_Token"),
        redirect_url: localStorage.getItem('instagram_redirect_url')
      });
      params.connect_by_inst = true;
      localStorage.removeItem('instagram');
    }
    if (localStorage.getItem('facebook') && localStorage.getItem('facebook') === 'true') {
      params.fb = JSON.stringify({
        oauth2_access_token: localStorage.getItem("Fb_Token")
      });
      params.connect_by_fb = true;
      localStorage.removeItem('facebook');
    }
    if (localStorage.getItem('linkedin') && localStorage.getItem('linkedin') === 'true') {
      params.linkedin = JSON.stringify({
        oauth2_access_token: localStorage.getItem("LinkedIn_Token"),
        redirect_url: localStorage.getItem('linkedin_redirect_url')
      });
      params.connect_by_linkedin = true;
      localStorage.removeItem('linkedin');
    }
    if (localStorage.getItem('twitter') && localStorage.getItem('twitter') === 'true') {
      params.twit = JSON.stringify({
        oauth_token: localStorage.getItem('twit_token'),
        oauth_verifier: localStorage.getItem('verifier'),
        oauth_secret: localStorage.getItem('twitterSecretKey')
      });
      params.connect_by_twit = true;
      localStorage.removeItem('twitter');
    }
    this.appService.post(urls.socialConnect, params)
      .subscribe(
        (response: any) => {
          if (response.sc && response.sc == 1) {
            if (params.connect_by_inst == true) {
              this.instagram = true;
              localStorage.setItem('instagram', 'true');
            } else {
              this.instagram = false;
              localStorage.removeItem('instagram');
            }
            if (params.connect_by_fb == true) {
              this.facebook = true;
              localStorage.setItem('facebook', 'true');
            } else {
              this.facebook = false;
              localStorage.removeItem('facebook');
            }
            if (params.connect_by_linkedin == true) {
              this.linkedin = true;
              localStorage.setItem('linkedin', 'true');
            } else {
              this.linkedin = false;
              localStorage.removeItem('linkedin');
            }
            if (params.connect_by_twit == true) {
              this.twitter = true;
              localStorage.setItem('twitter', 'true');
            } else {
              this.twitter = false;
              localStorage.removeItem('twitter');
            }
            this.getPopup('');
            this.router.navigate(["home/list"]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}